import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  layout(event) {
    if (event.type == "resize") {
      //console.log(window.screen.width)
      //console.log(window.innerWidth)
    }
  }
}
