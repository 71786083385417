import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["input"]

  connect() {
    //console.log("user info connect")
    users.init_dropdowns()
    general.hover_link("user_profile_webs")
  }

}
