document.addEventListener("turbo:load", function() {
  if ($("#projects.index").length > 0) {
    projects.index();
  }

  if ($("#projects.show").length > 0) {
    projects.show();
  }
});

var projects = {
  index() {
    search.init("/projects")
    projects.init_overview_search_filters()

    calendar.datepicker(".upload_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".upload_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".release_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".release_date_end", { position: 'top right', lastResort: 'top right' } )
  },

  edit() {
    assets.catalog_autocompletion('#projects')
    assets.remove_card('#projects')
    general.fetch_view($('#projects').find('.remote.catalog_base_object.card'))

    $('#projects .ui.button.quicklook').on('click', function() {
      return assets.quicklook_asset($(this).data('asset-id'))
    });
    $('.with-tooltip').popup({inline: true})
    
    projects.init_progress();
    
    calendar.datepicker("#projects")

    $('.not-for-production').hide()

    $('#projects .not-for-production-toggle.checkbox').checkbox({
      onChecked() {
        $("#projects").find('.not-for-production').show()
      },
      onUnchecked() {
        $("#projects").find('.not-for-production').hide()
      }
    })
    projects.init_dropdowns()

    search.project_title_seasons_autocomplete(".ui.search.project-title-seasons")
    search.project_assets_autocomplete('.ui.search.project-assets')

    projects.client_disc_spec()

    $("#project_p_c_ID").on('change', () => projects.client_disc_spec())

    projects.titles()
    projects.assets()
    projects.nested_fields()
  },

  show() {

  },

  index_menu() {
    $(".actions .set.project.value").on('click', function() {
      projects.init_set_value_modal()
    })

    $(".add.projects.assets.to.basket").off('click')
    $(".add.projects.assets.to.basket").on('click', function() {
      //baskets.open_quickmenu(true)
      projects.add_assets_to_basket()
    })
  },

  init_dropdowns() {
    $('#projects .ui.dropdown').not(".remote",'.custom').dropdown({ placeholder: false, fullTextSearch: true })

    let stamm_status_dropdowns = $('.stamm_status').find('.ui.custom.inline.dropdown')
    $.each(stamm_status_dropdowns, function(_i, d) {
      const stamm_status_dropdown = $(d)
      stamm_status_dropdown.dropdown()
      stamm_status_dropdown.removeClass('selection')
      stamm_status_dropdown.find('.dropdown.icon').appendTo(stamm_status_dropdown)
    })
  },

  init_index_body() {
    projects.init_progress()
  },
      
  init_progress() {
    return $('.progress').progress({ animating: false });
  },

  context_menu() {
  },

  init_overview_search_filters() {
    $('.upcoming.item').on("click", function() {
      const today = new Date()
      const strToday = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear()
      today.setDate(today.getDate() + 14)
      const strTwoWeeks = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear()
      $('#search_upload_date_start').val(strToday)
      $('#search_upload_date_end').val(strTwoWeeks)
      $("#search_type").dropdown('set exactly', 'VoD')
      $(".start-search .primary.button").trigger("click")
    })

    $('.upcoming-itunes.item').on("click", function() {
      // const today = new Date()
      // const strToday = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear()
      // today.setDate(today.getDate() + 30)
      // const strTwoWeeks = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear()
      // $('#search_upload_date_start').val(strToday)
      // $('#search_upload_date_end').val(strTwoWeeks)

      $("#search_platform").dropdown('set exactly', '7052')
      $("#search_type").dropdown('set exactly', 'VoD')
      $("#search_vod_status").dropdown('set exactly', 'ready for Delivery')
      $("#search_project_status").dropdown('set exactly', ['in progress', 'not started'])
      $(".start-search .primary.button").trigger("click")
    })
  },

  projects_assets() {
    $(".projects-assets-basket.modal .blue.button").addClass("disabled loading")
    $.ajax({
      url: `/projects/assets`,
      data: {
        project_ids: index_select.selected_ids(),
        language: $("#asset_language").val(),
        type: $("#asset_type").val(),
        not_for_production: $("#asset_not_for_production").val(),
        video_encoding_source: $("#video_encoding_source").val(),
        audio_encoding_source: $("#audio_encoding_source").val(),
        subtitle_encoding_source: $("#subtitle_encoding_source").val(),
        video_delivery_source: $("#video_delivery_source").val(),
        audio_delivery_source: $("#audio_delivery_source").val(),
        subtitle_delivery_source: $("#subtitle_delivery_source").val(),
      },
    }).done(function(result) {
      $(".projects-assets-basket .projects-assets-count").html(result.length)
      $(".projects-assets-basket .projects-assets").data("ids", result)
      $(".projects-assets-basket.modal .blue.button").removeClass("disabled loading")
    }).fail(function() {
    })
  },

  add_assets_to_basket() {
    $.ajax({
      url: `/projects/add_assets_to_basket`
    }).done(function(result) {
      $("body").append(result)

      $('.projects-assets-basket.modal').modal({
        autofocus: false,
        onShow() {
          $('.projects-assets-basket.modal .dropdown').not(".remote").dropdown()
          api.init()

          projects.projects_assets()

          $(".projects-assets-basket.modal .parameter").on("change", function() { 
            projects.projects_assets()
          })

          $(".projects-assets-basket.modal .blue.button").on("click", function() { 
            let asset_ids = $(".projects-assets-basket .projects-assets").data("ids")

            $(".projects-assets-basket.modal").remove()

            $('body').find(".dimmer").remove()
            $('body').removeClass("dimmable dimmed scrolling")
          
            baskets.open_quickmenu(true, "Asset", asset_ids)
          })
   
        },
        onHidden() {
          return $(this).remove();
        }
      }).modal("show")
      
    }).fail(function() {
    })
  },

  nested_fields() {
    $('#projects').off("fields_added.nested_form_fields.nested_project_assets");
    $('#projects').on("fields_added.nested_form_fields.nested_project_assets", function(event, param) {
      switch (param.object_class) {
        case "asset_project":
          projects.add_asset_card(event.target, param.additional_data)
          break
        case "title_project":
          projects.add_title_season_card(event.target, param.additional_data)
          break
        default:
          console.log("Fields were successfully added, callback not handled.")
          break
      }
    });
  },

  titles() {
    return $("#projects .title-season-id").each((_i, title) =>
      $.ajax({
        url: `/titles/season/${$(title).val()}/card`,
        data: {
          type: $(title).parent().find(".title-season-id-display").attr("data-type"),
        }
      }).done(function(result) {
        $(title).next().html(result);
      }).fail(function() {
      })
    );
  },

  assets() {
    return $("#projects .asset-id-display").each((_i, asset) =>
      $.ajax({
        url: `/assets/${$(asset).data("model-id")}/card`,
        data: {
          type: $(asset).attr("data-type"),
          fields: $(asset).attr("data-fields")
        }
      }).done(function(result) {
        $(asset).html(result);
      }).fail(function() {
      })
    );
  },

  add_title_season_card(target, data) {
    if (data !== undefined) {
      const { title } = data;
      $.ajax({
        url: `/titles/season/${title.ts_id}/card`,
        data: {
          type: $(target).find(".title-season-id-display").attr("data-type"),
          fields: $(target).find(".title-season-id-display").attr("data-fields")
        }
      }).done(function(result) {
        $(target).find(".title-season-id-display").html(result);
      }).fail(function() {
        console.log('error');
      });
      return $(target).find(".title-season-id").val(title.ts_id);
    }
  },
  
  add_asset_card(target, data) {
    if (data !== undefined) {
      const { asset } = data;
      $.ajax({
        url: `/assets/${asset.a_ID}/card`,
        data: {
          type: $(target).find(".asset-id-display").attr("data-type"),
          fields: $(target).find(".asset-id-display").attr("data-fields")
        }
      }).done(function(result) {
        $(target).find(".asset-id-display").html(result);
      }).fail(function() {
        console.log('error');
      });
      return $(target).find(".asset-id").val(asset.a_ID);
    }
  },

  client_disc_spec() {
    if ($("#project_p_c_ID").val() !== undefined) {
      let client_id;
      if ($("#project_p_c_ID").val() === "") {
        client_id = 0;
      } else {
        client_id = $("#project_p_c_ID").val();
      }
      return $.ajax({
        url: `/contacts/${client_id}/disc_specs`,
        type: "GET",
        dataType: "json",
        complete() {},

        success(data, _textStatus, _xhr) {
          const select = document.getElementById("project_lClientDiscSpecID");
          if (select !== null) {
            $("#project_lClientDiscSpecID").empty();
            let option = document.createElement("option");
            select.appendChild(option);
            for (let disc_spec of Array.from(data)) {
              option = document.createElement("option");
              option.value = disc_spec.lClientDiscSpecID;
              option.innerHTML = disc_spec.xDiscSpec;
              select.appendChild(option);
            }
            if ($(`#project_lClientDiscSpecID option[value='${$("#project_lClientDiscSpecID").data("selected")}']`).length !== 0) {
              $("#project_lClientDiscSpecID").val($("#project_lClientDiscSpecID").data("selected"));
            } else {
              $("#project_lClientDiscSpecID").val(null);
            }
            return $("#project_lClientDiscSpecID").change();
          }
        },

        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      });
    }
  },

  init_set_value_modal(project_id) {
    $.ajax({
      url: "/projects/set_value_modal",
    }).done(function(data) {
      $("body").append(data);
      $('.set.project.value.modal').modal({
        autofocus: false,
        allowMultiple: true,
        onShow() {
          calendar.datepicker('.set.project.value.modal')
          $('.set.project.value.modal .dropdown').dropdown()

          const q_count = $(".select.index.elements").data("selected").length

          if (q_count > 1) {
            $('.set.project.value.modal .projects-search-count').text($(".select.index.elements").data("selected").length)
          } else {
            $('.set.project.value.modal .warning.message').hide()
          }

          let button = $('.set.project.value.modal .ui.set-value.button')
          button.on("click", function() {
            button.addClass("loading");
            $.ajax({
              url: "/projects/set_value",
              type: "POST",
              dataType: "json",
              data: {
                model_ids: projects.selected_project_ids(project_id),
                projects_params: general.collect_parameter('.set.project.value.modal')
              }
            }).done(function(_data) {
              button.removeClass("loading");
              if ($(".select.index.elements").data("active")) {
                index_select.end_selection()
              }
              $('.set.project.value.modal').modal('hide')
              $(".start-search .primary.button").trigger("click")
            }).fail(() => button.removeClass('loading').removeClass('loading green').addClass('red').text("Error"));
          });
        }, 
        onHidden() {
          $('.set.project.value.modal').remove();
        }
      }).modal("show");

    }).fail(() => console.log("error"));
  },

  selected_project_ids(project_id) {
    if ($(".select.index.elements").data("active")) {
      return index_select.selected_ids()
    } else {
      return project_id
    }
  },
};
    
window.projects = projects;
