import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    if (document.querySelector('#user_profile_webs.edit')) {
      user_profile_webs.edit()
    }
  }

  checkChangePasswordInput() {
    if (this.inputTargets.every((t) => t.value.length > 0)) {
      $('.modal .approve.button').removeClass('disabled')
    } else {
      $('.modal .approve.button').addClass('disabled')
    }
  }

  showChangePasswordModal() {
    $('.change_password.modal').modal({
      onApprove() {
        $('#change_password_form').trigger('submit')
      }
    }).modal('show')
  }

  showCreateAccountModal() {
    console.log("showCreateAccountdModal")
    $('.create_account.modal').modal({
      onApprove() {
        $('#change_password_form').trigger('submit')
      }
    }).modal('show')

  }
}
