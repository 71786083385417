import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
  ]

  static values = {
    paramsId: String,
    videoPathRequired: { type: Boolean, default: true },
    assetId: String,
    proxyMountPoint: String,
  }
  
  connect() {
    const c = this
    if (c.videoPathRequiredValue) { forms.disable("video_proxy") }

    $(c.element).find(`#video_asset_search_selection_${c.paramsIdValue}`).dropdown({
      fireOnInit: true,
      apiSettings: {
        cache: false,
        url: '/assets/search_dropdown?q={/query}',
        data: {
          asset_id: parseInt(c.assetIdValue),
          asset_type: 1,
          with_lowres_proxy: true
        },
      },
      templates: {
        menu: dropdownTemplates.asset_search_menu
      },
      onChange(value, _text, _$selectedItem) {
        c.set_video_path(value)
      }
    })
  }

  set_video_path(value) {
    const c = this
    if (value !== null) {
      $(c.element).find(`#video_path_${c.paramsIdValue}`).val(`${c.proxyMountPointValue}\\${value}\\${value}.mov`).trigger('change')
      $(c.element).find(`#video_present_${c.paramsIdValue}`).val(true).trigger('change')
      forms.enable("video_proxy")
    }
  }

  disconnect() {
    forms.enable("video_proxy")
  }
}
