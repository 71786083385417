import Timecode from './timecode.js'

var assetEvent = {
  init() {
    $('.asset-event-metadata.nested-fields').hide()

    const language_dropdown = $('#event-languages').dropdown({
      onAdd(_addedValue, _addedText, _addedChoice) {
        $('.add-event-button').show()
      },
      onChange(value, _text, _$choice) {
        if (language_dropdown.dropdown('get values').length === 1) {
          assetEvent.set_default_language(value)
          language_dropdown.dropdown('set selected', value)
          if ($('#event-languages').parent().find("a").length > 0) {
            const default_value = $("#metadata_default_language").val()
            const default_label = assetEvent.findLabelWithValue(default_value)
            if (default_label?.length > 0) {
              $(default_label).addClass('language-selected')
              assetEvent.selectLabel(default_label)
            }
          }
        }
      },
      onRemove(removedValue, _removedText, _removedChoice) {
        if (removedValue !== "") {
          $(`.asset-event-metadata.nested-fields.${removedValue}`).find('.remove-event-language-button').trigger('click')
        }
        if (language_dropdown.dropdown('get value').length === 1) {
          $('.add-event-button').hide()
        }
      },
      onLabelSelect(selectedLabel) {
        assetEvent.selectLabel(selectedLabel)
        $(selectedLabel).removeClass('active')
      },
      onLabelCreate(value, _text) {
        assetEvent.check_default_language($(this))
        if (value !== "") {
          if ($(`.asset-event-metadata.nested-fields.${value}`).length === 0) {
            const default_value = $('#metadata_default_language').val()
            const iterable = $('.add-event-language-button')
            for (let i = 0; i < iterable.length; i++) {
              const button = iterable[i]
              window.nested_params = { value, is_default: default_value === value, chapter_title: `${assetEvent.get_chapter_title_prefix(value)} ${i+1}` }
              $(button).trigger('click')
            }
            assetEvent.selectLabel($(this))
            assetEvent.hide_and_show(value)
          }
        }
        return $(this)
      },
      apiSettings: {
        cache: false,
        url: '/languagecode?q={/query}',
        data: {
          default_filter: $('#add-event-language').data('language-default-filter')
        }
      }
    })

    $('#metadata_is_default_language').on("change", function() {
      const language_code = assetEvent.get_selected_language_code()
      if ($(this).prop('checked')) {
        assetEvent.set_default_language(language_code)
      } else {
        assetEvent.set_default_language()
      }
    })

    assetEvent.init_player_control_buttons()

    if ($('#event-languages').length > 0) {
      for (let language of Array.from($('input[name="language_ids"]'))) {
        $('#event-languages').dropdown('set selected', $(language).val(), true)
      }

      for (let label of Array.from($('#event-languages').parent().find("a"))) {
        $(label).removeClass('language-selected')
      }

      if ($('#event-languages').parent().find("a").length > 0) {
        const default_value = $("#metadata_default_language").val()
        const default_label = assetEvent.findLabelWithValue(default_value)
        if (default_label?.length > 0) {
          $(default_label).addClass('language-selected')
          assetEvent.selectLabel(default_label)
        }
      }
    }

    if ($('.chapter.buttons').length > 0) {
      assetEvent.check_crop_values()
    }

    $('#update-chapter-images').on('click', function() {
      assetEvent.show_update_chapter_images_modal()
    })

    $('#copy-chapter-button').on('click', function() {
      $('#copy-chapter-button').addClass('disabled loading')
      const form = $("#asset")
      const url = form.attr('action')
      $.ajax({
          type: "POST",
          url: url,
          data: form.serialize()
      }).done(() => {
        assetEvent.copy_selected_chapter()
      }).fail((error) => {
        $('#copy-chapter-button').removeClass('disabled loading')
        $('body').toast({
          title: 'Could not update asset!',
          class: 'error',
          displayTime: 'auto',
          message: `${error}`
        })
      })
    })
  },

  copy_selected_chapter() {
    const form = $("#asset")
    const url = `${form.attr('action')}/copy_selected_chapters`
    $.ajax({
        type: "POST",
        url: url,
        dataType: "json"
    }).done(() => {
      $('body').toast({
        title: "Copy selected chapters",
        class: 'success',
        displayTime: 3000,
        message: "Selected chapters will be copied."
      })
    }).fail((_xhr, text, _errorThrown) => {
      $('body').toast({
        title: "Copy selected chapters",
        class: 'error',
        displayTime: 3000,
        message: text
      })
    }).always(() => {
      $('#copy-chapter-button').removeClass('disabled loading')
    })
  },

  show_update_chapter_images_modal() {
    $("#update-chapter-images").addClass("loading")
    $.ajax({
      url: `/assets/${$('#asset_a_ID').val()}/update_chapter_images_modal`,
      type: "GET"
    }).done(function(data) {
      $("body").append(data)
      $('.update.chapter.images.modal').modal({
        onShow() {
          $("#update-chapter-images").removeClass("loading")
          $(this).find('.ui.dropdown').dropdown()

          let update_button = $(this).find(".update-chapter-images.button")
          update_button.on("click", function() {
            update_button.addClass("loading")
            $.ajax({
              url: `/assets/${$('#asset_a_ID').val()}/update_chapter_images`,
              data: {
                chapter_source: $('#chapter_source').dropdown('get value')
              }
            }).done(function(data) {
              if (data != null) {
                Turbo.visit("/workflow_jobs/" + data.wj_ID)
              } else {
                Turbo.visit($(".breadcrumb-nav span.navigation a.section").attr("href"))
              }
            }).fail(function(error) {
              console.log(error)
              update_button.removeClass("loading")
            })
          })
        },
        onHidden() {
          $('.update.chapter.images.modal').remove()
        },
        autofocus: false
      }).modal("show")
    })
  },

  hide_and_show(value) {
    let language_code
    if (value == null) { value = undefined }
    if (value === undefined) {
      language_code = assetEvent.get_selected_language_code()
    } else {
      language_code = value
    }
    $(".asset-event-metadata.nested-fields").hide()
    if (language_code !== "") {
      return $(`.asset-event-metadata.nested-fields.${language_code}`).show()
    }
  },

  get_selected_language_code() {
    const language_code = $('.ui.label.language-selected').data('value')
    return language_code
  },

  selectLabel(label) {
    $('.ui.label.language-selected').removeClass('language-selected')
    $(label).addClass('language-selected')
    assetEvent.check_default_language(label)
    assetEvent.hide_and_show()
  },

  set_default_language(language_code) {
    $(".asset-event-metadata.nested-fields").find('.asset_event_metadata_default_language').val(false)
    if (language_code === undefined) {
      $("#metadata_default_language").val("")
    } else {
      $("#metadata_default_language").val(language_code)
      $('#metadata_is_default_language').prop('checked', true)
      $(`.asset-event-metadata.nested-fields.${language_code}`).find('.asset_event_metadata_default_language').val(true)
    }
  },

  check_default_language(label) {
    if ($(label).hasClass('language-selected')) {
      const default_language = $("#metadata_default_language").val()
      $('#metadata_is_default_language').prop('checked', $(label).data('value') == default_language)
    }
  },

  findLabelWithValue(value) {
    return $('#event-languages').parent().find(`a[data-value='${value}']`)
  },

  check_crop_values() {
    const confirmed_crop_value_fields = ["asset_a_CropPixelTop", "asset_a_CropPixelBottom", "asset_a_CropPixelLeft", "asset_a_CropPixelRight"]
    const automatic_crop_value_fields = ["asset_a_CropPixelTopAutoMeasurement", "asset_a_CropPixelBottomAutoMeasurement", "asset_a_CropPixelLeftAutoMeasurement", "asset_a_CropPixelRightAutoMeasurement"]
    const open_button = $('.chapter.buttons .open.button')
    const automatic_crop_button = $('.chapter.buttons .automatic.crop.item')
    const without_crop_button = $('.chapter.buttons .without.crop.item')

    if (confirmed_crop_value_fields.find((i) => !document.getElementById(i).value || !document.getElementById(i).value === "" )) {
      $(open_button).addClass("disabled").html('<i class="images icon"></i> Show Automatic Chapter (Missing Crop Values)')
      $('.chapter.buttons .dropdown.button').show()
    } else {
      $(open_button).removeClass("disabled").html('<i class="images icon"></i> Show Automatic Chapter')
      $('.chapter.buttons .dropdown.button').hide()
    }

    if (automatic_crop_value_fields.find((i) => !document.getElementById(i).value || !document.getElementById(i).value === "" )) {
      automatic_crop_button.hide()
      without_crop_button.show()
    } else {
      automatic_crop_button.show()
      without_crop_button.hide()
    }
  },

  restore_automatic_chapter() {
    const existing_chapters = document.getElementsByClassName("chapter-thumb-tc")
    $('#automatic-chapter-selection .checkbox').checkbox({ fireOnInit: true })
    $('#automatic-chapter-selection img').on('load', (event) => {
      $(event.target).parent().find(".placeholder").remove()
    })
    return Array.from(existing_chapters).map((chapter) =>
      $(`#automatic-chapter-selection .checkbox:has(.radio-select[value='${$(chapter).val()}'])`).checkbox('check'))
  },

  get_automatic_chapter() {
    $.ajax({
      url: `/assets/${$("#asset_a_ID").val()}/automatic_chapter`,
      type: "GET",
      dataType: "script",
      complete() {
        assetEvent.restore_automatic_chapter()
      },
      success(_data, _textStatus, _xhr) {},
      error(_jqXHR, _textStatus, errorThrown) {
        console.log("automatic-chapter error")
        console.log(errorThrown)
      }
    })
  },

  get_chapter_title_prefix(value) {
    let language
    switch (`${value}`) {
      case "22": language = "Kapitel"; break
      case "25": language = "Chapter"; break
      case "34": language = "Chapitre"; break
      case "52": language = "Capitolo"; break
      case "123": language = "Bölüm"; break
      default: language = ""
    }
    return language
  },

  init_player_control_buttons() {
    if($('.preview.playbutton').length > 0) {
      $('.player.icon.input').off('mouseenter', 'mouseleave')
      $('.player.icon.input').on('mouseenter', function() {
        if (typeof player !== 'undefined') {
          $(this).find('i.set-tc.icon').show()
          if ($(this).find('input').val().length > 0) {
            $(this).find('i.play.icon').show()
          } 
        }
      }).on('mouseleave', function() {
          $(this).find('i').hide()
      })
    }

    $('.player .link.icon').on('mouseenter', function() {
      $(this).addClass('primary')
    }).on('mouseleave', function() {
      $(this).removeClass('primary')
    })

    if($('.preview.playbutton').length > 0) {
      $('.player .play.icon').off('click')
      $('.player .play.icon').on('click', function() {
        const tc_value = $(this).siblings('input').val()
        const framerate = $('.asset-events').data('framerate')
        const tc_format = $('.asset-events').data('tcFormat')
        const offset = $('.asset-events').data('offset')
        const tc = Timecode.from_string(tc_value, framerate, tc_format, offset)
        window.playerTools.jumpToTime(tc.to_runtime_seconds(), true)
      })
    }
    
    $(".player .set-tc.icon").off('click')
    $(".player .set-tc.icon").on('click', function() {
      if (typeof player !== 'undefined') {
        const target = $(this).siblings('input')
        target.val(playerTools.current_tc())
        target.addClass('highlight').delay(400).queue(() => target.removeClass('highlight').dequeue())
      }
    })
  },

  check_thumb_tcs() {
    const update_button = $('#update-chapter-images')
    const warning_button = $('.update-chapter-images.button')
    const thumb_tc_array = Array.from(document.getElementsByClassName("chapter-thumb-tc"))
    if (thumb_tc_array.every((input) => input.value.length > 0)) {
      update_button.show()
      warning_button.hide()
    } else {
      update_button.hide()
      warning_button.show()
    }

    thumb_tc_array.forEach((thumb_tc_input, i) => {
      try {
        if (thumb_tc_input?.value) {
          const asset_event = thumb_tc_input.closest('.asset_event')
          const chapter_start_input = asset_event.querySelector(`[id$="${i}_ae_EventTcIn"]`)
          const chapter_end_input = asset_event.querySelector(`[id$="${i+1}_ae_EventTcIn"]`)
    
          const thumb_tc = parseInt(thumb_tc_input?.value?.replace(/:/g, ''))
          const chapter_start_tc = parseInt(chapter_start_input?.value?.replace(/:/g, ''))
          let chapter_end_tc = parseInt(chapter_end_input?.value?.replace(/:/g, ''))
          document.getElementById('asset_a_FileEndTimecode').value

          if (i == thumb_tc_array.length - 1) chapter_end_tc = parseInt(document.getElementById('asset_a_FileEndTimecode')?.value?.replace(/:/g, ''))
          if (thumb_tc < chapter_start_tc || thumb_tc > chapter_end_tc) {
            throw Error('out of range')
          }

        }
        thumb_tc_input.parentNode.classList.remove('error')
      } catch(error) {
        thumb_tc_input.parentNode.classList.add('error')
      }
      
    })

  }
}


window.assetEvent = assetEvent
