import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    //console.log("user connect")
    if ($("#user_session").length > 0) {
      user_session.validate_password_forgotten_input()
    }
  }

}
