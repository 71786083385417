document.addEventListener("turbo:load", function() {
  dashboard.init()
});

var dashboard = {
  init() {
    if ($("#dashboard").length > 0) {

      $("#dashboard-options").dropdown({
        placeholder: false,
        action: "hide",
        onHide : function() {
          $(this).dropdown('clear');
        },
      });

      dashboard.stats();
  
      $('.ui.inline.right.pointing.workflow.dropdown.pull-right').dropdown({
        onChange(_value, _text, _$selectedItem) {
          users.settings("dashboard.workflow.range", $(".dashboard.workflow.range").dropdown("get value"));
          return dashboard.fill_workflow_chart();
        }
      }).dropdown('set selected', $('#workflow_range').val());
  
      $('.ui.inline.right.pointing.dfl.dropdown.pull-right').dropdown({
        onChange(_value, _text, _$selectedItem) {
          users.settings("dashboard.dfl.range", $(".dashboard.dfl.range").dropdown("get value"));
          dashboard.fill_dfl_chart();
        }
      }).dropdown('set selected', $('#dfl_range').val());

      if ($(".datamover.downtimes").length > 0) {
        dashboard.datamover_downtimes();
      }
  
      if ($("#projects-chart").length > 0) {
        dashboard.fill_projects_chart();
      }
  
      if ($("#asset-chart").length > 0) {
        dashboard.fill_asset_chart();
      }
  
      if ($("#workflow-job-chart").length > 0) {
        dashboard.fill_workflow_chart();
      }
  
      if ($("#dfl-job-chart").length > 0) {
        dashboard.fill_dfl_chart();
      }
  
      if ($("#my-qc-elements").length > 0) {
        dashboard.fill_my_qc_elements()
      }
  
      if ($(".latest_proxy_videos.overview").length > 0) {
        dashboard.fill_latest_proxies()
      }
  
      if ($(".asset_requests.overview").length > 0) {
        dashboard.fill_asset_requests()
      }

      if ($(".asset_requests.overdue").length > 0) {
        dashboard.fill_asset_requests_overdue()
      }

      if ($(".asset_requests.needed").length > 0) {
        dashboard.fill_asset_requests_needed()
      }

      $(".input.asset_requests input").on('change', function() {
        dashboard.fill_asset_requests_unassigned()
      })
      if ($(".asset_requests.unassigned").length > 0) {
        dashboard.fill_asset_requests_unassigned()
      }
      if ($(".asset_requests.latest_comments").length > 0) {
        dashboard.fill_asset_requests_latest_comments()
      }

      if ($("#news").length > 0) {
        //dashboard.latest_news()
      }

      if ($("#latest_base_objects").length > 0) {
        dashboard.latest_base_objects()
      }
    }
  },

  init_index_body() {
    dashboard.init()
  },
 
  let_it_snow() {
    $("body").append("<div id='let-it-snow'></div>");
    particlesJS("let-it-snow", {
      "particles": {
        "number": {
          "value": 400,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#cccccc"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0.5,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          }
        },
        "opacity": {
          "value": 0.5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": false,
          "distance": 500,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 2
        },
        "move": {
          "enable": true,
          "speed": 6,
          "direction": "bottom",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "bounce": false,
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "bubble"
          },
          "onclick": {
            "enable": true,
            "mode": "repulse"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 0.5
            }
          },
          "bubble": {
            "distance": 400,
            "size": 4,
            "duration": 0.3,
            "opacity": 1,
            "speed": 3
          },
          "repulse": {
            "distance": 200,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    });
  },

  fill_projects_chart() {
    $.ajax({
      data: {
        projects_filter: $('#projects_filter').val(),
        platform_filter: $('#platform_filter').val()
      },
      url: "/charts/dashboard_project",
      type: "GET",
      dataType: "json",
      success(data, _textStatus, _xhr) {
        charts.dashboard_project_chart('projects-chart', data);
      }
    });
  },

  fill_asset_chart() {
    $.ajax({
      url: "/charts/dashboard_asset_user_history",
      type: "GET",
      dataType: "json",
      success(data, _textStatus, _xhr) {
        charts.asset_user_history_chart('asset-chart', data);
      }
    });
  },

  fill_workflow_chart() {
    $.ajax({
      url: "/charts/dashboard_workflow_job",
      type: "GET",
      dataType: "json",
      data: {
        weeks: $(".dashboard.workflow.range").dropdown("get value")
      },
      success(data, _textStatus, _xhr) {
        return charts.workflow_job_chart('workflow-job-chart', data);
      }
    });
  },

  fill_dfl_chart() {
    $.ajax({
      url: "/charts/dashboard_dfl_job",
      type: "GET",
      dataType: "json",
      data: {
        weeks: $(".dashboard.dfl.range").dropdown("get value")
      },
      success(data, _textStatus, _xhr) {
        return charts.dfl_job_chart('dfl-job-chart', data);
      }
    });
  },

  fill_latest_proxies() {
    $.ajax({
      url: "/dashboard/latest_proxies",
      success(data, _textStatus, _xhr) {
        return $(".latest_proxy_videos.overview").html(data);
      }
    });
  },

  fill_asset_requests() {
    $.ajax({
      url: "/dashboard/asset_requests",
      success(data, _textStatus, _xhr) {
        return $(".asset_requests.overview").html(data);
      }
    });
  },

  fill_asset_requests_overdue() {
    $.ajax({
      url: "/dashboard/asset_requests_list_overdue",
      success(data, _textStatus, _xhr) {
        return $(".asset_requests.overdue").html(data);
      }
    });
  },

  fill_asset_requests_needed() {
    $.ajax({
      url: "/dashboard/asset_requests_needed",
      success(data, _textStatus, _xhr) {
        $(".asset_requests.needed").html(data);

        asset_requests.index_charts()
      }
    });
  },

  fill_asset_requests_unassigned() {    
    const search_term = $(".input.asset_requests input").val()

    $.ajax({
      url: `/asset_requests`,
      dataType: "script",
      data: {
        type: "mini",
        search_input: search_term
      },
      beforeSend() {

      }
    }).always(function(_data) {
      $(".input.asset_requests").removeClass("loading")
    })
  },

  fill_asset_requests_latest_comments() {
    //search_term = $(".input.asset_requests input").val()

    $.ajax({
      url: `/asset_requests/asset_requests_latest_comments`,
      dataType: "script",
      data: {
        // type: "mini",
        //search_input: search_term
      },
      beforeSend() {

      }
    }).always(function(_data) {
      $(".input.asset_requests").removeClass("loading")
    })
  },

  latest_base_objects() {
    $.ajax({
      url: `/catalog_base_objects/latest`,
      dataType: "script",
      data: {
        type: "compact",
        items_per_page: 5
      },
      beforeSend() {

      }
    }).always(function(_data) {
    })
  },

  fill_my_qc_elements() {
    const qc_elements_view = [
      [`search_assigned[]=${$("#upw_ID").val()}&search_status[]=not%20started&type=compact`, "#my-qc-elements .qc-elements-todo"],
    ]
    
    $.each(qc_elements_view, function( _index, value ) {
      qc_elements.qc_elements_search(value[0], value[1])
    })
  },


  datamover_downtimes() {
    $.ajax({
      url: `/datamover/downtimes`,
      success(data, _textStatus, _xhr) {
        $(".datamover.downtimes").html(data)
      }
    })
  },

  stats() {
    for (const stat_segment of document.querySelectorAll(".dashboard-stats .stats.segment")) {
      fetch(`/dashboard/stats?model=${stat_segment.dataset.model}`)
      .then(response => response.json()).then(data => stat_segment.querySelector('.count').innerText = data)
    }
  }
};

window.dashboard = dashboard;
