import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    'asset'
  ]

  static values = {
    assetTags: { type: Array, default: [] }
  }
  
  static targets = [
    'filterByClientCheckbox'
  ]

  connect() {
    this.tagsNestedFields()
    this.tagsAutocompletion()
  }

  tagsNestedFields() {
    const c = this
    $(c.element).off("fields_added.nested_form_fields")
    $(c.element).on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "asset_asset_tag":
          var tag_element = $(event.target).find("#asset-tag-")
          
          $(`#asset_asset_asset_tags_attributes_${param.added_index}_atag_tag_ID`).val(param.additional_data.asset_tag.id)
          tag_element.attr("data-asset-tag-tag-id-value", param.additional_data.asset_tag.id)
          tag_element.attr("id", "asset-tag-" + param.additional_data.asset_tag.id)
          c.assetTagsValue = [...c.assetTagsValue, param.additional_data.asset_tag.id]
          return
      }
    })

    $(c.element).off("fields_removed.nested_form_fields")
    $(c.element).on("fields_removed.nested_form_fields", function(event, param) {
      const removed_value = $(event.target).find('[id$=atag_tag_ID]').val()
      c.assetTagsValue = c.assetTagsValue.filter(t => t !== parseInt(removed_value))      
    })
  }

  tagsAutocompletion() {
    const c = this
    let search_field = $(c.element).find(".search.asset_tags")
    search_field.search("destroy")    
    const url = '/asset_tags.json?search_input={/query}&q={/query}'

    search_field.search({
      cache: false,
      minCharacters: 0,
      apiSettings: {
        url,
        beforeSend: function(settings) {
          let search_contact = $(c.filterByClientCheckboxTarget).checkbox('is checked') ? (c.filterByClientCheckboxTarget.dataset?.contactId || $('#asset_a_c_ID').val()) : ''
          settings.data = {
            search_contact,
            exclude_ids: asset_tags.exlude_asset_tags(),
          }
          return settings
        }
      },
      fields: {
        results: "asset_tags"
      },
      templates: {
        standard: asset_tags.search_template
      },
      onSelect(result, _response) {
        $(c.element).find('.search.asset_tags .add_nested_fields_link').trigger('click', [{ asset_tag: result }])

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    })
  }

  close(event) {
    if (event.params?.closeType == "submit") {
      Turbo.visit(window.location.href)
    }
    if (event.params?.closeType == 'ok') {
      $('#asset_asset_tag_ids').val(this.assetTagsValue.join(' '))
      $('#asset_asset_tag_ids')[0]?.dispatchEvent(new Event("change"))
    }
    $(this.element).modal('hide')
  }
  
}
