document.addEventListener("turbo:load", function() {
  if ($("#post").length > 0) {
    search.add_pagination_to_actionbar();
    $('.tooltip').popup({
      delay: {
        show: 800,
        hide: 300
      }
    });
    if ($("#post .editor").length > 0) {
      
      // const autolist = new AutoList();
      // new MediumEditor('.editor', { 
      //   placeholder: {
      //     text: 'Write your story...',
      //     hideOnClick: true
      //   },
      //   extensions: { 
      //     autolist
      //   },
      //   toolbar: {
      //     allowMultiParagraphSelection: true,
      //     align: 'center',
      //     buttons: [
      //       'bold',
      //       'italic',
      //       'underline',
      //       'anchor',
      //       'h2',
      //       'h3',
      //       'quote',
      //       'unorderedlist'
      //     ]
      //   }
      // }
      // );
    }

    const stats = () =>
      $.ajax({
        url: '/posts/draft/stats',
        type: "POST",
        data: { 
          body: $(".editor.medium-editor-element").html()
        }
      }).done(function(result) {
        $(".word_count").html(result["read_time"] + " min read");
        let word_count = result["word_count"] + " Words";
        if (result["word_count"] === 0) {
          word_count = "Blank Page";
        }
        $(".word_count").attr("data-content", word_count);
      })
    ;
      
    if ($('.editor.medium-editor-element').length > 0) {
      stats();
      return $('.editor.medium-editor-element').on('DOMSubtreeModified', function() {
        stats();
      });
    }
  }
});
