var workflow_7203 = {
  init(target, _params_id) {
    // change decoder to force correct selection
    target.find('.decoder-select').trigger('change')

    let deployment_specification_vr_checkbox = target.find('.deployment_specification.vr.checkbox')
    let deployment_specification_music_checkbox = target.find('.deployment_specification.music.checkbox')

    deployment_specification_vr_checkbox.checkbox({
      fireOnInit: true,
      onChecked() { deployment_specification_music_checkbox.checkbox('uncheck') },
      onUnchecked() { deployment_specification_music_checkbox.checkbox('check') }
    })
    deployment_specification_music_checkbox.checkbox({
      fireOnInit: true,
      onChecked() { deployment_specification_vr_checkbox.checkbox('uncheck') },
      onUnchecked() { deployment_specification_vr_checkbox.checkbox('check') }
    })

    let tachyon_checkbox = target.find('.tachyon.checkbox').checkbox()
    target.find('.mezzanine.checkbox').checkbox({
      onChecked() {
        tachyon_checkbox.removeClass('disabled')
      },
      onUnchecked() {
        tachyon_checkbox.checkbox('uncheck')
        tachyon_checkbox.addClass('disabled')
      }
    });
  }
};

window.workflow_7203 = workflow_7203;

