document.addEventListener("turbo:load", function() {

  if ($("#asset_requests.index").length > 0) {
    asset_requests.index();
  }
  if ($("#asset_requests.edit").length > 0) {
    asset_requests.edit();
  }

  if ($("#asset_requests.show").length > 0) {
    asset_requests.show();
  }
});
var arq_quicklook_request = null
var quicklook_loading_indicator = null

var asset_requests = { 

  index() {
    search.init("/asset_requests").then(function() {
      assets.set_language_search('.search.bar')

      calendar.datepicker(".created_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".created_date_end", { position: 'top right', lastResort: 'top right' } )
      calendar.datepicker(".due_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".due_date_end", { position: 'top right', lastResort: 'top right' } )
    })
  },

  adjust_form_body() {
  },

  init_index_body() {
    general.quicklook_icon('#asset_requests .cover.quicklook')

    asset_requests.index_charts()
    asset_requests.init_overview_search_filters()

    $('#asset_requests .quicklook').on("click", function() {
      quicklook_loading_indicator = $('<div class="ui active ajax dimmer"><div class="ui loader"></div></div>').appendTo(this)
      $(this).find(".quicklook-icon").remove()
      asset_requests.quicklook($(this).data('model-id'))
    })
  },

  quicklook(model_id) {
    if(arq_quicklook_request) { arq_quicklook_request.abort() }
    arq_quicklook_request = $.ajax({
      url: `/asset_requests/${model_id}/quicklook`,
      cache: false,
      data:{
        modal: true
      }
    }).done(function(data, _textStatus, _xhr) {
      var quicklook_modal = $(data).appendTo('body')      
      quicklook_modal.modal({
        duration: 200,
        onShow() {
          asset_requests.show()
          context_menu.init()
          quicklook_loading_indicator.remove()
        },
        onHidden() {
          quicklook_modal.remove()
        }
      }).modal('show')

    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      if (errorThrown != 'abort') {
        console.log(errorThrown)
        quicklook_loading_indicator.remove()
      }
    })
  },

  edit() {
    calendar.datepicker('#asset_requests')
    calendar.yearpicker()

    calendar.datepicker(".due_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".due_date_end", { position: 'top right', lastResort: 'top right' } )

    assets.set_language_search('#asset_requests')

    asset_requests.comments_accordion()
    asset_requests.comments()

    if ($('.upload.asset.button').length) {
      asset_requests.init_upload_button()
    }

    asset_requests.init_dropdowns()
    asset_requests.check_partner_id();

    $("#asset_request_arq_c_ClientContactID").on('change', function() {
      asset_requests.check_partner_id();
      $('#asset_request_arq_c_ContentPartnerID').dropdown('clear');
      $('#asset_request_arq_c_FulfillmentPartnerID').dropdown('clear');
    });

    $('#asset_requests .status.dropdown').dropdown({
      onChange(value, _text, _$choice) {
        $("#asset_request_arq_ars_ID").val(value);
      }
    });
  },

  show() {
    if ($('.upload.asset.button').length) {
      asset_requests.init_upload_button()
      asset_requests.init_dropzone()
    }

    $("#asset_requests.show .menu .item").tab()
    $('.ui.checkbox').checkbox();

    asset_requests.comments_accordion()
    asset_requests.comments()
  },

  index_menu() {
    $(".actions .set.asset-request.value").on('click', function() {
      asset_requests.init_set_value_modal()
    })
  },

  comments() {
    $.ajax({
      url: `/asset_requests/${$("#asset_requests .send.comment.button").data("asset-request-id")}/comments`,
    }).done((data) => $("#asset_requests .comments").html(data))
  },

  comments_accordion() {
    const comment = $('#asset_requests .ui.accordion.add.comment')
    const send_button = $("#asset_requests .send.comment.button")

    const button = $("#asset_requests .add.comment.button")
    button.off('click')
    button.on('click', _ => comment.accordion("toggle", 0) )

    comment.accordion({
      onOpening() {
        button.addClass("basic")
        button.text("Cancel")

        send_button.off("click")
        send_button.on("click", function() {
          send_button.addClass("loading")
          $.ajax({
            url: `/asset_requests/${$(this).data("asset-request-id")}/comment`,
            type: "POST",
            dataType: "json",
            data: {
              comment:  $("#asset_requests #comment").val(),
              mail:  $("#asset_requests #comment_mail").prop("checked"),
            }
          }).done(function() {
            comment.accordion("toggle", 0)
            send_button.removeClass("loading")
            asset_requests.comments()
          })
        })
      },
      onClose() {
        send_button.off("click")
        button.removeClass("basic")
        button.text("Add Comment")
      }
    })
  },

  index_charts() {
    if (document.getElementById('asset-needed-chart')) {
      fetch("/charts/asset_requests.json?chart_type=asset-needed")
      .then(response => response.json()).then(data => charts.asset_needed_chart('asset-needed-chart', data))
    }

    if (document.getElementById('asset-per-day-chart')) {
      fetch("/charts/asset_requests.json?chart_type=asset-per-day")
      .then(response => response.json()).then(data => charts.asset_per_day_chart('asset-per-day-chart', data))
    }
  },

  check_partner_id() {
    if ($("#asset_request_arq_c_ClientContactID").val() === "") {
      $('#asset_request_arq_c_ContentPartnerID').dropdown().addClass('disabled');
      return $('#asset_request_arq_c_FulfillmentPartnerID').dropdown().addClass('disabled');
    } else {
      $('#asset_request_arq_c_ContentPartnerID').dropdown().removeClass('disabled');
      return $('#asset_request_arq_c_FulfillmentPartnerID').dropdown().removeClass('disabled');
    }
  },

  context_menu() {
    $(".item.asset_request.set-values").on('click', function() {
      asset_requests.init_set_value_modal([$(this).attr("data-target-id")])
    })
  },

  selected_asset_request_ids(asset_request_id) {
    if ($(".select.index.elements").data("active")) {
      return index_select.selected_ids()
    } else {
      return asset_request_id
    }
  },

  init_set_value_modal(asset_request_id = $(".select.index.elements").data("selected")) {
    $.ajax({
      url: "/asset_requests/set_value_modal",
    }).done(function(data) {
      $("body").append(data);
      $('.set.asset-request.value.modal').modal({
        autofocus: false,
        allowMultiple: true,
        onShow() {
          calendar.datepicker('.set.asset-request.value.modal')
          $('.set.asset-request.value.modal .dropdown').not(".remote").dropdown()
          api.init()
          asset_requests.init_contact_dropdowns(true)

          const arq_count = asset_request_id.length

          if (arq_count > 1) {
            $('.set.asset-request.value.modal .asset-requests-search-count').text(arq_count)
          } else {
            $('.set.asset-request.value.modal .warning.message').hide()
          }

          let button = $('.set.asset-request.value.modal .ui.set-value.button')
          button.on("click", function() {
            button.addClass("loading");
            asset_requests.set_values(asset_requests.selected_asset_request_ids(asset_request_id), general.collect_parameter('.set.asset-request.value.modal')).done(function(_data) {
              button.removeClass("loading");
              if ($(".select.index.elements").data("active")) {
                index_select.end_selection()
              }
              $('.set.asset-request.value.modal').modal('hide')
              $(".start-search .primary.button").trigger("click")
            }).fail(() => button.removeClass('loading').removeClass('loading green').addClass('red').text("Error"));
          });
        }, 
        onHidden() {
          $('.set.asset-request.value.modal').remove();
        }
      }).modal("show");

    }).fail(() => console.log("error"));
  },

  set_values(ids, parameter) {
    return $.ajax({
      url: "/asset_requests/set_value",
      type: "POST",
      dataType: "json",
      data: {
        model_ids: ids,
        asset_requests_params: parameter
      }
    })
  },

  init_overview_search_filters() {

    $('.asset_request.overview .over.due.item').on("click", function() {
      $("#search_asset_request_status").dropdown('set exactly',['2','3'])
      const cutoff_date = new Date(new Date().setDate(new Date().getDate() - 1))
      $('#search_due_date_start').val('').trigger('change')
      $('#search_due_date_end').val(cutoff_date.toLocaleDateString('de-DE')).trigger('change')
      $('#search_created_date_start').val('').trigger('change')
      $('#search_created_date_end').val('').trigger('change')
      $(".start-search .primary.button").trigger("click")
    })

    $('.asset_request.overview .redelivery.item').on("click", function() {
      $('#search_due_date_start').val('').trigger('change')
      $('#search_due_date_end').val('').trigger('change')
      $('#search_created_date_start').val('').trigger('change')
      $('#search_created_date_end').val('').trigger('change')
      $("#search_asset_request_status").dropdown('set exactly', '3')
      $(".start-search .primary.button").trigger("click")
    })

    $('.asset_request.overview .one.week.due.item').on("click", function() {
      $("#search_asset_request_status").dropdown('set exactly',['2','3'])
      const today = new Date()
      const cutoff_date = new Date(new Date().setDate(today.getDate() + 7))
      $('#search_due_date_start').val(today.toLocaleDateString('de-DE')).trigger('change')
      $('#search_due_date_end').val(cutoff_date.toLocaleDateString('de-DE')).trigger('change')
      $('#search_created_date_start').val('').trigger('change')
      $('#search_created_date_end').val('').trigger('change')
      $(".start-search .primary.button").trigger("click")
    })

    $('.asset_request.overview .two.week.due.item').on("click", function() {
      $("#search_asset_request_status").dropdown('set exactly',['2','3'])
      const today = new Date()
      const cutoff_date = new Date(new Date().setDate(today.getDate() + 14))
      $('#search_due_date_start').val(today.toLocaleDateString('de-DE')).trigger('change')
      $('#search_due_date_end').val(cutoff_date.toLocaleDateString('de-DE')).trigger('change')
      $('#search_created_date_start').val('').trigger('change')
      $('#search_created_date_end').val('').trigger('change')
      $(".start-search .primary.button").trigger("click")
    })

    $('.asset_request.overview .new.item').on("click", function() {
      const today = new Date()
      const one_week_ago = new Date(new Date().setDate(today.getDate() - 7))
      const cutoff_date = new Date(new Date().setDate(today.getDate() + 1))
      $('#search_due_date_start').val('').trigger('change')
      $('#search_due_date_end').val('').trigger('change')
      $('#search_created_date_start').val(one_week_ago.toLocaleDateString('de-DE')).trigger('change')
      $('#search_created_date_end').val(cutoff_date.toLocaleDateString('de-DE')).trigger('change')
      $("#search_asset_request_status").dropdown('set exactly',['2','3'])
      $(".start-search .primary.button").trigger("click")
    })

    $('.asset_request.overview .open.item').on("click", function() {
      $('#search_due_date_start').val('').trigger('change')
      $('#search_due_date_end').val('').trigger('change')
      $("#search_asset_request_status").dropdown('set exactly',['2','3'])
      $(".start-search .primary.button").trigger("click")
    })
  },

  init_dropzone() {
    let dropzone = document.querySelector("#asset_requests.show")
    new Dragster(dropzone)

    document.addEventListener("dragster:enter", function (_e) {
      $(dropzone).addClass('dropactive')
    }, false )
    
    document.addEventListener( "dragster:leave", function (_e) {
      $(dropzone).removeClass('dropactive')
    }, false );
  },

  init_dropdowns() {
    $("#asset_requests .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})

    asset_requests.init_contact_dropdowns()

    $('#asset_request_arq_ars_ID').dropdown({
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: `/asset_requests/${$('#asset_requests').data("id")}/possible_statuses`,
      },
    })

    $('#asset_request_arq_dct_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/distribution_channel_types/channel_types?q={/query}',
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    }).dropdown('set selected', $('#asset_request_arq_dct_ID').val())

    $('#asset_request_arq_t_ID').dropdown({
      minCharacters: 2,
      apiSettings: {
        cache: false,
        action: 'get titles',
        beforeSend(settings) {
          const value = $(this).find("select").data('value')
          const name = $(this).find("select").data('name')
  
          if ($(this).find("select").attr('params')) {
            settings.onResponse = function(JsonResponse) {
              const response = {
                success: true,
                results: []
              }
              $.each(JsonResponse, function(_index, item) {
                if (typeof item === 'object') { return response.results.push({ value: item[value], name: item[name] }) }
              })
              return response
            }
            $.extend(settings.urlData, $(this).find("select").attr('params'))
          }
          return settings
        }
      },
    }).dropdown('set selected', $('#asset_request_arq_t_ID').val())

    $('#asset_request_arq_a_ID').dropdown({
      minCharacters: 2,
      apiSettings: {
        cache: false,
        action: 'get assets',
        data: {
          search_client: $("#asset_request_arq_c_ClientContactID").val(),
          search_explicit_content: $("#asset_request_arq_IsExplicitContent").prop('checked').toString()
        },
        beforeSend(settings) {
          settings.data.search_client = $("#asset_request_arq_c_ClientContactID").val()
          settings.data.search_explicit_content = $("#asset_request_arq_IsExplicitContent").prop('checked').toString()

          return settings
        }
      },
    }).dropdown('set selected', $('#asset_request_arq_a_ID').val())


    $('#asset_request_arq_tc_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/assets/territories?q={/query}',
      },
      templates: {
        menu: dropdownTemplates.territory_search_menu
      }
    }).dropdown('set selected', $('#asset_request_arq_tc_ID').val())

    $('#asset_request_arq_dctmi_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/asset_requests/distribution_channel_type_mapping_images?q={/query}',
      },
      fields: {
        remoteValues: "distribution_channel_type_mapping_images",
      },
      templates: {
        menu: dropdownTemplates.distribution_channel_type_mapping_images
      }
    }).dropdown('set selected', $('#asset_request_arq_dctmi_ID').val())

    general.hover_link("assets")
    general.hover_link("titles")
  },

  init_contact_dropdowns(without_prefix = false) {
    let prefix = "asset_request_"
    if (without_prefix) { prefix = "" }

    $(`#${prefix}arq_c_ContentPartnerID`).dropdown({
      apiSettings: {
        url: '/contacts/contentpartners?q={/query}',
        data: {
          c_ID: $(`#${prefix}arq_c_ClientContactID`).val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $(`#${prefix}arq_c_ClientContactID`).val()
          return settings
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    }).dropdown('set selected', $(`#${prefix}arq_c_ContentPartnerID`).val())

    $(`#${prefix}arq_c_FulfillmentPartnerID`).dropdown({
      apiSettings: {
        url: '/contacts/fulfillment_partner?q={/query}',
        data: {
          c_ID: $(`#${prefix}arq_c_ContentPartnerID`).val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $(`#${prefix}arq_c_ContentPartnerID`).val()
          return settings
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    }).dropdown('set selected', $(`#${prefix}arq_c_FulfillmentPartnerID`).val())
  },

  gen_modal_from_init() {
    $('.generate-asset-requests.modal .ui.dropdown').not(".remote").not(".api").not(".mass").dropdown()

    $('.generate-asset-requests.modal .mass.language.code.ui.dropdown').dropdown({
      action: "combo",
      allowReselection: true,
      templates: {
        menu: dropdownTemplates.language_search_menu
      },
      onChange(value, _text, _choice) {
        let dropdown = $('.generate-asset-requests.modal .language.code.ui.dropdown').not(".mass")
        let i = dropdown.length;
        while (i--) {
          $(dropdown[i]).dropdown("set selected", value)
        }
      }
    });

    $('.generate-asset-requests.modal .mass.fulfillment.partner.ui.dropdown').dropdown({
      action: "combo",
      allowReselection: true,
      onChange(value, _text, _choice) {
        let dropdown = $('.generate-asset-requests.modal .fulfillment.partner.ui.dropdown').not(".mass")
        let i = dropdown.length;
        while (i--) {
          $(dropdown[i]).dropdown("set selected", value)
        }
      }
    });

    $('.generate-asset-requests.modal .mass.content.type.ui.dropdown').dropdown({
      action: "combo",
      allowReselection: true,
      onChange(value, _text, _choice) {
        let dropdown = $('.generate-asset-requests.modal .content.type.ui.dropdown').not(".mass")
        let i = dropdown.length;
        while (i--) {
          $(dropdown[i]).dropdown("set selected", value)
        }
      }
    });

    $('.generate-asset-requests.modal .mass.status.ui.dropdown').dropdown({
      action: "combo",
      allowReselection: true,
      onChange(value, _text, _choice) {
        let dropdown = $('.generate-asset-requests.modal .status.ui.dropdown').not(".mass")
        let i = dropdown.length;
        while (i--) {
          $(dropdown[i]).dropdown("set selected", value)
        }
      }
    });

    $('.generate-asset-requests.modal .ui.checkbox.create').checkbox()

    $('.generate-asset-requests.modal .ui.checkbox.select-all').checkbox({
      onChecked: function() {
        $('.generate-asset-requests.modal .ui.checkbox.create').checkbox('check')
      },
      onUnchecked: function() {
        $('.generate-asset-requests.modal .ui.checkbox.create').checkbox('uncheck')
      }
    })

    calendar.datepicker(".generate-asset-requests.modal")
  },

  gen_form_submit() {
    const button = $('.generate-asset-requests.modal .primary.button')
    button.on('click', function () {
      $('#gen_arq').trigger('submit')
    })

    api.init()

    $('#gen_arq').form({
      // fields: {
      //   identifier: '',
      //   rules: [
      //     {
      //       type   : 'minCount[1]',
      //       prompt : 'Please select an error code'
      //     }
      //   ]
      // },
      onSuccess: function (event) {
        event.preventDefault();
      }

    }).api({
      action: 'generate asset requests',
      method: 'post',
      serializeForm: true,

      beforeSend: function (settings) {
        console.log("Data submitted: " + settings)
        $('.ui.generate-asset-requests.modal').find(".generate.button").addClass("loading")
        $('.ui.generate-asset-requests.modal').find(".generate.button").removeClass("red").addClass("green")
        return $('#form').form('is valid')
      },
      onSuccess: function (response) {
        // valid response and response.success = true
        console.log(response)
        $('.ui.generate-asset-requests.modal').modal('hide')
        orders.load_asset_requests()
      },
      onFailure: function (response) {
        // request failed, or valid response but response.success = false
        $('.ui.generate-asset-requests.modal').find(".generate.button").removeClass("loading")
        $('.ui.generate-asset-requests.modal').find(".generate.button").removeClass("green").addClass("red")
        $('.ui.generate-asset-requests.modal').find(".generate.button").removeAttr("disabled")
        
        $('#form .error.message').text(response.message)
        $('#form').api('set error')
        console.log(response)
      }
    });
  },

  init_upload_button() {
    let drop_options = { drop: true, dragEnter: false, dragLeave: false }
    let drop_target = '#asset_requests.show .dropzone'
    asset_requests.data_transfer = null

    asset_requests.connect_client = new AW4.Connect({
      minVersion: '3.10.0',
      dragDropEnabled: true
    })
    let asperaInstaller = new AW4.ConnectInstaller()
    let statusEventListener = function (eventType, data) {
      if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.INITIALIZING) {
        asperaInstaller.showLaunching()
      } else if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.FAILED) {
        asperaInstaller.showDownload()
      } else if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.OUTDATED) {
        asperaInstaller.showUpdate()
      } else if (eventType === AW4.Connect.EVENT.STATUS && data == AW4.Connect.STATUS.RUNNING) {
        asperaInstaller.connected()
      }
    }

    function dropListener(callback) {
      let event = callback.event
      switch (event.type) {
        case "drop":
          asset_requests.data_transfer = callback.files
          fill_drop_zone()
          break
      }
    }

    function fill_drop_zone() {
      $('#asset_requests.show').addClass('listactive')
      const list = $(drop_target).find(".list")
      const header = $(drop_target).find(".filelist .header")
      const total = $(drop_target).find(".filelist .label .detail")
      list.find('.item').remove()

      //remove this to support multi file upload
      if (asset_requests.data_transfer.dataTransfer.files.length > 1) {
        asset_requests.data_transfer.dataTransfer.files.splice(0, asset_requests.data_transfer.dataTransfer.files.length - 1)
      }
      
      for (const [index, file] of Array.from(asset_requests.data_transfer.dataTransfer.files).entries()) {
        list.append(upload_list_item(file, index))
      }

      header.text(asset_requests.data_transfer.dataTransfer.files.length > 1 ? "Files to upload" : "File to upload")
      total.text(prettyBytes(asset_requests.data_transfer.dataTransfer.files.map(file => { return file.size }).reduce((sum, size) => sum + size)))

      $('.file.list .remove.button').on("click", function() {
        const index = $(this).closest('.item').data('index')
        if (asset_requests.data_transfer.dataTransfer.files.length > 0) {
          asset_requests.data_transfer.dataTransfer.files.splice(index, 1)
          if (asset_requests.data_transfer.dataTransfer.files.length > 0) {
            fill_drop_zone()
          }
          else {
            $('#asset_requests.show').removeClass('listactive')
          }
        } 
      })
    }

    function upload_list_item(file, index) {
      let filename = file.name.replace(/^.*[\\/]/, '')
      let filesize = file.size != 0 ? prettyBytes(file.size) : ""
      return `<div class="item" data-index="${index}">
        <div class="right floated content">
          <div class="ui basic remove button">Remove</div>
        </div>
        <i class="large middle aligned ${icon_for_filetype(file.type)} icon"></i>
        <div class="content">
          <a class="header">${filename}</a>
          <div class="description">${filesize}</div>
        </div>
      </div>`
    }

    function icon_for_filetype(filetype) {
      let icon = "file"
      if (/application\/zip/.test(filetype)) {
        icon += " archive"
      } else if (/application\/pdf/.test(filetype)) {
        icon += " pdf"
      } else if (/video/.test(filetype)) {
        icon += " video"
      } else if (/audio/.test(filetype)) {
        icon += " audio"
      } else if (/image/.test(filetype)) {
        icon = "photo video"
      } else if (/\xml/.test(filetype)) {
        icon += " code"
      } else if (/text\//.test(filetype)) {
        icon += " alternate"
      } else if (/\.sheet/.test(filetype)) {
        icon += " excel"
      } else if (/\/directory/.test(filetype)) {
        icon = "folder open"
      }
      return icon
    }

    asset_requests.connect_client.initSession()
    asset_requests.connect_client.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener)
    asset_requests.connect_client.addEventListener('transfer', asset_requests.fileControls.handleTransferEvents)
    asset_requests.connect_client.setDragDropTargets(drop_target, drop_options, dropListener)

    $('.upload.asset.button').on("click", function() {
      asset_requests.connect_client.showSelectFileDialog({success: asset_requests.fileControls.uploadFiles}, {allowMultipleSelection: false})
    })

    $('.start.transfer.button').on("click", function() {
      $(this).addClass('disabled')
      asset_requests.fileControls.uploadFiles(asset_requests.data_transfer)
    })
  },

  set_card_data(target, additional_data) {
    var card = $(target).find(".asset_request.remote.card")

    $(card).data("model-id", additional_data.asset_request.arq_ID)

    general.fetch_view(card).then(function(_data) {
      $(target).find(".ui.segment .corner.label").off('click')
      $(target).find(".ui.segment .corner.label").on('click', function () {
        $(target).hide()
        $(".link.modal .search.asset-requests").show()
        $(".link.modal .search.asset-requests").find("input").trigger("focus")

        $(".link-request.button").addClass("disabled")
      })
      $(target).find('.with-tooltip').popup({
        inline: true,
        exclusive: true,
        delay: {
          show: 500,
          hide: 0
        }
      })
    })
  },
};

asset_requests.fileControls = {
  request_id: null
}

asset_requests.fileControls.handleTransferEvents = function (event, obj) {
  function update_progress(transfer) {
    for (const [index, file] of transfer.files.entries()) {
      let progress = $(`.file.list .item[data-index='${index}'] .ui.progress`)
      let percent = (file.bytes_written / file.bytes_expected) * 100
      progress.progress('set percent', percent)
      progress.progress('set label', `${prettyBytes(transfer.calculated_rate_kbps * 1000, {bits: true})}s`)
    }
  }

  switch (event) {
  case 'transfer':
    if (obj.transfers.length > 0) {
      let transfer = obj.transfers.find(transfer => transfer.aspera_connect_settings.request_id === asset_requests.fileControls.request_id)
      if (transfer) {
        if (asset_requests.fileControls.iteration_token === obj.iteration_token) {
          update_progress(transfer)
          return
        } else {
          asset_requests.fileControls.iteration_token = obj.iteration_token
        }
        if ('error_desc' in transfer) {
          asset_requests.fileControls.showError(transfer.error_desc)
          return
        } 

        if (transfer.status == "running" && transfer.previous_status == "initiating") {
          $('.file.list .progress').removeClass('sliding indeterminate')
          return
        }
        if (transfer.status == "cancelled") {
          $('.file.list .progress').removeClass('sliding indeterminate')
          $('.file.list .progress').progress('set warning', 'Cancelled', true)
          return
        }

        if (transfer.status == "completed") {
          $('.file.list .progress').removeClass('sliding indeterminate')
          $('.file.list .progress').progress('set success', "Upload finished", true)
          return
        }
      }
    }
    break;
  }
}

asset_requests.fileControls.showError = function(message, title = 'Transfer Error!') {
  $('body').toast({
    title: title,
    class: 'error',
    displayTime: 0,
    message: message
  })
  $('.file.list .progress').removeClass('sliding indeterminate')
  $('.file.list .progress').progress('set error', title, true)
}

asset_requests.fileControls.showProgress = function(index) {
  let item = $(`.file.list .item[data-index='${index}']`)
  item.find('.remove.button').remove()
  item.find('.right.floated.content').html(`<div class="transfer-progress">
    <div class="ui blue sliding indeterminate progress">
      <div class="bar">
        <div class="progress"></div>
      </div>
      <div class="description label"></div>
    </div>
  </div>`)
  item.find('.progress').progress()
}

asset_requests.fileControls.uploadFiles = function (pathArray) {
  const asset_request_id = $('#asset_requests.show').data('id')
  let files = pathArray.dataTransfer.files;
  let source_paths = []
  
  for (const [index, file] of files.entries()) {
    source_paths.push(file.name)
    asset_requests.fileControls.showProgress(index)
  }

  const data = { source_paths: source_paths }
  const connectSettings = {
    allow_dialogs: "false"
  }

  $.ajax({
    url: `/asset_requests/${asset_request_id}/transfer_spec_for_asset_request_upload`,
    type: "POST",
    dataType: "json",
    data: data
  }).done(function(transferSpec) {
    // eslint-disable-next-line no-undef
    asset_requests.fileControls.request_id = asset_requests.connect_client.startTransfer(transferSpec, connectSettings, callbacks = {
      error: function(obj) {
        asset_requests.fileControls.showError(obj.error.user_message)
      },
      success: function(_obj) {
      }
    }).request_id
  })
}

window.asset_requests = asset_requests;
