import Timecode from './timecode.js'
import { FetchRequest } from '@rails/request.js'

Array.prototype.unique = function() {
  let key;
  let asc, end;
  const output = {};
  for (key = 0, end = this.length, asc = 0 <= end; asc ? key < end : key > end; asc ? key++ : key--) { output[this[key]] = this[key]; }
  return (() => {
    const result = [];
    for (key in output) {
      const value = output[key];
      result.push(value)
    }
    return result;
  })()
};

document.addEventListener("turbo:load", function() {
  let keydown_handler = function(event) {
    if (event.ctrlKey || event.metaKey) {
      switch (String.fromCharCode(event.which).toLowerCase()) {
      case 's':
        event.preventDefault()
        if ($(".mini.primary.button.disabled.submit").length == 0) {
          $(".mini.primary.button.submit").trigger("click")
        }
        break;
      }
    }
    if (event.key === 'Enter') {
      if ($("#search").is(":focus")) {
        return true
      } else if ($('textarea').is(":focus")) {
        return true
      } else if ($('#asset_a_Notes').is(":focus")) {
        return true
      } else if ($('#asset_a_AdditionDescription').is(":focus")) {
        return true
      } else if ($('#asset_a_DetailedDescription').is(":focus")) {
        return true
      } else if ($("#user_session").length > 0) {
        return true
      } else if ($('#asset_request_arq_ExternalNotes').is(":focus")) {
        return true
      } else if ($('#mam_version_release_notes').is(":focus")) {
        return true
      } else if ($('.medium-editor-element').is(":focus")) {
        return true
      } else if ($('#timecode').is(":focus")) {
        return true
      } else {
        event.preventDefault()
        return false
      }
    }
  }
  general.common.assets = function() {
    $(document).off('keydown.assets', keydown_handler)
    $(document).on('keydown.assets', keydown_handler)
  
    if ($(".asset.rename.dialog").length > 0) {
      assets.rename()
    }
  }
  if ($("#assets").length > 0) {
    assets.init()
  }
})

var vidchecker_event_request = null

var assets = {
  init() {
    assets.index()
    assets.edit()
    assets.show()
  },

  index() {
    general.init_common()
    if ($("#assets.index").length > 0) {

      $.ajax({
        url: '/assets/search_options',
      }).done(function(data) {
        $(".search.bar.filter>.segment").html(data)

        search.init("/assets").then(function(value) {
          let search_promises = []
          search_promises.push(assets.init_tags())

          var deferred_asset_spec_type = new $.Deferred()
          search_promises.push(deferred_asset_spec_type)

          let asset_spec_type_dropdown = $('#search_asset_spec_type').dropdown({
            apiSettings: {
              cache: false,
              url: '/asset_spec_types?q={/query}&search_input={/query}',
              data: {
                search_asset_types: $("#search_asset_type").val(),
                search_distribution_channel_types: $("#distribution_channel_type").parent().dropdown('get value')
              },
              beforeSend(settings) {
                settings.data.search_asset_types = $("#asset_a_aty_ID").val()
                settings.data.search_distribution_channel_types = $("#distribution_channel_type").parent().dropdown('get value')
                return settings;
              },
              beforeXHR(_xhr) {
                // xhr.fail(() => deferred_asset_spec_type.resolve())
              },
              onChange(value, _text, _$selectedItem) {
                return $('#asset_asset_spec_type_ids').val(value)
              }
              
            }
          })

          if (typeof asset_spec_type_dropdown.dropdown('setting', 'apiSettings')?.url === 'undefined') {
            deferred_asset_spec_type.resolve() // initialization failed
          }

          asset_spec_type_dropdown.dropdown('queryRemote', '', function() { 
            $('#search_asset_spec_type').dropdown('restore defaults')
            
            var selected_asset_spect_types = $(".filter.settings").data("settings") && $(".filter.settings").data("settings")['search_asset_spec_type']
            if (typeof(selected_asset_spect_types) != 'undefined') {
              $('#search_asset_spec_type').dropdown('set exactly', selected_asset_spect_types)
            }
            deferred_asset_spec_type.resolve()
          })
  
          $('#search_arrival_from').dropdown({
            apiSettings: {
              cache: false,
              url: '/contacts/suppliers?q={/query}'
            },
            templates: {
              menu: dropdownTemplates.search_menu
            }
          })
  
          calendar.datepicker(".arrival_date_start", { position: 'top left', lastResort: 'top left' } )
          calendar.datepicker(".arrival_date_end", { position: 'top right', lastResort: 'top right' } )
  
          calendar.datepicker(".modified_date_start", { position: 'top left', lastResort: 'top left' } )
          calendar.datepicker(".modified_date_end", { position: 'top right', lastResort: 'top right' } )
  
          calendar.datepicker(".creation_date_start", { position: 'top left', lastResort: 'top left' } )
          calendar.datepicker(".creation_date_end", { position: 'top right', lastResort: 'top right' } )
  
          assets.set_language_search(".search.bar")
  
          assets.workflow_job_template_search()



          Promise.allSettled(search_promises).then(() => search.perform_search(value.path, true)) 
        })
      })

      assets.init_index_body()
    }
  },

  edit() {
    general.init_common()
    assets.catalog_autocompletion('#assets')
    assets.remove_card('#assets')
    general.fetch_view($('#assets').find('.remote.catalog_base_object.card'))
    if (($("#assets.edit").length > 0) || ($("#assets.new").length > 0)) {
      $('#assets .with-tooltip').popup({
        inline: true,
        exclusive: true,
        delay: {
          show: 2000,
          hide: 0
        }
      })

      let deferred_queue = forms.deferred_queues['init_asset_edit']
      deferred_queue.onEnqueue(function(_result_promises) {
        forms.disable('init_asset_edit')
        $('button[type=submit],input[type=submit]', $('.ui.form')).addClass('loading')
      })
      deferred_queue.onEmpty(function(_result_promises) {
        forms.enable('init_asset_edit')
        $('button[type=submit],input[type=submit]', $('.ui.form')).removeClass('loading')
      })

      let tab_menu = $(".segment.main.custom.with-tabs")
      general.tab_with_url(tab_menu)
      tab_menu.find(".menu").menu({overflowMethod: 'dropdown',});

      let init_q = deferred_queue.enqueue()
      assets.load_tabs()

      assets.init_additional()

      assets.init_vidchecker_xml_menu_button()

      assets.on_change_defaults()
      assets.get_formats()

      assets.adjust_form()
      assets.adjust_season()

      assets.init_modals()

      assets.check_not_for_production_label()

      if ($("#asset_a_ID").val() != "" && $("#asset_a_ID").val() != undefined) {
        assets.load_action_bar()
        assets.set_current_storage_location()
      }

      $('#assets .ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })

      $(".remote.dropdown.qc.request.creator").dropdown({
        minCharacters: 1,
        delay: {search: 300},
        apiSettings: {
          cache: false,
          url: '/user_profile_webs.json?q={/query}&search_input={/query}'
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
      })

      assets.set_language_search("#assets")

      $('#asset_a_c_ID').dropdown({
        apiSettings: {
          cache: false,
          url: '/contacts/clients?q={/query}',
          data: {
            active: $("#asset_a_ID").length > 0
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        },
        onChange(value, _text, _$selectedItem) {
          $('#asset_a_c_ID').val(value)
          $("#title_selection").dropdown('restore defaults')
          $("#title_selection").dropdown('clear')
          $("#asset_a_c_ContentPartnerID").dropdown('restore defaults')
          //$("#asset_a_AdditionalTitle").val('')
          $("#asset_a_Title").val('')
          assets.set_archives()
          $("#mediaportal-filter").trigger('change')
        }
      }).dropdown('set selected', $('#asset_a_c_ID').val())

      $('#asset_a_c_ContentPartnerID').dropdown({
        apiSettings: {
          cache: false,
          url: '/contacts/contentpartners?q={/query}',
          data: {
            c_ID: $("#asset_a_c_ID").val()
          },
          beforeSend(settings) {
            settings.data.c_ID = $("#asset_a_c_ID").val()
            return settings;
          },
          onChange(value, _text, _$selectedItem) {
            return $('#asset_a_c_ContentPartnerID').val(value)
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
      }).dropdown('set selected', $('#asset_a_c_ContentPartnerID').val())

      $('#asset_a_co_ID').dropdown({
        apiSettings: {
          cache: false,
          url: '/assets/codecs?q={/query}',
          data: {
            asset_type: $("#asset_a_aty_ID").val()
          },
          beforeSend(settings) {
            settings.data.asset_type = $("#asset_a_aty_ID").val()
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
      }).dropdown('set selected', $('#asset_a_co_ID').val())

      assets.init_title_season_dropdown(deferred_queue)

      $('#title_selection').dropdown({
        allowReselection: true,
        apiSettings: {
          cache: false,
          url: '/titles/dropdown_search?q={/query}',
          data: {
            contact_id: $("#asset_a_c_ID").val()
          },
          beforeSend(settings) {
            settings.data.contact_id = $("#asset_a_c_ID").val()
            return settings
          },
          onRequest: () => deferred_queue.enqueue(), // beforeSend can be called multiple times without actually sending a request
          onComplete: (_response, _element, _xhr) => deferred_queue.dequeue()
        },
        onChange(_value, text, _$selectedItem) {
          $("#asset_title_asset_attributes_ta_ts_ID").dropdown('change values',null) // trigger remote on next dropdown usage
          const title = $("#asset_a_Title").val()
          if (typeof title === 'undefined' || title === '') {
            $("#asset_a_Title").val(text)
          }
          $("#asset_a_Title").trigger('change')
          $("#asset_a_Title").trigger('blur')
          assets.call_season_ajax()
          assets.ec_check()
          assets.init_sync_group()
        },
        templates: {
          menu: dropdownTemplates.title_search_menu
        }
      }).dropdown('set selected', $('#title_selection').val())

      $('#asset_catalog_base_object_ids').dropdown({
        apiSettings: {
          cache: false,
          url: '/catalog_base_objects?q={/query}',
          data: {
            contact_id: $("#asset_a_c_ID").val()
          },
          beforeSend(settings) {
            settings.data.contact_id = $("#asset_a_c_ID").val()
            return settings
          }
        },
        onChange(_value, _text, _$selectedItem) {
          
        },
        templates: {
          menu: dropdownTemplates.title_search_menu
        }
      })

      $('.asset.attachment.item').on('click', () => assets.asset_attachment_modal())

      $("#assets .sync.title").on('click', function() {
        $.ajax({
          url: "/titles/" + $('#title_selection').val() + ".json",
          type: "GET",
          dataType: "json",
          success(data, _textStatus, _xhr) {
            $("#asset_a_Title").val(data["t_GermanTitle"])
            $("#asset_a_Title").trigger("change")
            $("#asset_a_Title").trigger('focusout')
          },
          error(_jqXHR, _textStatus, errorThrown) {
            return console.log(errorThrown)
          }
        })
      })

      $('.title.field .input').hover((function() {
          $(this).find(".sync.title").show()
      }), function() {
        $(this).find(".sync.title").hide()
      })

      $('#asset_a_ArrivalFrom').dropdown({
        apiSettings: {
          cache: false,
          url: '/contacts/suppliers?q={/query}',
          data: {
            supplier_id: $("#asset_supplier_id").val()
          },
          beforeSend(settings) {
            settings.data.supplier_id = $("#asset_supplier_id").val()
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
        // onChange: (value, text, $selectedItem) ->
        //   if value == "3342"
        //     $("#asset_a_ArrivalChannel").dropdown('set selected', "Internal Creation")
      }).dropdown('set selected', $('#asset_a_ArrivalFrom').val())

      $('.supplier.field').hover((function() {
        if (!($('.supplier.field label a').length > 0)) {
          $('.supplier.field label').append("<a class='add contact'>&nbsp;<i class='far fa-plus-circle'></i></a>")
          $('.supplier.field label .add.contact').on('click', () => contacts.create_button())
        }
      }), function() {
        $('.supplier.field label a').remove()
      })

      $('.title.selection.field').hover((function() {
        if (!($('.title.selection.field label a').length > 0)) {
          $('.title.selection.field label').append("<a target='_blank' href='/titles/" + $("#title_selection").val() + "'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>")
        }
      }), function() {
        $('.title.selection.field label a').remove()
      })

      $('.contact.field').hover((function() {
        if (!($('.client.field label a').length > 0)) {
          $('.contact.field label').append("<a target='_blank' href='/contacts/" + $(".contact.dropdown").dropdown("get value") + "'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>")
        }
      }), function() {
        $('.contact.field label a').remove()
      })

      $('.content_partner.field').hover((function() {
        if (!($('.content_partner.field label a').length > 0)) {
          $('.content_partner.field label').append("<a target='_blank' href='/contacts/" + $(".content_partner.dropdown").dropdown("get value") + "'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>")
        }
      }), function() {
        $('.content_partner.field label a').remove()
      })

      if (($('.ui.accordion.videoplayer').length > 0)  && ($('#player-frame').length > 0)) {
        assets.init_video_accordion()
      }

      $('.mediainfo.details.button').on('click', function() {
        if ($('.mediainfo.details.data').hasClass("open")) {
          $('.mediainfo.details.data').removeClass("open animate__animated animate__fadeIn fastest")
        } else {
          $('.mediainfo.details.data').addClass("open animate__animated animate__fadeIn fastest")
          assets.get_mediainfo_data()
        }
      })

      if ((assets.getUrlParameter("import_asset_id") !== undefined) || (assets.getUrlParameter("import_asset_request_id") !== undefined) || (assets.getUrlParameter("import_order_id") !== undefined)) {
        assets.adjust_season()
        assets.set_archives()
        assets.ec_check()
        assets.diff_check(assets.getUrlParameter("import_asset_id"))
      }

      $(".tc.formatter").on("change", function() {
        assets.clean_framerate($(this))
      })

      if ($('#assets').hasClass('new')) {
        $('.two.mediainfo.fields').change(function() {
          assets.mark_required_mediainfo_label($(this))
        })
      }

      $('.filename.accordion').accordion({
        animateChildren: false,
        onChange() {
          const active = $('.ui.filename.accordion').find(".title").hasClass("active")
          users.settings("accordion.filenames.assets", active)
        }
      })

      if ($("#asset_job_id").val() !== "") {
        $(".filename-changer").change(() => assets.adjust_file_name())
        if ($("#asset_a_ID").val() === "") {
          assets.set_archives()
        }
        const elements = document.getElementsByClassName("not-checked-warning")
        for (let element of Array.from(elements)) {
          if ($(element).find("select").val() === "191") {
            $(element).parent().addClass("warning")
            $(element).dropdown('set text', "not specified (not checked)")
          }
        }
      }

      $("#asset_a_ArrivalDate").dblclick(() =>
        $.ajax({
          url: "/current_date",
          type: "GET",
          dataType: "json",
          success(data, _textStatus, _xhr) {
            if (data !== undefined) {
              $("#asset_a_ArrivalDate").val(data["current_date"])
              $(".arrival.date.field .calendar.datetimepicker").calendar("set date", data["current_date"])
            }
          },
          error(_jqXHR, _textStatus, errorThrown) {
            return console.log(errorThrown)
          }
        })
      )

      calendar.datetimepicker($("#assets"))

      $("#asset_a_IsNotForProduction").on('change', function() {
        assets.check_not_for_production_label()
        assets.flag_not_for_production_modal($("#asset_a_IsNotForProduction").is(":checked"))
      })

      $("#asset_a_IsExplicitContent").on('change', function() {
        assets.set_archives()
        if ($("#asset_a_IsExplicitContent").is(":checked")) {
          $('.jamesblue.bg').addClass('red-bg')
        } else {
          $('.jamesblue.bg').removeClass('red-bg')
        }
      })

      $("#asset_a_IsExplicitContent").trigger('change')

      $("#clear-cookies").on('click', function() {
        $("#asset_a_c_ID").val(0)
        $("#asset_a_c_ID").dropdown("clear")
        //$("#title_selection").val(0)
        $("#asset_a_c_ContentPartnerID").val(0)
        $("#asset_a_c_ContentPartnerID").dropdown("clear")
        //$("#asset_a_Title").val("")
        $("#asset_a_AdditionalTitle").val("")
        $("#asset_a_aty_ID").val(0)
        $("#asset_a_aty_ID").dropdown("clear")
        $("#asset_a_ContentType").val(0)
        $("#asset_a_ContentType").dropdown("clear")
        $("#asset_a_lc_ID").val(0)
        $("#asset_a_lc_ID").dropdown("clear")
        $("#asset_a_HasLtc").attr('checked', false)
        $("#asset_a_iar_ID").val(0)
        $("#asset_a_iar_ID").dropdown("clear")
        $("#asset_a_ArrivalChannel").val(0)
        $("#asset_a_ArrivalChannel").dropdown("clear")
        $("#asset_a_ArrivalFrom").val(0)
        $("#asset_a_ArrivalFrom").dropdown("clear")
        assets.clear_cookies()
      })

      $(document).on('submit', function(_event) {
        $(".submit").addClass("disabled loading")
        if ($("#asset_a_aty_ID").dropdown().val() !== "1" && $("#asset_a_afo_ID").val() != 125) {
          $("#asset_a_ScanningOrder").val(null)
          $("#asset_a_ScanningFormat").val(null)
          $("#asset_a_Color").val(null)
          $("#asset_a_far_ID").val(null)
          $("#asset_a_iar_ID").val(null)
        }
        if ($("#asset_a_aty_ID").dropdown().val() !== "2") {
          let num = 0
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if ($(`#asset_asset_filenames_attributes_${num}_afn_Attribute`).length > 0) {
              $(`#asset_asset_filenames_attributes_${num}_afn_Attribute`).val(null)
              num++
            } else {
              break
            }
          }
        }
        if ($("#asset_a_ScanningFormat").val() !== "i") {
          return $("#asset_a_ScanningOrder").val(null)
        }
      })

      $('#assets .catalog_base_objects .segment a.remove').on('click', function(event) {
        $('#assets #asset_catalog_base_object_ids').val(null)
        $(this).closest('.segment').remove()

        $('#assets .catalog_base_objects .search').show()
        event.preventDefault()
        event.stopPropagation()
      })

      assets.adjust_file_name()
      init_q.resolve()
    }
    assets.check_validation_status()
    assets.init_validations()

    assets.load_comments()

    $('#assets.edit .filenames.list .item .cog.icon').on('click', function() {
      assets.settings_modal($(this).data('context'))
    })

    $('#assets.edit .filenames.list .item').on("mouseenter", function() {
      $(this).find('.cog.icon').show()
    }).on("mouseleave", function() {
      $(this).find('.cog.icon').hide()
    })
  },

  show() {
    general.init_common()
    if ($("#assets.show").length > 0) {
      $('#assets.show .menu .item').tab()

      // init remote tabs
      $("#assets.show").find('.item[data-tab="ocr"]').on('click', function() {
        if ($('.ui.tab[data-tab="ocr"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_ocr_events`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="ocr"]').html(data).promise().then(
              general.table_sort(".ocr.table", "TC In")
            )
          }).fail(function() {
            console.log("failure to load ocr events")
          })
        }
      })
      $("#assets.show").find('.item[data-tab="chapter"]').on('click', function() {
        if ($('.ui.tab[data-tab="chapter"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_chapter_events`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="chapter"]').html(data).promise().then(
              general.table_sort(".chapter.table", "TC In")
            )
          }).fail(function() {
            console.log("failure to load chapter events")
          })
        }
      })
      $('.item[data-tab="events_chapter"]').on('click', function() { $("#assets.show").find('.item[data-tab="chapter"]').trigger('click') })
      
      assets.load_comments()

      $('.item[data-tab="sync_group"]').on('click', function() {
        if ($('.ui.tab[data-tab="sync_group"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/all_sync_group_assets`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="sync_group"]').html(data).promise().then(
              Array.from($('.ui.tab[data-tab="sync_group"]').find('.remote.card')).map((card) => general.fetch_view(card))
            )
          }).fail(function() {
            console.log("failure to load sync groups")
          })
        }
      })

      assets.init_quality_control()
      assets.init_storyboard()

      $('.item[data-tab="titles_projects"]').on('click', function() {
        if ($('.ui.tab[data-tab="titles_projects"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_titles_projects`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="titles_projects"]').html(data).promise().then(
              Array.from($('.ui.tab[data-tab="titles_projects"]').find('.remote.card')).map((card) => general.fetch_view(card)),
              general.table_sort(".projects.table")
            )  
          }).fail(function() {
            console.log("failure to load projects")
          })
        }
      })

      $('.item[data-tab="metadata"]').on('click', function() {
        if ($('.ui.tab[data-tab="metadata"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_metadata`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="metadata"]').html(data)   
          }).fail(function() {
            console.log("failure to load metadata")
          })
        }
      })

      $('.item[data-tab="add_info"]').on('click', function() {
        if ($('.ui.tab[data-tab="add_info"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_additional_information`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="add_info"]').html(data)
          }).fail(function() {
            console.log("failure to load additional info")
          })
        }
      })

      $('.item[data-tab="audio"]').on('click', function() {
        if ($('.ui.tab[data-tab="audio"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_audio`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="audio"]').html(data).promise().then(
              general.table_sort(".audio.table", "Number")
            )
          }).fail(function() {
            console.log("failure to load audio info")
          })
        }
      })

      $('.item[data-tab="content"]').on('click', function() {
        if ($('.ui.tab[data-tab="content"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_content`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="content"]').html(data).promise().then(
              general.table_sort(".content.table", "TC In")
            )
          }).fail(function() {
            console.log("failure to load content info")
          })
        }
      })
      $('.item[data-tab="content"]').trigger('click')
    }
  },

  index_menu() {
    $(".actions .set.asset.value").on('click', function() {
      assets.init_set_value_modal()
    })

    $(".send.all.to.workflow").on('click', async function() {
      const asset_id_params = index_select.selected_ids()
      await general.post_redirect('/workflows/compose', { "asset_ids[]": asset_id_params })
    })

    $(".actions .request-backup-tapes").on('click', function() {
      assets.request_backup_tapes_modal()
    })
  },

  load_comments() {
    assets.comments($("#asset_a_ID").val())
    if ($('.ui.tab[data-tab="comments"]').find('.loading.segment').length != 0) {
      $.ajax({
        url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_asset_comments`,
        type: "GET",
      }).done(function(data) {
        $('.ui.tab[data-tab="comments"]').html(data)
      }).fail(function() {
        console.log("failure to load comments")
      })
    }
  },

  init_title_season_dropdown(deferred_queue = null) {
    $('#asset_title_asset_attributes_ta_ts_ID').dropdown({
      saveRemoteData: false,
      allowReselection: true,
      apiSettings: {
        url: `/titles/{id}/title_seasons`,
        method : 'GET',
        urlData: {
          id: $('#title_selection').val()
        },
        cache: false,
        beforeSend(settings) {
          settings.urlData = {
            id: $('#title_selection').val()
          }
          return settings
        },
        onRequest: () => deferred_queue?.enqueue(), // beforeSend can be called multiple times without actually sending a request
        onComplete: (_response, _element, _xhr) => deferred_queue?.dequeue()
      }
    }).dropdown('set selected', $('#asset_title_asset_attributes_ta_ts_ID').val())
  },

  set_language_search(scope, default_filter) {
    if (default_filter == null) { default_filter = "default"; }
    $.each($(scope).find('.language-search'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/languagecode?q={/query}',
          data: {
            default_filter
          }
        },
        templates: {
          menu: dropdownTemplates.language_search_menu
        },
        onChange(value, _text, _$choice) {
          if ($(item).parent().hasClass("not-checked-warning")) {
            if (value === "191") {
              $(item).parent().parent().addClass("warning")
            } else {
              $(item).parent().parent().removeClass("warning")
            }
          }
          if ($(this).closest('.audio-language.dropdown').length > 0) {
            let secondary_dropdown = $(this).closest('.fields').find('.secondary-audio-language.dropdown')
            if (value == "") {
              secondary_dropdown.dropdown('clear')
              secondary_dropdown.addClass('disabled')
            } else {
              secondary_dropdown.removeClass('disabled')
            }
          }
        }
      })
    })
  },

  set_client_search(scope, default_filter) {
    if (default_filter == null) { default_filter = "default"; }
    $.each($(scope).find('.client-search'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/contacts/clients?q={/query}',
          // data: {}
        },
        templates: {
          menu: dropdownTemplates.search_menu
        },
          beforeSend: function (settings) {
            return settings
          },
      })
    })
  },

  adjust_form_body() {
    assets.load_tabs(["events"])
  },

  load_action_bar() {
    $.ajax({
      url: `/assets/${$("#asset_a_ID").val()}/action_bar`,
    }).done(function(data) {
      $('#assets.edit .action-bar-content').html(data)
      context_menu.init()
      popup.init()
      assets.init_modals()
      // assets.init_glim()
      assets.subtitle_check()
      assets.load_tags_labels($('#assets .remote.tag'))
    }).fail(function(_error){
    })
  },

  load_tab(namespace) {
    return $.ajax({
      url: `/assets/${$("#asset_a_ID").val()}/tabs`,
      data: {
        tab: namespace,
        source_controller: $('.navigation[data-controller-name][data-action-name]').data('controller-name'),
        source_action: $('.navigation[data-controller-name][data-action-name]').data('action-name'),
      },
    })
  },

  load_tabs(asset_tabs = ["editorial", "content", "association", "audio_track", "events", "project", "access", "quality_control", "movement_log", "data_content"]) {
    if ($("#asset_a_ID").val() != "") {
      $.each(asset_tabs, function( _index, tab ) {
        assets.load_tab(tab).done(function(data) {
          $(`.ui.bottom.attached.tab[data-tab="${tab}"]`).html($(data).find(".tab_content").children())
          var fn = getFunctionByName("window.assets.init_" + tab)
          if (typeof fn === "function") fn()
        })
      })
    } else {
      $.each(asset_tabs, function( _index, tab ) {
        var fn = getFunctionByName("window.assets.init_" + tab)
        if (typeof fn === "function") fn()
      })
    }
  },

  init_additional() {
    $('.ui.bottom.attached.tab[data-tab="additional"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })

    if ($("#asset_a_ID").val() != "" && $("#asset_a_ID").val() != undefined) {
      $.ajax({
        url: `/assets/${$("#asset_a_ID").val()}/pse_standard`,
        type: "GET",
        dataType: "json"
      }).done(function(data) {
        $('.asset.pse_standard').val(`${data.pse_standard}`)
      }).fail(function(error){
        console.log(`failed to load pse norm remotely ${error}`)
      })
    }

    if ($("#asset_a_ID").val() != "" && $("#asset_a_ID").val() != undefined) {
      $.ajax({
        url: `/assets/${$("#asset_a_ID").val()}/calculated_aspect_ratio`,
        type: "GET",
        dataType: "json"
      }).done(function(data, _textStatus, _xhr) {
        if (data) {
          $('[for="calculated_aspect_ratio"]').text(`${data.text}`)
          $('#calculated_aspect_ratio').text(`${data.value}`)
          $('.calculated_aspect_ratio.fields').show()
        }
      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        console.log(`failed to load calculated aspect ratio ${errorThrown}`)
      })
    }

    $('#asset_asset_spec_type_ids').dropdown({
      apiSettings: {
        cache: false,
        url: '/asset_spec_types?q={/query}&search_input={/query}',
        data: {
          search_asset_types: $("#asset_a_aty_ID").val(),
          search_distribution_channel_types: $("#distribution_channel_type").parent().dropdown('get value')
        },
        beforeSend(settings) {
          settings.data.search_asset_types = $("#asset_a_aty_ID").val()
          settings.data.search_distribution_channel_types = $("#distribution_channel_type").parent().dropdown('get value')
          return settings;
        },
        onChange(value, _text, _$selectedItem) {
          return $('#asset_asset_spec_type_ids').val(value)
        }
      }
    }).dropdown('set selected', $('#asset_asset_spec_type_ids').val())

    assets.init_tags()

    search.asset_autocompletion($(".ui.search.3dmaster"), { asset_type: 1 })
    $("#3dmaster_asset_search").val($("#asset_a_a_3dDependenceMasterID").val())
    assets.dropdown_3d_field()

    $('.ui.accordion.ingestlog').accordion()
    $('.ui.accordion.additionaltrack').accordion()
    
    $('.hdr.menu .item').tab()
    $('.ui.accordion.hdr').accordion({
      animateChildren: false
    })

    $('.hdr_value').on("change", function() {
      const target = $(this).data('target')
      const factor = $(this).data('factor')
      const value = $(this).val()
      let result = value * factor;
      if (factor >= 1) {
        result = Math.round(result)
      } else {
        result = result.toPrecision(4)
      }

      return $(`#${target}`).val(result)
    })

    $('.hdr_color_preset').on("change", function() {
      const data = $(this).find(':selected').data()
      if (jQuery.isEmptyObject(data)) {
        $('.hdr_value').val(null)
      } else {
        $('#asset_a_MasteringDisplayGreenX').val(data.greenX)
        $('#asset_a_MasteringDisplayGreenY').val(data.greenY)
        $('#asset_a_MasteringDisplayBlueX').val(data.blueX)
        $('#asset_a_MasteringDisplayBlueY').val(data.blueY)
        $('#asset_a_MasteringDisplayRedX').val(data.redX)
        $('#asset_a_MasteringDisplayRedY').val(data.redY)
        $('#asset_a_MasteringDisplayWhitePointX').val(data.whiteX)
        $('#asset_a_MasteringDisplayWhitePointY').val(data.whiteY)
      }
      $('.master.hdr_value').trigger("change")
    })
    
    $('.master.hdr_value').trigger("change")

    $('#assets .crop-value').on("change", function() {
      assetEvent.check_crop_values()
    })
    assetEvent.check_crop_values()

    assets.fill_external_id()
  },

  init_editorial() {
    $('.ui.bottom.attached.tab[data-tab="editorial"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })
    assets.set_language_search("#assets")

    assets.language_buttons("#assets")

    $.each($('.rating-select'), function(_i, item) {
      const tc = $(item).parent().parent().parent().find('.rating-tc-select')
      return $(item).dropdown({
        apiSettings: {
          cache: false,
          url: "/assets/ratings?q={/query}",
          beforeSend(settings) {
            settings.url = `${settings.url}&r_tc_ID=${$(tc).val()}`;
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
      }).dropdown('set selected', $(item))
    })

    $.each($('.rating-tc-select'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/assets/territories?q={/query}'
        },
        templates: {
          menu: dropdownTemplates.territory_search_menu
        }
      }).dropdown('set selected',  $(item).dropdown('get value'))
      return $(item).change(function() {
        const rs = $(item).parent().parent().parent().find(".rating-select")
        $(rs).dropdown('clear')
        if ($(item).dropdown("get text") === "") {
          return $(rs).hide()
        } else {
          return $(rs).show()
        }
      })
    })

    $("#genre-filter").change(function() {
      $("#asset_genre_ids").empty()
      return $.ajax({
        url: "/assets/genre",
        type: "GET",
        dataType: "json",
        data: {
          checked: $(this).prop("checked")
        },
        success(data, _textStatus, _xhr) {
          const select = $("#asset_genre_ids")[0];
          for (let genre of Array.from(data)) {
            const option = document.createElement("option")
            option.value = genre.value;
            option.innerHTML = genre.text;
            select.appendChild(option)
          }
          return $("#asset_genre_ids").dropdown("refresh")
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown)
          return console.log("toggle genre filter error")
        }
      })
    })

    $("#genre").find(".text").addClass("default").text("Genres")
    $("#asset_asset_usage_type_ids").parent().find(".text").addClass("default").text("Usage")
  },

  init_content() {
    $('.ui.bottom.attached.tab[data-tab="content"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })
      
    assets.tc_helper()

    let sels = document.getElementsByClassName("content-tc-changer")
    let i = sels.length
    while (i--) {
      $(document).find(sels[i]).change(function() {
        if (!$(this).hasClass("content-is-active")) {
          assets.clean_framerate($(this))
        }
        assets.add_title_dblclick($(this).parents(':eq(3)').find(".content-content"))
        assets.add_tc_in_dblclick($(this).parents(':eq(3)').find(".content-tc-in"))
        assets.add_tc_out_dblclick($(this).parents(':eq(3)').find(".content-tc-out"))
        // close existing toast
        $('body').toast('get toasts').find('.invalid_tc.error').toast('close')          
        // remove error from fields
        $(this).parent().removeClass('error')
        $(this).parents(':eq(3)').find(".content-tc-in").parent().removeClass('error')
        $(this).parents(':eq(3)').find(".content-tc-dur").parent().removeClass('error')

        let framerate      = Timecode.check_framerate($("option:selected", $("#asset_a_fr_ID")).data('framerate'))
        let tc_format      = $("option:selected", $("#asset_a_TimecodeFormat")).val()

        let content_tc_in  = Timecode.from_string($(this).parents(':eq(3)').find(".content-tc-in").val(),  framerate, tc_format, false)
        let content_tc_out = Timecode.from_string($(this).parents(':eq(3)').find(".content-tc-out").val(), framerate, tc_format, false)
        let duration_tc    = content_tc_out.subtract(content_tc_in)
        var duration       = duration_tc.toString()
       
        if (typeof(duration) !== 'undefined' && duration_tc.totalframes < 0) {
          // mark errors
          $(this).parent().addClass('error')
          $(this).parents(':eq(3)').find(".content-tc-in").parent().addClass('error')
          $(this).parents(':eq(3)').find(".content-tc-dur").parent().addClass('error')

          // notice
          $("#asset_a_ContentNetDurationTimecode").parent().toggleClass('error')
          $('body').toast({
            title: `Time Paradox`,
            class: 'invalid_tc error',
            message: `duration tc is negative`,
            displayTime: 5000,
          })
        }
        
        $(this).parents(':eq(3)').find(".content-tc-dur").val(duration)
        assets.calc_content_net_dur()
      }).change()
    }

    assets.init_jump_to_tc_fields()
  },

  language_buttons(node) {
    $(node).find(".reset-language").on('click', function() {
      $(node).find(".reset-language-text").val("")
      $(node).find(".reset-language-dropdown").dropdown('set exactly', 191)
    })

    $(node).find(".no-language").on('click', function() {
      $(node).find(".reset-language-text").val("")
      $(node).find(".reset-language-dropdown").dropdown('set exactly', 140)
    })
  },

  init_association() {
    $('.ui.bottom.attached.tab[data-tab="association"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })
      
    assets.tc_helper()

    assets.nested_fields()
    assets.load_remote_cards()
    assets.filter_by_title_checkbox()

    assets.init_all_sync_groups()
    search.asset_autocompletion(".ui.search.source-assets")
    assets.init_sync_group()

    delivery_orders.load_status_labels($('.ui.bottom.attached.tab[data-tab="association"]').find('.status.label'))
    delivery_orders.load_cover('.delivery-order.list.item .quicklook.cover')
  },

  init_audio_track() {
    $('.ui.bottom.attached.tab[data-tab="audio_content"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })
    audio_tracks.init()
  },

  init_events() {
    $('.ui.bottom.attached.tab[data-tab="events"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })
    $('.ui.bottom.attached.tab[data-tab="events"] .menu .item').tab()
    assetEvent.init()
    assets.init_storyboard()

    if ($("#export-chapter-button").length > 0) {
      $('.ui.auto-chapter-modal.modal').modal({
        onVisible() {
          $(".auto-chapter-content").select()
        }
      }).modal('attach events', '#export-chapter-button', 'show')
    }

    $(".chapter.buttons .open").on('click', function() {
      $('.ui.modal.automatic-chapter').modal({
        onShow() {
          assetEvent.get_automatic_chapter()
        },
        actions: [
          {
            text    : 'Close',
            class   : 'left floated black cancel',
          },
          {
            text    : 'Update All TCs',
            class   : 'teal approve all tcs',
          },
          {
            text    : 'Update Thumb TCs',
            class   : 'blue approve thumb tcs',
          }
        ],

        onApprove(element) {
          let update_all = element.is('.all.tcs')
          Array.from(document.getElementsByClassName("chapter-row")).forEach( (chapter, i) => {
            let target = $(".asset-events.nested-fields")[i]
            if ($(target).attr('style') === "display: none;") {
              $(target).removeAttr('style')
            }
            const tc = $(chapter).data("tc")
            const thumb_tc = $(chapter).find(`input[name=chapter${i+1}]:checked`).val()
            const filename = $(chapter).find(`input[name=chapter${i+1}]:checked`).data("filename")

            if (target === undefined) {
              $(".add-event-button").trigger("click")
              target = $(".asset-events.nested-fields")[i]
            }

            let tc_in_field = $(target).find('.chapter-tc-in')
            if (update_all || tc_in_field.val() === "") {
              tc_in_field.val(tc).trigger('change')
            }
            
            $(target).find(".chapter-thumb-tc").val(thumb_tc).trigger('change')
            $(target).find(".chapter-filename").val(filename).trigger('change')
          })
        }
      }).modal("show")
    })

    var sels = document.getElementsByClassName("chapter-tc-changer")
    var i = sels.length;
    while (i--) {
      $(document).find(sels[i]).on('change', function() {
        assets.clean_framerate($(this))
        assetEvent.check_thumb_tcs()
      }).trigger('change')
    }

    assets.init_ocr_fields()
    assets.init_subtitle_fields()
    assets.init_vidchecker_events()
    assets.init_content_marker()
    assets.init_vi_fields()
    assets.init_audio_transcription_fields()
  },

  init_project() {
    $('.ui.bottom.attached.tab[data-tab="project"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })

    $('.ui.bottom.attached.tab[data-tab="project"]').find('.contact_filter.dropdown').dropdown({
      placeholder: false,
      action: "hide"
    })

    search.asset_project_autocomplete(".ui.search.asset-project")
    assets.projects()

    $(".project.territory").dropdown({
      apiSettings: {
        cache: false,
        url: '/assets/territories?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.project_territory_search_menu
      }
    })
  },

  init_access() {
    $('.ui.bottom.attached.tab[data-tab="access"]').find('.ui.dropdown').not('.remote').not('.audio-track').not('.actions').not(".custom").dropdown({ placeholder: false, fullTextSearch: false })

    $('#asset_media_portal_ids').dropdown({
      apiSettings: {
        cache: false,
        url: '/media_portals/filter_portals?search_input={/query}',
        data: {
          client_id: $('#asset_a_c_ID').val(),
          show_all_client_filter: $("#mediaportal-filter").prop('checked')
        },
        beforeSend(settings) {
          settings.data.client_id = $('#asset_a_c_ID').val()
          settings.data.show_all_client_filter = $("#mediaportal-filter").prop('checked')
          
          return settings;
        }
      }
    })
  },

  init_quality_control() {
    $(".add.qc-request.button").on("click", function() {
      assets.add_qc_request()
    })

    const init_quality_control_elements = function() {
      $('.qc.tabular.menu .item').tab()
      $('#filter_attachments').dropdown({
        onChange() {
          filter_attachments(Array.from($(this).dropdown('get values')))
        }
      })
      general.table_sort(".auto_quality_control.table", "Created", true)
      general.table_sort(".asset_history.table", "Created", true)
      $('.status_event.accordion').accordion()
    }
    const filter_attachments = function(values) {
      console.log(values.length)
      if (values.length > 0) {
        $('.attachments.list .item').hide()
        for (let value of values) {
          $(`.attachments.list .${value}.item`).show()
        }
      } else {
        $('.attachments.list .item').show()
      }
    }
    init_quality_control_elements()
    $('.item[data-tab="quality_control"]').on("click", function() {
      if ($('.ui.tab[data-tab="quality_control"]').find('.loading.segment').length != 0) {
        $.ajax({
          url: `/assets/${$("#asset_a_ID").data('asset-id')}/show_quality_control`,
          type: "GET",
        }).done(function(data) {
          $('.ui.tab[data-tab="quality_control"]').html(data).promise().then(
            init_quality_control_elements()
          )
        }).fail(function() {
          console.log("failure to load quality control")
        })
      } else {
        init_quality_control_elements()
      }
    })
  },
  init_movement_log() {

  },

  fill_external_id() {
    let namespace_field = $(".reference.field")
    namespace_field
    .on("mouseenter", function() {
      if (!($(this).find('div.label').length > 0)) {
        if ($(this).find("input").val() == "") {
          $(this).find("label").append(`<div class="ui mini basic label" style="margin-left: 10px; position: relative; top: -4px;"><i class="fa-duotone fa-arrows-rotate"></i> &nbsp;Fill External ID from Title</div>`)

          let input = $(this).find("input")
          $(this).find('div.label').off("click")
          $(this).find('div.label').on("click", function() {
            $.ajax({
              url: "/titles/" + $("#title_selection").val() + "/reference_id",
              type: "GET",
              dataType: "json",
              data: {
                c_id: $("#asset_a_c_ID").val(),
              }
            }).done(function(data) {
              if (data.reference_id != "" && data.reference_id != null) {
                input.val(data.reference_id).transition('glow')
              }
            }).fail(() => console.log("error"));
          })
        }
      }
    })
    .on("mouseleave", function() {
      $(this).find('div.label').remove()
    })
  },

  add_qc_request() {
    console.log("add_qc_request")
  },

  adjust_arrival_from(arrival_channel) {
    if ((arrival_channel.options[arrival_channel.selectedIndex].text === "Internal Creation") || (arrival_channel.options[arrival_channel.selectedIndex].text === "Internal Ingest from Asset ID")) {
      return assets.add_supplier(3342, "Imagion AG")
    }
  },

  adjust_form() {
    if ($("#asset_a_aty_ID").val() === "2") {
      assets.switch_to_audio()
    } else if ($("#asset_a_aty_ID").val() === "11") {
      assets.switch_to_subtitle()
    } else if ($("#asset_a_aty_ID").val() === "5") {
      assets.switch_to_premaster_backup()
    } else if ($("#asset_a_aty_ID").val() === "8") {
      assets.switch_to_premaster_backup()
    } else if ($("#asset_a_aty_ID").val() === "18") {
      assets.switch_to_image()
    } else {
      assets.switch_to_video()
    }
    assets.adjust_scanformat()
    assets.get_formats()
  },

  init_rating_fields(element) {
    $(element).find('.ui.dropdown').not('.remote').dropdown({ placeholder: false, fullTextSearch: false })
    $(element).find(".rating-selection").hide()

    const newname = `rating-${general.makeid()}`;
    $(element).find(".rating-button").attr("data-target", `#${newname}`)
    $(element).find(".rating").attr("id", newname)

    $(element).find('.rating-tc-select').dropdown({
      apiSettings: {
        cache: false,
        url: '/assets/territories?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.territory_search_menu
      }
    }).dropdown('set selected', $(element).val())

    $(element).find('.rating-select').dropdown({
      apiSettings: {
        cache: false,
        url: "/assets/ratings?q={/query}",
        beforeSend(settings) {
          const tc = $(element).find(".rating-tc-select").dropdown('get value')
          settings.url = `${settings.url}&r_tc_ID=${tc}`;
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    }).dropdown('set selected', $(element).find(".rating-tc-select").val())

    $(element).find(".rating-tc-select").change(function() {
      $(element).find(".rating-select").dropdown('refresh')
      if ($(element).find(".rating-tc-select").dropdown("get text") === "") {
        return $(element).find(".rating-selection").hide()
      } else {
        return $(element).find(".rating-selection").show()
      }
    })

  },

  init_content_fields(element) {
    $(element).find('.ui.dropdown').dropdown({ placeholder: false, fullTextSearch: false })
    $(element).find(".content-tc-changer").change(function() {
      $(element).find(".content-is-active").attr('checked', true)
      if (!$(this).hasClass("content-is-active")) {
        assets.clean_framerate($(this))
      }
      assets.add_title_dblclick($(this).parents(':eq(3)').find(".content-content"))
      assets.add_tc_in_dblclick($(this).parents(':eq(3)').find(".content-tc-in"))
      assets.add_tc_out_dblclick($(this).parents(':eq(3)').find(".content-tc-out"))
      // close existing toast
      $('body').toast('get toasts').find('.invalid_tc.error').toast('close')
      // remove error from fields
      $(this).parent().removeClass('error')
      $(this).parents(':eq(3)').find(".content-tc-in").parent().removeClass('error')
      $(this).parents(':eq(3)').find(".content-tc-dur").parent().removeClass('error')

      let framerate = Timecode.check_framerate($("option:selected", $("#asset_a_fr_ID")).data('framerate'))
      let tc_format = $("option:selected", $("#asset_a_TimecodeFormat")).val()

      let content_tc_in  = Timecode.from_string($(this).parents(':eq(3)').find(".content-tc-in").val(),  framerate, tc_format, false)
      let content_tc_out = Timecode.from_string($(this).parents(':eq(3)').find(".content-tc-out").val(), framerate, tc_format, false)
      let duration_tc = content_tc_out.subtract(content_tc_in)

      let duration = duration_tc.toString()
      if (typeof(duration) !== 'undefined' && duration_tc.totalframes < 0) {
        // mark errors
        $(this).parent().addClass('error')
        $(this).parents(':eq(3)').find(".content-tc-in").parent().addClass('error')
        $(this).parents(':eq(3)').find(".content-tc-dur").parent().addClass('error')

        // notice
        $("#asset_a_ContentNetDurationTimecode").parent().toggleClass('error')
        $('body').toast({
          title: `Time Paradox`,
          class: 'invalid_tc error',
          message: `duration tc is negative`,
          displayTime: 5000,
        })
      }
      if (duration != null) { 
        $(this).parents(':eq(3)').find(".content-tc-dur").val(duration)
      }
      
      assets.calc_content_net_dur()
      assets.init_jump_to_tc_fields()
    }
    ).trigger('change')
  },

  init_event_fields(target) {
    $(".chapter-tc-changer").on('change', function() {
      assets.clean_framerate($(this))
      assetEvent.check_thumb_tcs()
    }).trigger('change')

    let element = $(target).find(".asset-events")
    assetEvent.init_player_control_buttons()
    let value
    if ($(element).hasClass('nested-fields')) {
      for (value of Array.from($('#event-languages').dropdown().dropdown('get values'))) {
        const button = $(element).find('.add-event-language-button')
        const default_value = $('#metadata_default_language').val()
        window.nested_params = { value, is_default: default_value === value, chapter_title: `${assetEvent.get_chapter_title_prefix(value)} ${$('.asset-events.nested-fields:visible').length}` }
        button.trigger('click')
      }
      assetEvent.selectLabel($('.ui.label.language-selected'))
      assetEvent.hide_and_show()
    }
  },

  init_event_metadata_fields(target) {
    let chapter_title, is_default, value
    let element = $(target).find(".asset-event-metadata")
    if ($(element).hasClass('asset-event-metadata')) {
      ({ value } = window.nested_params);
      ({ is_default } = window.nested_params);
      ({ chapter_title } = window.nested_params);

      $(element).find('.asset_event_metadata_language').val(value)
      $(element).find('.chapter-title').val(chapter_title)
      $(element).find('.asset_event_metadata_default_language').val(is_default)
      $(element).addClass(value)
      assetEvent.hide_and_show(value)
    }
  },

  init_audio_fields(element) {
    audio_tracks.init_remote($(element))
    assets.set_language_search(element)

    const target_id = general.makeid()
    const newname = `audio-settings-${target_id}`;
    const new_audio_track_name = `audio_track_${target_id}`;
    $(element).find(".audio-tracks").attr("id", new_audio_track_name)
    $(element).find(".audio-settings-button").attr("data-target", `#${newname}`)
    $(element).find(".audio-settings").attr("id", newname)
    audio_tracks.set_audio_numbers()
    
    if (window.insert_audio_track_batch !== undefined) {
      const track_groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      const index = track_groups.indexOf(window.insert_audio_track_batch['last_channel'])
      const audio_track_group_dropdown = $(element).find('.audio-trackgroup')
      audio_track_group_dropdown.dropdown("set selected", track_groups[index + 1])
      audio_track_group_dropdown.dropdown("set value", track_groups[index + 1])
      audio_track_group_dropdown.dropdown("set text", track_groups[index + 1])
      if (window.insert_audio_track_batch['no_audio'] === true) {
        $(element).find('.audio-track-channels').dropdown("set selected", "10")
        $(element).find('.audio-track-channels').dropdown("set value", "10")
        $(element).find('.audio-track-channels').dropdown("set text", "no Audio")
      }
    } else {
      const audio_track_count = $('.audio-tracks:visible').length
      if (audio_track_count > 2) {
        let last_value = $($('.audio-tracks:visible')[audio_track_count - 2]).find('.audio-trackgroup').dropdown("get value")
        $('.audio-tracks:visible').last().find('.audio-trackgroup').dropdown("set selected", last_value)
        $('.audio-tracks:visible').last().find('.audio-trackgroup').dropdown("set value", last_value)
        $('.audio-tracks:visible').last().find('.audio-trackgroup').dropdown("set text", last_value)
      }
    }

    audio_tracks.channel_selection($(element).find(".audio-channel-label"))
    audio_tracks.track_group_selection($(element).find(".audio-channel-label"))
  },

  adjust_scanformat() {
    if ($("#asset_a_ScanningFormat").val() === "i") {
      $(".scan-order").show()
      $("#video-format-fields").removeClass('three').addClass('four')
    } else {
      $(".scan-order").hide()
      if ($("#asset_a_aty_ID").val() === "1") {
        $("#video-format-fields").removeClass('four').addClass('three')
      } else {
        $("#video-format-fields").removeClass('four').addClass('two')
      }
    }
    if ($("#asset_a_ScanningFormatContainer").val() === "i") {
      return $(".scan-order-container").show()
    } else {
      return $(".scan-order-container").hide()
    }
  },

  adjust_audio_layout(layout) {
    let optionsarray, target;
    if (layout.id === "low_res_layout") {
      target = $("#low_res_options")
    } else if (layout.id === "high_res_layout") {
      target = $("#high_res_options")
    }
    target.empty()
    let seloption = [];
    if (layout.value === "Stereo") {
      optionsarray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"];
    } else if (layout.value === "Mono") {
      optionsarray = ["1-2", "3-4", "5-6", "7-8", "9-10", "11-12", "13-14", "15-16"];
    } else if (layout.value === "5.1") {
      optionsarray = ["1-6", "3-8", "5-10", "7-12", "9-14", "11-16"];
    }
    $.each(optionsarray, i => seloption += `<option value="${optionsarray[i]}">${optionsarray[i]}</option>`)
    return target.append(seloption)
  },

  ec_check() {
    return $.ajax({
      url: "/titles/" + $("#title_selection").val(),
      type: "GET",
      dataType: "json",
      success(data, _textStatus, _xhr) {
        if (data !== undefined) {
          if (data !== null) {
            if (data.t_IsExplicitContent === true) {
              $("#asset_a_IsExplicitContent").prop("checked", true)
              $("#asset_a_IsExplicitContent").change()
            } else {
              $("#asset_a_IsExplicitContent").prop("checked", false)
              $("#asset_a_IsExplicitContent").change()
            }
            return assets.adjust_file_name()
          }
        }
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  get_formats() {
    return $.ajax({
      url: "/assets/formats",
      type: "GET",
      dataType: "json",
      data: { // This goes to Controller in params hash, i.e. params[:file_name]
        asset_type: $("#asset_a_aty_ID").val(),
        digital: $('#asset_a_IsDigitalMediaAsset').val()
      },
      complete() {},

      success(data, _textStatus, _xhr) {
        const select = document.getElementById("asset_a_afo_ID")
        if (select !== null) {
          $("#asset_a_afo_ID").empty()
          let option = document.createElement("option")
          select.appendChild(option)
          for (let format of Array.from(data)) {
            option = document.createElement("option")
            option.value = format.afo_ID;
            option.innerHTML = format.afo_ContainerFormat;
            select.appendChild(option)
          }
          if ($(`#asset_a_afo_ID option[value='${$("#asset_a_afo_ID").data("selected")}']`).length !== 0) {
            $("#asset_a_afo_ID").val($("#asset_a_afo_ID").data("selected"))
          } else {
            $("#asset_a_afo_ID").val(null)
          }
          return $("#asset_a_afo_ID").change()
        }
      },

      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  switch_to_audio() {
    $(".video.fields, .video.field").hide()
    $(".subtitle.fields, .subtitle.field").hide()
    $(".premaster.fields, .premaster.field").hide()
    $('.editorial.language .field').show()
    return $(".audio.fields, .audio.field").show()
  },

  switch_to_subtitle() {
    $(".video.fields, .video.field").hide()
    $(".audio.fields, .audio.field").hide()
    $(".premaster.fields, .premaster.field").hide()
    $(".subtitle.fields, .subtitle.field").show()
    $('.editorial.language .field').not('.subtitle').hide()
    // set codec to tbc
    if ($('#asset_a_co_ID').val() === "") {
      return $('#asset_a_co_ID').val("47")
    }
  },

  switch_to_premaster_backup() {
    $(".video.fields, .video.field").hide()
    $(".audio.fields, .audio.field").hide()
    $(".subtitle.fields, .subtitle.field").hide()
    $(".premaster.fields, .premaster.field").show()
    $('.editorial.language .field').show()
    // set codec to tbc
    if ($('#asset_a_co_ID').val() === "") {
      return $('#asset_a_co_ID').val("47")
    }
  },

  switch_to_video() {
    $(".audio.fields, .audio.field").hide()
    $(".subtitle.fields, .subtitle.field").hide()
    $(".premaster.fields, .premaster.field").hide()
    $('.editorial.language .field').show()
    return $(".video.fields, .video.field").show()
  },

  switch_to_image() {
    $(".video.fields, .video.field").hide()
    $(".audio.fields, .audio.field").hide()
    $(".subtitle.fields, .subtitle.field").hide()
    $(".premaster.fields, .premaster.field").hide()
    $('.editorial.language .field').hide()

    $('.aspect_ratio.fields').show()
    $('.dimension.fields').show()
    
    // set codec to tbc
    if ($('#asset_a_co_ID').val() === "") {
      return $('#asset_a_co_ID').val("47")
    }
  },

  clear_cookies() {
    document.cookie = "a_c_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    //document.cookie = "a_Title=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
    //document.cookie = "title_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
    document.cookie = "a_c_ContentPartnerID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_AdditionalTitle=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_aty_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_ContentType=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_lc_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_HasLtc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_iar_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_ArrivalChannel=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "a_ArrivalFrom=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    return $("#clear-cookies").hide()
  },

  getUrlParameter(sParam) {
    const sPageURL = window.location.search.substring(1)
    const sURLVariables = sPageURL.split('&')
    let i = 0;
    while (i < sURLVariables.length) {
      const sParameterName = sURLVariables[i].split('=')
      if (sParameterName[0] === sParam) {
        return sParameterName[1];
      }
      i++;
    }
  },

  get_mediainfo_data() {
    $.ajax({
      url: "/assets/mediainfo",
      type: "GET",
      dataType: "JSON",
      data: {
        job_id: $("#asset_job_id").val(),
        asset_id: $("#asset_a_ID").val()
      },
      beforeSend() {
        $(".mediainfo.details.insert").empty()
        return $(".mediainfo.details.insert").append('<div class="ui basic segment"><br><br><br><br><div class="ui active inverted dimmer"><div class="ui large loader"></div></div><br><br><br><br></div>')
      },
      success(data, _textStatus, _xhr) {
        if ($(".mediainfo.details.insert").text() === "") {
          $(".mediainfo.details.insert").empty()
          $(".mediainfo.details.insert").text(data.xml_data)
          return $(".mediainfo.details.insert").each((_i, block) => hljs.highlightElement(block))
        }
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown)
        $(".mediainfo.details.insert").empty()
        return $(".mediainfo.details.insert").append(`<div class="ui negative message"><p>${errorThrown}</p></div>`)
      }
    })
  },

  workflow_job_template_search() {
    $('#search_workflowjobs').dropdown({
      fullTextSearch: false,
      onChange(_value, _text, _$selectedItem) {
        if ($('#search_workflowjobs').dropdown("get value").length > 0) {
          $('.advanced.workflow.template.filter').show()
        } else {
          $(".advanced.workflow.template.filter").hide()
        }
      }
    })

    $('#search_without_workflowjobs').change(function() {
      if ($('#search_without_workflowjobs').prop("checked")) {
        $('#search_workflowjob_status').dropdown().hide()
      } else {
        $("#search_workflowjob_status").dropdown().show()
      }
    })
  },

  on_change_defaults() {
    // $("#filter_arq").on("change", () => $('.search.asset_request_import').search("search remote", $('.search.asset_request_import').search("get value")))
    // $("#filter_order").change(() => $('.search.order_import').search("search remote", $('.search.order_import').search("get value")))

    $("#asset_a_aty_ID").change(function() {
      $('#asset_a_co_ID').dropdown('clear')
      $('#asset_a_afo_ID').dropdown('clear')
      assets.adjust_form()
      //for client side validation
      $('#asset_a_afo_ID').change().blur()
    })

    assets.tc_helper()

    $(".duration-changer").change(function() {
      let clean_value = $(this).val().replace(/[^0-9]/g, '')
      clean_value = assets.pad(clean_value, 5, 0)
      clean_value = clean_value.replace(/(.{1})(.{2})(.{2})/, '$1$2:$3')
      return $(this).val(clean_value)
    }).change()

    $(".dimension-changer").change(function() {
      const clean_value = $(this).val().replace(/[^0-9]/g, '')
      return $(this).val(clean_value)
    }).change()

    $("#asset_a_ScanningFormat").change(() => assets.adjust_scanformat())

    $("#asset_a_ArrivalChannel").change(function() {
      return assets.adjust_arrival_from(this)
    })
  },

  tc_helper(_element) {
    $(".tc-changer").on('change', function() {
      assets.clean_framerate($(this))
      let framerate = Timecode.check_framerate($("option:selected", $("#asset_a_fr_ID")).data('framerate'))
      let tc_format = $("option:selected", $("#asset_a_TimecodeFormat")).val()


      let this_tc = Timecode.from_string($(this).val(), framerate, tc_format)
      $(this).val(this_tc.toString())

      let file_duration_tc = Timecode.from_string($("#asset_a_FileDurationTimecode").val(), framerate, tc_format, true)
      let file_start_tc    = Timecode.from_string($("#asset_a_FileStartTimecode").val(),    framerate, tc_format, true)
      $("#asset_a_FileEndTimecode").val(file_start_tc.add(file_duration_tc).toString())
      $(".asset_a_FileStartTimecode_copy").text($("#asset_a_FileStartTimecode").val())
      $(".asset_a_FileEndTimecode_copy").text($("#asset_a_FileEndTimecode").val())
      $(".content-net-dur").text($("#asset_a_FileDurationTimecode").val())
    }).change()

    $(".clean-tc").change(function() {
      return assets.clean_framerate($(this))
    }).change()
  },

  add_title_dblclick(item) {
    const divdbl = $(item)
    return divdbl.dblclick(function() {
      divdbl.val($("#asset_a_Title").val() + " " + $("#asset_a_AdditionalTitle").val())
    })
  },

  add_tc_in_dblclick(item) {
    const divdbl = $(item)
    return divdbl.dblclick(function() {
      divdbl.val($("#asset_a_FileStartTimecode").val())
      divdbl.change()
    })
  },

  add_tc_out_dblclick(item) {
    const divdbl = $(item)
    return divdbl.dblclick(function() {
      divdbl.val($("#asset_a_FileEndTimecode").val())
      divdbl.change()
    })
  },

  init_index_body() {
    $('#assets .with-tooltip').popup({
      inline: true,
      exclusive: true,
      delay: {
        show: 500,
        hide: 0
      }
    })

    assets.load_tags_labels($('#assets .remote.tag'))

    $('#assets .proxy_icon').each((_i, proxy_icon) =>
      $.ajax({
        url: `/assets/${$(proxy_icon).data('id')}/proxy_icon`
      }).done(function(result) {
        $(proxy_icon).html(result)
      })
    )

    $('#assets .quicklook.cover').each((_i, cover) =>
      $.ajax({
        url: `/assets/${$(cover).data('asset-id')}/cover`
      }).done(function(result) {
        $(cover).find('.ui.placeholder').replaceWith(`<img src="${result}" class="ui cover image">`)
        assets.load_cover_warning_sign(cover)
      })
    )

    assets.load_cover('#assets .quicklook.cover')

    general.quicklook_icon('#assets .quicklook')
    $('#assets .quicklook').on("click", function() {
      assets.quicklook_asset($(this).data('asset-id'))
    })

    api.init()
  },

  load_cover(node) {
    $(node).each((_i, cover) =>
      $.ajax({
        url: `/assets/${$(cover).data('asset-id')}/cover`
      }).done(function(result) {
        $(cover).find('.ui.placeholder').replaceWith(`<img src="${result}" class="ui cover image">`)
        assets.load_cover_warning_sign(cover)
      })
    )
  },

  load_cover_warning_sign(cover) {
    $(cover).find('.warning.sign[data-type="error"]').show()
    fetch(`/assets/${$(cover).data('asset-id')}/check_validation_status`)
      .then(response => response.json())
      .then(data => {
        if (data.validation_status == 'failed') {
          $(cover).find('.warning.sign[data-type="warning"]').show()
        }
      })
      .catch((error) => { console.log(error)})
  },

  load_tags_labels(namespace) {
    namespace.each((_i, tag) =>
      $.ajax({
        url: `/assets/${$(tag).data('id')}/asset_tags`
      }).done(function(data) {
        $(tag).html(data)
      }) 
    )
  },
      
  check_search_inputs(event) {
    if (event.keyCode === 16) {
      return true;
    } else if (event.keyCode === 17) {
      return true;
    } else if (event.keyCode === 18) {
      return true;
    } else if (event.keyCode === 91) {
      return true;
    } else if (event.keyCode === 93) {
      return true;
    } else if (event.keyCode === 37) {
      return true;
    } else if (event.keyCode === 38) {
      return true;
    } else if (event.keyCode === 39) {
      return true;
    } else if (event.keyCode === 40) {
      return true;
    } else {
      return false;
    }
  },

  call_season_ajax() {
    if ($('#title_selection').val() === "") { 
      $('#asset_title_asset_attributes_id').val(null)
      return 
    }

    $('#asset_title_asset_attributes_ta_ts_ID').dropdown('queryRemote', '', function() { 
      const options = $('#asset_title_asset_attributes_ta_ts_ID').parent().find('.item').toArray().filter((o) => $(o).data('value') == '' ? false : true)
      if (options.length == 1) {
        $('#asset_title_asset_attributes_ta_ts_ID').dropdown('set exactly', $(options[0]).data('value'))
      } else if (options.length > 1) {
        if ($('#title_selection').val() !== "") {
          $('#asset_title_asset_attributes_ta_ts_ID').dropdown('restore defaults')
          if ($('#asset_title_asset_attributes_ta_ts_ID').val() === null || $('#asset_title_asset_attributes_ta_ts_ID').val() === "") {
            $('#asset_title_asset_attributes_ta_ts_ID').dropdown('set exactly', $(options[0]).data('value'))
          }
        }
      }
      $("#title_selection").dropdown("refresh")
      assets.adjust_season()
      assets.adjust_file_name()
    })
  },

  adjust_season(with_seasons = true) {
    const options = $('#asset_title_asset_attributes_ta_ts_ID').parent().find('.item').toArray().filter((o) => $(o).data('value') == '' ? false : true)

    if (options !== null) {
      for (let season of Array.from(options)) {
        let t = $(season).data('text')
        if (t != null) {
          if ((parseInt(t, 10) === 0) || (t === "") ) {
            with_seasons = false
            if ((options.length > 1) && (parseInt($(options[1]).data('text'), 10) !== 0)) {
              with_seasons = true
            }
            break
          }
        }
      }
      if (with_seasons) {
        $(".season.field").show()
        $(".title.selection.field").addClass("twelve wide")
        $(".season.field").addClass("four wide")

        $(".season.field").parent().removeClass("field").addClass("two fields")
      } else {
        $(".season.field").hide()
        $(".title.selection.field").removeClass("twelve wide")
        $(".season.field").removeClass("four wide")
        return $(".season.field").parent().removeClass("two fields").addClass("field")
      }
    }
  },

  set_archives() {
    const archives = $.ajax({
      url: "/assets/storage_policy",
      type: "GET",
      dataType: "json",
      data: {
        a_c_ID: $("#asset_a_c_ID").val(),
        a_IsExplicitContent: $("#asset_a_IsExplicitContent").prop("checked")
      },
      complete() {},
      success(_data, _textStatus, _xhr) {},
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })

    return archives.done(function(data, _textStatus, _jqXHR) {
      const select = $("#asset_a_sp_ID")[0];
      if (select !== undefined) {
        if (select !== null) {
          $("#asset_a_sp_ID").empty()
          for (let format of Array.from(data.storage)) {
            const option = document.createElement("option")
            option.value = format.sp_ID;
            option.innerHTML = format.sp_Name;
            select.appendChild(option)
            if ((`${format.sp_ID}`) === (`${data.client}`)) {
              $("#asset_a_sp_ID").val(format.sp_ID)
              $("#asset_a_sp_ID").dropdown("refresh").dropdown("set value", format.sp_ID).dropdown("set text", format.sp_Name)
            }
          }
        }
      }

      if ($("#asset_archive").val() !== "5") {
        $("#asset_a_sp_ID").val($("#asset_archive").val())
        return $("#asset_a_sp_ID").dropdown("set selected", $("#asset_archive").val())
      }
    })
  },

  pad(n, width, z) {
    z = z || "0";
    n = n + "";
    if (n.length >= width) { return n; } else { return new Array((width - n.length) + 1).join(z) + n; }
  },

  negative_tc(tc) {
    let negative_value = "";
    if (tc !== undefined) {
      if (tc[0] !== undefined) {
        if (tc[0].toString().startsWith("-")) {
          while (tc[0].toString().charAt(0) === '-') {
            tc[0] = tc[0].toString().substr(1)
            negative_value = "-";
          }
        }
      }
    }
    return [negative_value, tc];
  },

  clean_framerate(tc) {
    const negative_tc = assets.negative_tc(tc.val().split(":"))
    tc.val(negative_tc[1])

    if (tc.val().length > 0) {
      let clean_value = tc.val().replace(/[^0-9]/g, '')
      clean_value = assets.pad(clean_value, 8, 0)
      clean_value = clean_value.replace(/(.{2})(.{2})(.{2})(.{2})/, '$1:$2:$3:$4')

      tc.val(clean_value)
      const splitter = tc.val().split(":")
      tc.val(negative_tc[0] + clean_value)

      if (splitter[3].length === 3) {
        let newframes = "00";
        const framerate = assets.get_framerate()
        newframes = (splitter[3] * framerate) / 1000;
        splitter[3] = assets.pad(Math.ceil(newframes), 2, 0)
        return tc.val(negative_tc[0] + splitter[0] + ":" + splitter[1] + ":" + splitter[2] + ":" + splitter[3])
      }
    }
  },

  substract_framerates(timecode_start, timecode_end) {
    if ((timecode_start.val() === "") || (timecode_end.val() === "")) {
      return;
    }
    const tc_in = timecode_start.val().split(":")
    const tc_out = timecode_end.val().split(":")
    const tc_dur = [1, 2, 3, 4];
    tc_dur[3] = parseInt(tc_out[3]) - parseInt(tc_in[3])
    tc_dur[2] = parseInt(tc_out[2]) - parseInt(tc_in[2])
    tc_dur[1] = parseInt(tc_out[1]) - parseInt(tc_in[1])
    tc_dur[0] = parseInt(tc_out[0]) - parseInt(tc_in[0])
    return assets.correct_time_in_tc(tc_dur)
  },

  correct_time_in_tc(tc) {
    const negative_tc = assets.negative_tc(tc)
    tc = negative_tc[1];

    if (tc[3] === undefined) {
      tc[3] = 0;
    }
    if (tc[2] === undefined) {
      tc[2] = 0;
    }
    if (tc[1] === undefined) {
      tc[1] = 0;
    }

    if (parseInt(tc[3]) < 0) {
      tc[3] = parseInt(tc[3]) + parseInt(assets.get_framerate())
      tc[2] = parseInt(tc[2]) - 1;
    }

    if (parseInt(tc[2]) < 0) {
      tc[2] = parseInt(tc[2]) + 60;
      tc[1] = parseInt(tc[1]) - 1;
    }
    if (parseInt(tc[1]) < 0) {
      tc[1] = parseInt(tc[1]) + 60;
      tc[0] = parseInt(tc[0]) - 1;
    }
    if (parseInt(tc[0]) < 0) {
      tc[0] = "00";
      tc[1] = parseInt(tc[1]) - 1;
    }
    return assets.pad(negative_tc[0] + tc[0], 2, 0) + ":" + assets.pad(tc[1], 2, 0) + ":" + assets.pad(tc[2], 2, 0) + ":" + assets.pad(tc[3], 2, 0)
  },

  get_framerate() {
    let framerate = $("option:selected", $("#asset_a_fr_ID")).data('framerate')
    if (framerate === 23.976) {
      framerate = 24;
    }
    if (framerate === 29.97) {
      framerate = 30;
    }
    if (framerate === 59.94) {
      framerate = 60;
    }
    if (framerate === "") {
      framerate = 24;
    }
    return framerate;
  },

  add_framerates(timecode_start, timecode_end) {
    if ((timecode_start.val() === "") || (timecode_end.val() === "")) {
      return;
    }
    const tc_in = timecode_start.val().split(":")
    const tc_out = timecode_end.val().split(":")
    const tc_dur = [1, 2, 3, 4];
    tc_dur[3] = parseInt(tc_in[3]) + parseInt(tc_out[3])
    tc_dur[2] = parseInt(tc_in[2]) + parseInt(tc_out[2])
    tc_dur[1] = parseInt(tc_in[1]) + parseInt(tc_out[1])
    tc_dur[0] = parseInt(tc_in[0]) + parseInt(tc_out[0])
    if (parseInt(tc_dur[3]) > (parseInt(assets.get_framerate() - 1))) {
      tc_dur[3] = parseInt(tc_dur[3]) - parseInt(assets.get_framerate())
      tc_dur[2] = parseInt(tc_dur[2]) + 1;
    }
    if (parseInt(tc_dur[2]) >= 60) {
      tc_dur[2] = parseInt(tc_dur[2]) - 60;
      tc_dur[1] = parseInt(tc_dur[1]) + 1;
    }
    if (parseInt(tc_dur[1]) >= 60) {
      tc_dur[1] = parseInt(tc_dur[1]) - 60;
      tc_dur[0] = parseInt(tc_dur[0]) + 1;
    }
    if (parseInt(tc_dur[0]) >= 60) {
      tc_dur[0] = "00";
    }
    return assets.pad(tc_dur[0], 2, 0) + ":" + assets.pad(tc_dur[1], 2, 0) + ":" + assets.pad(tc_dur[2], 2, 0) + ":" + assets.pad(tc_dur[3], 2, 0)
  },

  calc_content_net_dur() {
    const content_durs = document.getElementsByClassName("content-tc-dur")
    let i = content_durs.length
    $("#asset_a_ContentNetDurationTimecode").val("00:00:00:00")

    let framerate = Timecode.check_framerate($("option:selected", $("#asset_a_fr_ID")).data('framerate'))
    let tc_format = $("option:selected", $("#asset_a_TimecodeFormat")).val()

    while (i--) {
      if ($(content_durs[i]).parents('.compact.grid').find(".content-is-active").prop('checked')) {
        let tc_dur         = Timecode.from_string($("#asset_a_ContentNetDurationTimecode").val(), framerate, tc_format)
        let tc_content_dur = Timecode.from_string($(content_durs[0]).val(), framerate, tc_format)
        $("#asset_a_ContentNetDurationTimecode").val(tc_dur.add(tc_content_dur).toString())
      }
    }
    const net_dur = $("#asset_a_ContentNetDurationTimecode").val().split(":")
    while (parseInt(net_dur[0]) > 0) {
      net_dur[1] = parseInt(net_dur[1]) + 60
      net_dur[0] = parseInt(net_dur[0]) - 1
    }
    if ((net_dur[1] === undefined) || (net_dur[2] === undefined)) {
      net_dur[1] = "000"
      net_dur[2] = "00"
    }
    return $("#asset_a_ContentNetDuration").val(net_dur[1] + ":" + net_dur[2])
  },

  adjust_file_name() {
    if (($("#asset_job_id").val() !== "") && ($("#asset_job_id").val() !== undefined)) {
      const filenames = [];
      let num = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if ($(`#asset_asset_filenames_attributes_${num}_afn_OriginalFilename`).length > 0) {
          filenames.push({ filename: $(`#asset_asset_filenames_attributes_${num}_afn_OriginalFilename`).val(), audio_attribute: assets.gen_audio_attrib(num), file_type: $(`#asset_asset_filenames_attributes_${num}_afn_aty_ID`).val() })
          num++;
        } else {
          break;
        }
      }
      $.ajax({
        url: "/assets/filenames",
        type: "GET",
        dataType: "json",
        data: {
          asset_filenames: JSON.stringify(filenames, null, null),
          title_id: $("#title_selection").val(),
          a_aty_ID: $("#asset_a_aty_ID").val(),
          a_ScanningFormat: $("#asset_a_ScanningFormat").val(),
          a_ContentType: $("#asset_a_ContentType").val(),
          a_AdditionalTitle: $("#asset_a_AdditionalTitle").val(),
          a_lc_ID: $("#asset_a_lc_ID").val(),
          far: $("#asset_a_far_ID option:selected").text(),
          framerate: $("option:selected", $("#asset_a_fr_ID")).data('framerate'),
          a_co_ID: $("#asset_a_co_ID").val(),
          a_FormatHeight: $("#asset_a_FormatHeight").val(),
          a_FileStartTimecode: $("#asset_a_FileStartTimecode").val(),
          a_ID: $("#asset_a_ID").val(),
          a_SubtitleType: $('#asset_a_SubtitleType').val(),
          a_afo_ID: $('#asset_a_afo_ID').val()
        },
        success(data, _textStatus, _xhr) {
          num = 0;
          return (() => {
            const result = [];
            // eslint-disable-next-line no-constant-condition
            while (true) {
              if ($(`#asset_asset_filenames_attributes_${num}_afn_OriginalFilename`).length > 0) {
                $(`#asset_asset_filenames_attributes_${num}_afn_Filename`).val(data['asset_filenames'][num])
                $(`#asset_asset_filenames_attributes_${num}_afn_Filename`).parent().find(".header").text(data['asset_filenames'][num])
                result.push(num++)
              } else {
                break;
              }
            }
            return result;
          })()
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown)
        }
      })

      return $.ajax({
        data: {
          a_c_ID: $("#asset_a_c_ID").val(),
          a_Title: $("#asset_a_Title").val(),
          a_AdditionalTitle: $("#asset_a_AdditionalTitle").val(),
          a_Ownership: $("#asset_a_Ownership").val(),
          a_lc_ID: $("#asset_a_lc_ID").val()
        },
        url: '/assets/find_duplicates',
        type: "POST"
      }).done(function(data) {
        const duplicate_warning = $(".asset.duplicate.warning")
        duplicate_warning.html("")
        duplicate_warning.html(data)
        general.message_closable()
      }).fail(function() {
        console.log("fail")
      })
    }
  },

  set_project_data(target, data) {
    if (data !== undefined) {
      const { project } = data;
      $.ajax({
        url: `/projects/${project.p_ID}/card`
      }).done(function(result) {
        $(target).find(".project-id-display").append(result)
      }).fail(function() {
        console.log('error')
      })
      $(target).find(".project-id").val(project.p_ID)
      $(target).find(".v-enc").prop("checked", project.ap_IsVideoEncodingSource)
      $(target).find(".a-enc").prop("checked", project.ap_IsAudioEncodingSource)
      $(target).find(".s-enc").prop("checked", project.ap_IsSubtitleEncodingSource)
      $(target).find(".v-del").prop("checked", project.ap_IsVideoDeliverySource)
      $(target).find(".a-del").prop("checked", project.ap_IsAudioDeliverySource)
      return $(target).find(".s-del").prop("checked", project.ap_IsSubtitleDeliverySource)
    }
  },
  
  set_asset_data(target, data) {
    return new Promise(function (resolve, reject) {
      if (data !== undefined) {
        const { asset } = data
        $(target).find(".asset-id").val(asset.a_ID)
        $.ajax({
          data: {
            type: $(target).find(".asset-id-display").attr("data-type"),
            fields: $(target).find(".asset-id-display").attr("data-fields")
          },
          url: `/assets/${asset.a_ID}/card`
        }).done(function(result) {
          $(target).find(".asset-id-display").append(result)
          $(target).find('.sync-start-tc').val(asset.a_FileStartTimecode)
          if (["Subtitle"].includes($(target).find('.type [data-tooltip]').data('tooltip')) || $('#asset_a_aty_ID').val() == 11) {
            $(target).find('.sync-start-tc').removeAttr("disabled")
          }
          $(target).find('.sync-start-tc').on('change', function() {
            assets.clean_framerate($(this))
          }).trigger('change')
          resolve()
        }).fail(function() {
          console.log('error')
          reject()
        })
      } else {
        reject()
      }
    }).catch(() => {})
  },

  diff_check(import_asset_id) {
    if (import_asset_id == "") { return }
    return $.ajax({
      url: `/assets/${import_asset_id}/diff`,
      success(data, _textStatus, _xhr) {
        const apply_diff = (field, data) => {
          if ((data !== null) && (data !== "")) {
            if (field.val() !== data) {
              field.parent().attr("data-content", data).attr("data-title", "Value from imported asset").attr("data-variation", "very wide inverted").addClass('with-tooltip');
              if (field.attr("type") === "text") {
                field.addClass('diffed');
                field.dblclick(function () {
                  field.val(field.parent().attr("data-content"));
                  field.removeClass('diffed');
                  return field.parent().popup('destroy');
                });
              } else {
                field.parent().addClass('diffed');
                field.dropdown().dropdown('setting', 'onChange', function () {
                  field.parent().removeClass('diffed');
                  return field.parent().popup('destroy');
                });
              }
            }
          }
        };
        apply_diff($("#asset_a_afo_ID"), data.a_afo_ID);
        apply_diff($("#asset_a_co_ID"), data.a_co_ID);
        apply_diff($("#asset_a_SubtitleType"), data.a_SubtitleType);
        apply_diff($("#asset_a_TimecodeFormat"), data.a_TimecodeFormat);
        apply_diff($("#asset_a_ScanningOrder"), data.a_ScanningOrder);
        apply_diff($("#asset_a_Color"), data.a_Color);
        apply_diff($("#asset_a_ChromaSampling"), data.a_ChromaSampling);
        apply_diff($("#asset_a_FileDuration"), data.a_FileDuration);
        apply_diff($("#asset_a_FileDurationTimecode"), data.a_FileDurationTimecode);
        apply_diff($("#asset_a_FileStartTimecode"), data.a_FileStartTimecode);
        apply_diff($("#asset_a_FileEndTimecode"), data.a_FileEndTimecode);
        apply_diff($("#asset_a_FormatWidth"), data.a_FormatWidth);
        apply_diff($("#asset_a_FormatHeight"), data.a_FormatHeight);
        return $('#assets .with-tooltip').popup({ inline: true })
      }
    })
  },

  fill_asset_storage_usage_chart(div, asset_id) {
    $.ajax({
      url: "/charts/asset_storage_overview",
      type: "GET",
      dataType: "json",
      data: {
        asset_id
      },
      success(data, _textStatus, _xhr) {
        if (data !== null) {
          $(`#${div}`).html('<div id="asset-storage-usage-chart" style="height:120px; width:200px;"></div>')
          charts.asset_storage_usage_chart('asset-storage-usage-chart', data)
        } else {
          $(`#${div}`).html('<div class="box"><div class="ui basic teal label">Not indexed yet</div></div>')
        }
      }
    })
  },

  gen_audio_attrib(num) {
    let audios = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if ($(`#asset_asset_filenames_attributes_${audios}_afn_OriginalFilename`).length > 0) {
        audios++;
      } else {
        break;
      }
    }
    if (audios !== 1) {
      if ($(`#asset_asset_filenames_attributes_${num}_afn_Attribute`).val() !== "") {
        return $(`#asset_asset_filenames_attributes_${num}_afn_Attribute`).val()
      } else {
        return `${num + 1}`;
      }
    } else {
      return "";
    }
  },

  quicklook_asset(asset_id) {
    $.ajax({
      url: `/assets/${asset_id}/quicklook`,
      data:{
        modal: true
      }
    }).done(function(data) {
        $('body').append(data)
        
        $('.ui.quicklook.modal').modal({
          duration: 200,
          onShow() {
            assets.show()
            context_menu.init()
          },
          onHidden() {
            playerTools.removeVideo()
            $('body').find(".quicklook.modal.proxyvideo_asset_id").remove()
          }
        }).modal('show')
      }).fail( error => console.log(error))
  },

  missingcheck(title, divider) {
    if ((title === null) || (title === undefined)) {
      if (divider === undefined) {
        return "-";
      } else {
        return "";
      }
    } else {
      if (divider === undefined) {
        return title;
      } else {
        return divider + title;
      }
    }
  },

  set_current_storage_location() {
    const link = window.location.href.replace("edit", "current_storage_location")
    return $.ajax({
      url: link,
      type: "GET",
      dataType: "json",
      data: {
        with_windows_format: (navigator.appVersion.indexOf("Win") !== -1 )
      },
      success(data, _textStatus, _xhr) {
        if (data.workspace) {
          general.copy_menu()
          $('.copy.path').show()
          return $('#copytextarea').text(data.location)
        }
      }
    })
  },

  mark_required_mediainfo_label(field) {
    return (() => {
      const result = [];
      for (field of Array.from($('.two.mediainfo.fields'))) {
        const label = $(field).find('label')
        const input_field = $(`#${label.attr('for')}`)
        if (input_field.val() === "") {
          result.push(label.addClass('not-checked-warning'))
        } else {
          result.push(label.removeClass('not-checked-warning'))
        }
      }
      return result;
    })()
  },

  init_modals() {
    $("#import_last_asset").on('click', () => Turbo.visit(`/assets/new?jobid=${$("#asset_job_id").val()}&import_asset_id=${$("#import_last_asset").attr("data-last-asset")}`))
    $(".modal_import").on('click', () => assets.import_modal("modal_import"))
    $(".modal_import_order").on('click', () => assets.import_modal("modal_import_order"))
    $(".modal_import_request").on('click', () => assets.import_modal("modal_import_request"))
    $(".modal_update").on('click', () => assets.import_modal("modal_update"))
  },
      
  import_modal(type) {
    $.ajax({
      url: "/assets/import/modal",
      type: "GET",
      data: {
        asset_id: $("#asset_a_ID").val(),
        job_id: $("#asset_job_id").val(),
        suggest_asset_request_id: $("#suggest_asset_request_id").val(),
        suggest_asset_id: $("#suggest_asset_id").val(),
        suggest_update_existing_id: $("#suggest_update_existing_id").val(),
        suggest_asset_existing_id: $("#suggest_asset_existing_id").val(),
        suggest_update_id: $("#suggest_update_id").val(),
        has_special_filename: $("#has_special_filename").val(),
        asset_type: $('#asset_a_aty_ID').val(),
        type
      },
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $('.import.modal').modal({
          transition: 'fade up',
          duration: 200,
          autofocus: false,
          onShow() {
            assets.import_modal_init()
          },
          onHidden() {
            $('.import.modal').remove()
          }
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  settings_modal(context) {
    $('.ui.popup').popup("hide all")
    $.ajax({
      url: `/assets/${$('#asset_a_ID').val()}/settings_modal`,
      type: "GET",
      data: {
        context
      },
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $('.proxy-settings.modal').modal({
          onShow() {
            $(this).find('.ui.dropdown').not(".custom").dropdown()
            assets.streaming_location_autocompletion('.proxy-settings.modal')
            assets.streaming_location_nested_fields('.proxy-settings.modal')
          },
          onHidden() {
            $('.proxy-settings.modal').remove()
          },
          autofocus: false
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  streaming_location_nested_fields(node) {
    $(node).off("fields_added.nested_form_fields")
    $(node).on("fields_added.nested_form_fields", function(event, param) {
      //console.log(param.object_class)
      switch (param.object_class) {
        case "asset_streaming_location":
          //console.log(event.target)
          $(event.target).find(".srl-id").val(param.additional_data.streaming_location.srl_ID)
          $(event.target).find(".description.field").html(param.additional_data.streaming_location.srl_Description)
          
          return
          default:
            return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
        }
      })
  },

  streaming_location_autocompletion(node) {
    let search_field = $(node).find(".search.streaming_locations")
    search_field.search("destroy")
    
    const url = '/assets/streaming_locations.json?search_input={/query}';
    return search_field.search({
      cache: false,
      minCharacters: 0,
      apiSettings: {
        url,
      },
      fields: {
        results: "streaming_locations"
      },
      templates: {
        standard: assets.streaming_location_search
      },
      onResultsAdd(_html) {
        
        return true
      },
      onSelect(result, _response) {
        $(node).find('.search.streaming_locations .add_nested_fields_link').trigger('click', [{
          streaming_location: result
        }])

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    })
  },

  streaming_location_search(response) {
    let html = '';
    $.each(response.streaming_locations, function(_index, option) {
      //console.log(option)
      html += '<div class="result"><div class="content">';
      if (!option.srl_IsActive) {
        html += `<div class="ui pull-right right floated mini orange basic label">Inactive</div>`;
      }
      html += `<div class="title" data-id="${option.srl_ID}">${option.srl_Description}</div>`;
      html += `<div class="description">${option.srl_Location}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  check_not_for_production_label() {
    if ($("#asset_a_IsNotForProduction").is(":checked")) {
      $('.not-for-production').show()
      $('.nfp.error.message').show()
    } else {
      $('.not-for-production').hide()
      $('.nfp.error.message').hide()
    }
  },

  flag_not_for_production_modal(flag_nfp = true) {
    $.ajax({
      url: `/assets/${$('#asset_a_ID').val()}/flag_not_for_production_modal?flag_nfp=${flag_nfp}`,
      type: "GET"}).done(function(data) {
        $("body").append(data)
        $('.flag.nfp.modal').modal({
          autofocus: false,
          closable: false,
          onShow() {
            $(this).find('.ui.dropdown').dropdown({
              onChange(value, _text, _$choice) {
                $('.flag.nfp.modal #nfp_description').val(value).trigger('change')
              }
            })
            $('.flag.nfp.modal #nfp_description').on('change input', function() {
              if ($(this).val().length > 0) {
                $('.flag.nfp.modal .approve.button').removeClass('disabled')
              }
            })
          },
          onHidden() {
            $('.flag.nfp.modal').remove()
          },
          onApprove: async function() {
            const request = new FetchRequest("post", `/assets/${$('#asset_a_ID').val()}/histories`, {
              responseKind: "turbo-stream",
              body: JSON.stringify({ "asset_history": {
                ah_upw_ID: $("#upw_ID").val(),
                ah_Description: $('.flag.nfp.modal #nfp_description').val(),
                ah_SetNotForProduction: flag_nfp,
                force_update_asset: true
                }
              })
            })
            await request.perform()
          },
          onDeny: function() {
            if (flag_nfp) {
              $("#asset_a_IsNotForProduction").parent().checkbox('set unchecked')
            } else {
              $("#asset_a_IsNotForProduction").parent().checkbox('set checked')
            }
            assets.check_not_for_production_label()
          }
        }).modal("show")
      }).fail(function(error) {
        console.log(error)
      })
  },

  sync_group_modal(modal) {
    let search_frame = modal.find('turbo-frame#searched_asset')

    modal.find('.searched.asset,.source.assets').find('turbo-frame').on('click', function(e) {
      let asset_card = $(e.currentTarget).find('.list.item[data-asset-id]')
      let selected_id = asset_card.data('asset-id')

      modal.find('.searched.asset,.source.assets').find('turbo-frame').find('.list.item[data-asset-id]').removeClass('active')
      asset_card.addClass('active')
      assets.sync_group_modal_update_sync_assets(selected_id)
    })

    modal.off('turbo:frame-load')
    modal.on('turbo:frame-load', function(e) {
      
      modal.find('.approve.button').addClass('disabled')

      let selector = $(e.originalEvent.target).find(".selector")
      let cover = $(e.originalEvent.target).find(".list.item .cover")
      if (cover.length > 0) { assets.load_cover(cover) }
      if (selector.length > 0) {
        index_select.select(selector)
        selector.show()
        selector.off('click').on('click', function(_e) {
          if ($(this).find(".selected").length > 0) {
            $(this).parent().removeClass("selected")
            index_select.unselect(this)
          } else {
            $(this).parent().addClass("selected")
            index_select.select(this)
          }
          assets.sync_group_modal_check_selected_items(modal)
        })
      }

      assets.sync_group_modal_check_selected_items(modal)
      
    })

    modal.modal({
      autofocus: false,
      onShow() {
        modal.find(".ui.sync-source-assets.search").search({
          minCharacters: 1,
          apiSettings: {
            url: '/assets/search_dropdown?q={/query}',
          },
          templates: {
            standard: search.asset_basic_search
          },
          onSelect(result, _response) {
            search_frame.html('<div class="ui active small centered inline loader"></div>')
            search_frame.attr('src', `/assets/${result.a_ID}/card?include_link=false&amp;turbo_frame_id=searched_asset&amp;type=list`)
            search_frame[0].loaded.then(() => {
              let asset_card = search_frame.find('.list.item[data-asset-id]')
              modal.find('.searched.asset,.source.assets').find('turbo-frame').find('.list.item[data-asset-id]').removeClass('active')
              asset_card.addClass('active')
            })
            assets.sync_group_modal_update_sync_assets(result.a_ID)
          }
        })
      },
      onHidden() {
        modal.remove()
      },
      onApprove: function() {
        let sync_master_ids = modal.find('turbo-frame#sync_group_sync_masters').find('turbo-frame').filter((i,e) => $(e).find('.selected').length > 0 ).map((i, e) => e.dataset?.assetSyncGroupId ).get()
        let sync_slave_ids = modal.find('turbo-frame#sync_group_sync_slaves').find('turbo-frame').filter((i,e) => $(e).find('.selected').length > 0 ).map((i, e) => e.dataset?.assetSyncGroupId ).get()

        $.post({
          url: `/assets/${$('#asset_a_ID').val()}/import_asset_sync_groups`,
          headers: {
            "Accept":"text/vnd.turbo-stream.html",
          },
          data: {
            sync_master_ids: sync_master_ids,
            sync_slave_ids: sync_slave_ids,
          }
        }).done(function(stream_message) {
          Turbo.renderStreamMessage(stream_message)

          assets.load_tab('association').done(function(data) {
            $(`.ui.bottom.attached.tab[data-tab="association"]`).html($(data).find(".tab_content").children())
            assets.init_association()
            modal.modal('hide')
          })
        }).fail(function(xhr, _text, _errorThrown) {
          modal.find('.approve.button').removeClass('loading')
          Turbo.renderStreamMessage(xhr.responseText)
        })
        return false
      },
      onDeny: function() {
        modal.modal('hide')
      }
    })
    modal.modal("show")
  },

  sync_group_modal_check_selected_items(modal) {
    if (modal.find('turbo-frame#sync_group_sync_masters,turbo-frame#sync_group_sync_slaves').find('.list.item .selected').length > 0) {
      modal.find('.approve.button').removeClass('disabled')
    } else {
      modal.find('.approve.button').addClass('disabled')
    }
  },

  sync_group_modal_update_sync_assets(asset_id) {
    $('#sync_group_sync_masters,#sync_group_sync_slaves').html('<div class="ui active small centered inline loader"></div>')
    fetch(`/assets/${asset_id}/asset_sync_groups?exclude_from=${$('#asset_a_ID').val()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then(r => r.text(), () => {})
      .then(html => Turbo.renderStreamMessage(html))
  },

  asset_attachment_modal() {
    $.ajax({
      url: "/assets/" + $("#asset_a_ID").val() + "/attachment_modal",
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $('.asset.attachment.modal').modal({
          onHidden() {
            $('.asset.attachment.modal').remove()
          }
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  init_glim() {
    $('.glimbutton').on('click', function(_e) {
      var glim_url = "https://glim.imagion.de:5001/?path="
      var glim_path = $(this).data('glim-path')

      window.open(`${glim_url}${glim_path}`, '_blank')
    })
  },

  subtitle_check() {
    let asset_id = $("#asset_a_ID").val() || $("#asset_a_ID").text()
    if (asset_id == undefined || asset_id == "") { return }

    $.ajax({
      url: `/assets/${asset_id}/webvtt_element`,
      type: "GET",
      success(data, _textStatus, _xhr) {
        $(".webvtt_element").append(data)
        $('.webvtt.subtitle.item').on('click', () => assets.webvtt_subtitle_preview())
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  webvtt_subtitle_preview() {
    let asset_id = $("#asset_a_ID").val() || $("#asset_a_ID").text()
    if (asset_id == undefined || asset_id == "") { return }

    $.ajax({
      url: `/assets/${asset_id}/webvtt_subtitle_preview_modal`,
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $('.webvtt.subtitle.modal').modal({
          onShow() {
            assets.webvtt_subtitle()
          },
          onHidden() {
            $('.webvtt.subtitle.modal').remove()
          }
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  webvtt_subtitle() {
    $.ajax({
      url: $('.webvtt.subtitle.item').attr("data-vtt-url"),
      type: "GET",
      cache: false,
      beforeSend() {
        $(".webvtt.subtitle.details.insert").empty()
        $(".webvtt.subtitle.details.insert").append('<div class="ui basic segment"><br><br><br><br><div class="ui active inverted dimmer"><div class="ui large loader"></div></div><br><br><br><br></div>')
      },
      success(data, _textStatus, _xhr) {
        if ($(".webvtt.subtitle.details.insert").text() === "") {
          $(".webvtt.subtitle.details.insert").empty()
          $(".webvtt.subtitle.details.insert").text(data)
        }
      },
      error(jqXHR, _textStatus, _errorThrown) {
        $(".webvtt.subtitle.details.insert").empty()
        $(".webvtt.subtitle.details.insert").append(`<div class="ui negative message"><p>${jqXHR.responseText}</p></div>`)
      }
    })
  },

  vidchecker_xml_view(xml_index) {
    $.ajax({
      url: "/assets/" + $("#asset_a_ID").val() + "/vidchecker_xml_modal",
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $('.vidchecker.xml.modal').modal({
          onShow() {
            assets.vidchecker_xml(xml_index)
          },
          onHidden() {
            $('.vidchecker.xml.modal').remove()
          }
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  init_vidchecker_xml_menu_button() {
    $(".vidchecker.xml.item").off("click")
    $(".vidchecker.xml.item").on('click', function() {
      assets.vidchecker_xml_view($(this).data('xmlIndex'))
    })
  },

  vidchecker_xml(xml_index) {
    $.ajax({
      url: "/assets/" + $("#asset_a_ID").val() + `/vidchecker_xml?xml_index=${xml_index}`,
      type: "GET",
      beforeSend() {
        $(".vidchecker.xml.details.insert").empty()
        $(".vidchecker.xml.details.insert").append('<div class="ui basic segment"><br><br><br><br><div class="ui active inverted dimmer"><div class="ui large loader"></div></div><br><br><br><br></div>')
      },
      success(data, _textStatus, _xhr) {
        if ($(".vidchecker.xml.details.insert").text() === "") {
          $(".vidchecker.xml.details.insert").empty()
          $(".vidchecker.xml.details.insert").text(data)
          $(".vidchecker.xml.details.insert").each((_i, block) => hljs.highlightElement(block))
        }
      },
      error(jqXHR, _textStatus, _errorThrown) {
        $(".vidchecker.xml.details.insert").empty()
        $(".vidchecker.xml.details.insert").append(`<div class="ui negative message"><p>${jqXHR.responseText}</p></div>`)
      }
    })
  },

  init_nbcu_metadata_menu_button() {
    $(".nbcu.item").off("click")
    $(".nbcu.item").on('click', () => assets.nbcu_metadata_modal())
  },

  nbcu_metadata_modal() {
    $.ajax("/assets/" + $("#asset_a_ID").val() + "/nbcu_metadata_modal")
      .done((data) => {
        $("body").append(data)
        $('.nbcu.xml.export.modal').modal({
          onShow() {
            $.ajax("/assets/" + $("#asset_a_ID").val() + "/nbcu_metadata.xml")
              .done((data) => {
                const xmlString = (new XMLSerializer()).serializeToString(data);
                $('.nbcu.xml.export.modal pre').text(xmlString)
                $('.nbcu.xml.export.modal .code').each((_, block) => hljs.highlightElement(block))
              })
              .fail((error) => {
                console.log(error.responseText)
                $('.nbcu.xml.export.modal .content').empty()
                $('.nbcu.xml.export.modal .content').append(`<div class="ui negative message"><p>${error.responseText}</p></div>`)
              })
          },
          onHidden() {
            $('.nbcu.xml.export.modal').remove()
          },
          onApprove() {
            window.open(`/assets/${$("#asset_a_ID").val()}/nbcu_metadata.xml?download=true`, '_blank');
          }
        }).modal("show")
      })
  },

  init_backup_tapes_menu_button() {
    $(".backup.tapes.item").off("click")
    $('.backup.tapes.item').on('click', function(_e) { assets.backup_tapes_modal($(this).data('target-id')) })
  },

  backup_tapes_modal(asset_id) {
    $.ajax("/assets/" + asset_id + "/backup_tapes_modal")
      .done((data) => {
        $("body").append(data)
        let modal = $('.backup.tapes.modal')
        modal.modal({
          onShow() {
            $.ajax("/assets/" + asset_id + "/backup_tapes")
              .done((data) => {
                //console.log(data)
                $('.backup.tapes.modal .content').empty()
                if (data == null) {
                  data = `<div class="ui info message"><p>Nothing Found</p></div>`
                }
                
                $('.backup.tapes.modal .content').append(`${data}`)

                $('.backup.tapes.modal .location.label').popup({position : 'right center'})

                modal.find(".item[data-tab]").tab()

                if (modal.find(".item.active .label").length > 0) {
                  if (modal.find(".item.active .label").text() == 1) {
                    modal.find(".tape.header").text( modal.find(".tape.header").text().replace("Tapes", "Tape") )
                  }
                }
              })
              .fail((error) => {
                console.log(error.responseText)
                $('.backup.tapes.modal .content').empty()
                $('.backup.tapes.modal .content').append(`<div class="ui negative message"><p>${error.responseText}</p></div>`)
              })
          },
          onHidden() {
            modal.remove()
          },
          onApprove() {
            
          }
        }).modal("show")
      })
  },

  request_backup_tapes_modal() {
    $.ajax("/assets/request_backup_tapes_modal")
      .done((data) => {
        $("body").append(data)
        let modal = $('.backup.tapes.modal')
        modal.modal({
          onShow() {
            let selected_assets = $(".select.index.elements").data("selected")
            $.ajax({
              url: "/assets/request_backup_tapes",
              data: {
                asset_ids: selected_assets
              }
            })
              .done((data) => {
                //console.log(data)
                $('.backup.tapes.modal .content').html(`${data}`)

                $('.backup.tapes.modal .location.label').popup({position : 'right center'})

                let tapes = $('.backup.tapes.modal .content').find(".huge.header").length
                let header_text = tapes + " Backup Tapes"
                if (tapes == 1) { header_text = "1 Backup Tape" }

                if (tapes > 0) { $('.backup.tapes.modal .request.tapes.button').removeClass("disabled") }

                let assets_text = selected_assets.length + " selected Assets"
                if (selected_assets.length == 1) { assets_text = "1 selected Asset" }

                modal.find(".tape.header").text( modal.find(".tape.header").text().replace("Backup Tapes", header_text).replace("selected Assets", assets_text ) )
                //modal.find(".tape.header").text( modal.find(".tape.header").text().replace("selected Assets", assets_text ) )
              })
              .fail((error) => {
                console.log(error.responseText)
                $('.backup.tapes.modal .content').empty()
                $('.backup.tapes.modal .content').append(`<div class="ui negative message"><p>${error.responseText}</p></div>`)
              })
            
          },
          onHidden() {
            modal.remove()
          },
          onApprove() {
            let tape_data = $('.backup.tapes.modal .content').find(".tapes-data").data("tapes")
            let asset_ids = $('.backup.tapes.modal .content').find(".asset-ids").data("asset-ids")
            //console.log(tape_data)

            $('.backup.tapes.modal .request.button').addClass("loading")

            $.ajax({
              url: "/assets/send_request_backup_tapes",
              type: 'POST',
              dataType: 'json',
              data: {
                tape_data: JSON.stringify(tape_data),
                asset_ids: JSON.stringify(asset_ids)
              }
            })
              .done((data) => {
                //console.log(data)
                $('.backup.tapes.modal .request.button').removeClass("loading")
                $('.backup.tapes.modal').modal("hide")
              })
              .fail((error) => {
                console.log(error.responseText)
                $('.backup.tapes.modal .request.button').removeClass("loading")
              })

            return false
          }
        }).modal("show")
      })
  },

  asset_autocompletion(nodes, params) {
    let url = '/assets/search_dropdown?q={/query}';
    if (params) {
      url = search.add_params_to_url(url, params)
    }
    $(nodes).search({
      minCharacters: search.min_characters(nodes),
      apiSettings: {
        url,
        data: {
          exclude_ids: assets.exclude_asset_ids(nodes)
        },
        beforeSend(settings) {
          settings.data.exclude_ids = assets.exclude_asset_ids(nodes)
          return settings;
        }
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        $(this).find('.asset-autocomplete-id').val(parseInt(result.a_ID))
        $(".ui.search.asset_import").search("set value", "")
        $(".ui.search.asset_import").search("hide results")
        assets.load_import_asset_card(parseInt(result.a_ID))
      }
    })
  },

  asset_request_order_autocompletion(nodes, prefix = "asset_requests") {
    $(nodes).search("destroy")
    
    let filter = false

    if (prefix == "arq") {
      prefix = "asset_requests"
      filter = false
    }

    if (prefix == "all_arq") {
      prefix = "asset_requests"
      filter = true
    }

    if (prefix == "order") {
      prefix = "orders"
      filter = false
    }

    if (prefix == "all_order") {
      prefix = "orders"
      filter = true
    }
    
    const url = '/' + prefix + '/search/import/?q={/query}';
    let query_string = ""
    return $(nodes).search({
      minCharacters: 3,
      apiSettings: {
        url,
        data: {
          filter: filter,
          c_ID: $("#import_search_client").val(),
          lc_ID: $("#import_search_language_code").val(),
          persist_search: $("#persist_search").checkbox('is checked')
        },
        beforeSend(settings) {
          settings.data.filter = filter
          settings.data.c_ID = $("#import_search_client").val(),
          settings.data.lc_ID = $("#import_search_language_code").val(),
          settings.data.persist_search = $("#persist_search").checkbox('is checked')
          return settings;
        }
      },
      templates: {
        standard: prefix == "asset_requests" ? search.asset_asset_request_search : search.asset_order_search
      },
      onSearchQuery(query) {
        query_string = query
      },
      onSelect(result, _response) {
        if (result.arq_c_ClientContactID === 541) {
          $('.swap_title_hack.checkbox').checkbox('set checked')
        }
        var id = prefix == "asset_requests" ? result.arq_ID : result.o_ID

        prefix == "asset_requests" ? $('#import_asset_request_id.asset-autocomplete-id').val(parseInt(id)) : $('#import_order_id.asset-autocomplete-id').val(parseInt(id))

        if (!$('.ui.persist_search.checkbox').checkbox('is checked')) {
          $(".ui.search.asset_request_order_import").search("set value", "")
        } else {
          $(".ui.search.asset_request_order_import").search("set value", query_string)
        }
        $(".ui.search.asset_request_order_import").search("hide results")

        var card = $(".asset_request_order.remote.card")

        $(card).data("model-name", prefix == "asset_requests" ? "asset_requests" : "orders")
        $(card).data("model-id", id)

        assets.load_arq_order_card(card)
      }
    })
    
  },

  load_arq_order_card(card) {
    if ($(card).data('model-name') != "" && $(card).data('model-id') != "") {  
      general.fetch_view(card).then(function(_data) {
        $(card).find(".ui.segment").prepend('<a class="ui black right mini corner label"><i class="times icon"></i></a>')
        $(card).find(".ui.segment .corner.label").on('click', function () {
          $('#import_asset_request_id.asset-autocomplete-id').val("")
          $('#import_order_id.asset-autocomplete-id').val("")

          $(card).data("model-name", "")
          $(card).data("model-id", "")
          $(card).find(".ui.segment").remove()
          assets.check_import_button()
        })

        const client_contact_id = $(card).find(".segment[data-client-contact-id]").data('client-contact-id')
        if (client_contact_id === 541) {
          $('.swap_title_hack.checkbox').checkbox('set checked')
        }
        assets.check_import_button()
      })
    }
  },

  load_import_asset_card(asset_id) {
    var card = $(".import-asset.modal .remote.asset.card.asset-id-display")
    $(card).data("asset-id", asset_id)

    assets.asset_card($(card).data('asset-id'), $(card).data('type')).done(html => assets.add_import_asset_card(card, html))
  },

  add_import_asset_card(card, html) {
    (card).html(html)
    
    $(".import-asset.modal .options.list").show()

    $(card).find(".card.list.item").prepend('<a class="ui black right mini corner label"><i class="times icon"></i></a>')
    $(card).find(".card.list.item .corner.label").on('click', function () {
      $('#asset_import_id.asset-autocomplete-id').val("")
      $(card).data("asset-id", "")

      $(card).find(".card.list.item").remove()
      $(".import-asset.modal .options.list").hide()
      assets.check_import_button()
    })

    $('#framerate_import').parent().checkbox('set unchecked')
    if (card.find(".type *[data-tooltip='Video']").length == 0) {
      $('#framerate_import').prop( "disabled", false )
    } else {
      $('#framerate_import').prop( "disabled", true )
    }

    assets.check_import_button()
  },

  check_import_button() {
    if ($('#asset_import_id.asset-autocomplete-id').val() != undefined && 
        $('#asset_import_id.asset-autocomplete-id').val() != "" ||
        $('#import_asset_request_id.asset-autocomplete-id').val() != undefined && 
        $('#import_asset_request_id.asset-autocomplete-id').val() != "" || 
        $('#import_order_id.asset-autocomplete-id').val() != undefined && 
        $('#import_order_id.asset-autocomplete-id').val() != ""
    ) {
      $(".import-asset-button").removeClass("disabled")
    } else {
      $(".import-asset-button").addClass("disabled")
    }
  },

  import_modal_init() {
    assets.asset_autocompletion($(".search.asset_import"))
    search.asset_request_autocompletion($(".search.asset_request_import"))
    assets.asset_request_order_autocompletion($(".search.asset_request_order_import"))

    search.asset_autocompletion($(".search.asset_update"))

    assets.check_import_button()

    if (assets.getUrlParameter('import_asset_id') !== undefined) {
      $('#asset_import_id.asset-autocomplete-id').val(assets.getUrlParameter('import_asset_id'))
      assets.load_import_asset_card(assets.getUrlParameter('import_asset_id'))
    }

    if (assets.getUrlParameter('import_asset_request_id') !== undefined) {
      $('#import_asset_request_id.asset-autocomplete-id').val(assets.getUrlParameter('import_asset_request_id'))
      const card = $(".asset_request_order.remote.card")
      $(card).data("model-name", "asset_requests")
      $(card).data("model-id", assets.getUrlParameter('import_asset_request_id'))
      assets.load_arq_order_card(card)
    }

    if (assets.getUrlParameter('import_order_id') !== undefined) {
      $('#import_order_id.asset-autocomplete-id').val(assets.getUrlParameter('import_order_id'))
      const card = $(".asset_request_order.remote.card")
      $(card).data("model-name", "orders")
      $(card).data("model-id", assets.getUrlParameter('import_order_id'))
      assets.load_arq_order_card(card)
    }
    
    $('.import-asset.modal .ui.dropdown').not(".remote,.request_order_filter").dropdown({
      onChange(value, _text, _$selectedItem) {
        assets.asset_request_order_autocompletion($(".search.asset_request_order_import"), value)

        if (value == "arq" || value == "all_arq") {
          $(".asset_request.fields").show()
        } else {
          $(".asset_request.fields").hide()
          $("#import_search_client").parent().dropdown("clear")
          $("#import_search_language_code").dropdown("clear")
        }
      }
    })

    $('.ui.persist_search.checkbox').checkbox({
      onUnchecked: function() {
        $.ajax({
          url: '/users/' + $("#current_user_id").val() + '/settings',
          type: 'POST',
          dataType: 'json',
          data: {
            settings: JSON.stringify({
            key: 'filter.asset_data_import',
            value: null,
            })
          }
        })
      }
    })

    $('.ui.request_order_filter.dropdown').dropdown({
      allowAdditions: true,
      values: [
        {
          name     : 'Filtered by Status',
          type     : 'header'
        },
        {
          name     : '<i class="file archive outline fa-fw icon"></i> Asset Requests',
          value    : 'arq',
          type     : 'item'
        },
        {
          name     : '<i class="shipping fa-fw icon"></i> Orders',
          value    : 'order',
        },
        {
          class        : 'divider',
          divider      : 'divider',
        },
        {
          name     : 'Show all',
          type     : 'header'
        },
        {
          name     : '<i class="file archive outline fa-fw icon"></i> All Asset Requests',
          value    : 'all_arq',
          type     : 'item'
        },
        {
          name     : '<i class="shipping fa-fw icon"></i> All Orders',
          value    : 'all_order',
          type     : 'item'
        }
      ],
      onChange(value, _text, _$selectedItem) {
        assets.asset_request_order_autocompletion($(".search.asset_request_order_import"), value)

        if (value == "arq" || value == "all_arq") {
          $(".asset_request.fields").show()
        } else {
          $(".asset_request.fields").hide()
          $("#import_search_client").parent().dropdown("clear")
          $("#import_search_language_code").dropdown("clear")
        }
      }
    })

    $('.import-asset.modal .ui.dropdown.noselection').dropdown({
      placeholder: false,
      action: "hide"
    })

    $('.ui.dropdown.audio-import').dropdown({
      onChange(_value, _text, selectedItem) {
        const dropdown_text = selectedItem.find('span.text').text()
        return $('.ui.dropdown.audio-import').dropdown('set text', dropdown_text)
      }
    })

    api.init()

    assets.set_language_search(".import-asset.modal")
    assets.set_client_search(".import-asset.modal")

    const value = $('.ui.dropdown.audio-import').dropdown('get value')
    const dropdown_text = $('.ui.dropdown.audio-import').dropdown('get item', value).find('span.text').text()
    $('.ui.dropdown.audio-import').dropdown('set text', dropdown_text)

    $(".import-asset-button").off('click')
    $(".import-asset-button").on('click', () =>
      $(".import-asset-button").attr("href", `/assets/new?jobid=${$("#asset_job_id").val()}` +
      `&import_asset_id=${$('#asset_import_id.asset-autocomplete-id').val()}` +
      `&import_asset_request_id=${$('#import_asset_request_id.asset-autocomplete-id').val()}` + 
      `&import_order_id=${$('#import_order_id.asset-autocomplete-id').val()}` + 
      ($('.swap_title_hack.checkbox').checkbox('is checked') === true ? "&swap_title_hack=true" : "") +
      ($("#content_import").prop("checked") === false ? "&skip_contents=true" : "") +
      ($("#project_import").prop("checked") === false ? "&skip_projects=true" : "") +
      ($("#audio_import").prop("checked") === false ? "&skip_audios=true" : "") +
      ($("#framerate_import").prop("checked") === true ? "&import_framerate=true" : "") +
      (`&audio_import_select=${$("#audio_import_select").val()}`) +
      ($("#events_import").prop("checked") === false ? "&skip_events=true" : "") +
      ($("#asset_tags_import").prop("checked") === true ? "&import_asset_tags=true" : "") +
      ($("#cropping_import").prop("checked") === true ? "&import_cropping=true" : "") +
      ($("#hdr_import").prop("checked") === true ? "&import_hdr=true" : "")
      )
    )

    $(".import-asset-request-button").off('click')
    $(".import-asset-request-button").on('click', function() {
      if ($("#asset_job_id").val() == "") {
        return $(".import-asset-request-button").attr("href", `/assets/${$("#asset_a_ID").val()}/edit/?import_asset_request_id=${$('#import_asset_request_id.asset-autocomplete-id').val()}`)
      }
    })

    $(".update-asset-button").off('click')
    $(".update-asset-button").on('click', () => $(".update-asset-button").attr("href", `/assets/${$('#asset_update_id.asset-autocomplete-id').val()}/edit?update=${$("#asset_job_id").val()}`))

    $(".suggest_asset_id").off('click')
    $(".suggest_asset_id").on('click', function() {
      $('#asset_import_id.asset-autocomplete-id').val($("#asset_suggest_asset_id").val())
      assets.load_import_asset_card($("#asset_suggest_asset_id").val())
    })

    $('.api.search.dropdown[data-action="get clients"]').dropdown('setting', 'onChange', function() {
      if ($(this).dropdown('get value') === "541") {
        $('.swap_title_hack.checkbox').checkbox('set checked')
      }
    })

    $(".suggest_asset_request_id").off('click')
    $(".suggest_asset_request_id").on('click', function() {
      $('#import_asset_request_id.asset-autocomplete-id').val($("#asset_suggest_asset_request_id").val())
      const card = $(".asset_request_order.remote.card")
      $(card).data("model-name", "asset_requests")
      $(card).data("model-id", $("#asset_suggest_asset_request_id").val())
      assets.load_arq_order_card(card)
    })

    $(".suggest_update_id").off('click')
    $(".suggest_update_id").on('click', function() {
      $('.search.asset_update').search("search remote", $("#asset_suggest_update_id").val())
    })

    $(".suggest_asset_existing_id").off('click')
    $(".suggest_asset_existing_id").on('click', function() {
      $('.search.asset_update').search("search remote", $("#asset_suggest_asset_existing_id").val())
    })

    $(".suggest_update_existing_id").off('click')
    $(".suggest_update_existing_id").on('click', function() {
      $('.search.asset_import').search("search remote", $("#asset_suggest_update_existing_id").val())
    })
  },

  rename() {
    $(".try.again.button").off('click')
    $(".try.again.button").on('click', function() {
      return assets.rename_buttons($(this), "rename_files")
    })

    $(".swap.files.button").off('click')
    $(".swap.files.button").on('click', function() {
      return assets.rename_buttons($(this), "swap_files")
    })
    
    $(".remove.files.button").off('click')
    $(".remove.files.button").on('click', function() {
      if (confirm('This action will delete the original Files in the destination Folder!')) {
        return assets.rename_buttons($(this), "remove_files")
      }
    })
  },

  rename_buttons(button, path) {
    const asset_id = button.parent().data("asset-id")
    const job_id = button.parent().data("job-id")

    button.addClass("loading")

    return $.ajax({
      url: `/assets/${asset_id}/${path}/${job_id}`,
      type: "GET"
    }).done(function(data) {
      button.removeClass("loading")
      if (data.success) {
        $(".asset.rename.dialog").remove()
        $(".body.segment").prepend(`<div class="ui positive icon message"><i class="close icon"></i><i class="copy icon"></i><div class="header">Renamed Asset ${data.asset_id} successful</div></div>`)
        general.message_closable()
      }
    }).fail(function() {
      button.removeClass("loading")
      console.log("fail")
    })
  },

  fill_report_links(asset_id) {
    return $.ajax({
      url: `/assets/${asset_id}/report_pdf_urls`,
      type: "GET"
    }).done(data =>
      Array.from(data).map((entry) =>
        console.log(entry))
    )
  },
  
  add_supplier(value, text) {
   return $("#asset_a_ArrivalFrom").dropdown("refresh").dropdown("set value", value).dropdown("set text", text)
 },

  load_remote_cards() {
    Array.from($('#assets [data-tab="all_sync_group"]').find('.remote.card')).map((card) => general.fetch_view(card))

    $('.remote.asset.card:not([data-asset-id=""])').each((_i, card) =>
      assets.asset_card($(card).data('asset-id'), $(card).data('type'), [$(card).data('fields'), 'duration-tc'].flat().filter(e => e)).done(function(html, _textStatus, _request) {
        $(card).html(html)

        let sync_start_field = $(card).siblings('.sync_slave_info,.sync_master_info').find('.sync.start.field')

        if (["Subtitle"].includes($(card).find('.type [data-tooltip]').data('tooltip')) || $('#asset_a_aty_ID').val() == 11) {
          sync_start_field.find('input').removeAttr("disabled")
        }

        if ($(card).siblings('.sync_slave_info').length > 0 && $('#asset_a_ID').val()) {
          $.ajax({
            url: `/assets/${$('#asset_a_ID').val()}/sync_slave_info_display`,
            type: "GET",
            data: {
              sync_slave_asset_id: $(card).data('asset-id')
            }, 
            beforeSend: function() {
              $(card).siblings('.sync_slave_info').find('.loader').addClass('active').removeClass('hidden')
            }
          }).done(function(info_html) {
            sync_start_field.siblings().remove()
            sync_start_field.parent().append($(info_html))
  
            let potential_issue_label = sync_start_field.parent().find('.potential_issues_label.label')
            let potential_issues = potential_issue_label.data('potential-issues')
          
            let warnings = Array().concat(potential_issues?.warnings)
            var list = $(`<ul class="potential_issues warning list"></ul>`)
            
            for (let warning of warnings) {
              let list_item = `<li>${warning}</li>`
              list.append(list_item)
            }
            var message = `
            <div class="ui compact potential_issues warning message">
              <div class="header" style="margin-bottom: 4px">
                Potential Issues
              </div>
              ${list.prop('outerHTML')}
            </div>
            `
            potential_issue_label.popup({
              position: 'right center',
              hoverable: true,
              html: message,
              lastResort: 'left center',
            })
          })
        }
        
      })
    )
   },

  comments(asset_id) {
    if (asset_id && asset_id != '') {
      $.ajax({
        url: `/assets/${asset_id}/comments`,
      }).done(function(data) {
        $("#comments-form").html(data)
        //$(".item[data-tab=comments] .comments.count").text($(".context.bar .ui.attached.tab[data-tab=comments] #comments .feed .event").length)
      })
    }
  },

  qc_annotations(asset_id) {
    if (asset_id && asset_id != '') {
      return $.ajax({
        url: `/assets/${asset_id}/qc_annotations`,
        type: 'get',
        dataType: 'json'
      })
    }
  },
  
  asset_card(asset_id, type, fields = "") {
    if (type == null) { type = "full"; }
    return $.ajax({
      url: `/assets/${asset_id}/card`,
      type: "GET",
      data: {
        type,
        fields: fields
      }
    })
  },

  projects() {
    return $("#asset-project-list .project-id").each((_i, project) =>
      $.ajax({
        url: `/projects/${$(project).val()}/card`
      }).done(function(result) {
        $(project).next().html(result)
      }).fail(function() {
      })
    )
  },

  dropdown_3d_field() {
    $("#3dmaster_asset_search").val($("#asset_a_a_3dDependenceMasterID").val())
    assets.master_3d_remove_field()

    return $("#asset_a_3dDetails").change(function() {
      if (($("#asset_a_3dDetails").val() === "3D Left Eye") || ($("#asset_a_3dDetails").val() === "3D Right Eye")) {
        $("#3dmaster-asset-div").show()
        return assets.master_3d_remove_field()
      } else {
        $("#3dmaster-asset-div").hide()
        return $("#asset_a_a_3dDependenceMasterID").val(null)
      }
    }).change()
  },

  master_3d_remove_field() {
    return $("#remove-3dmaster-asset").on('click', function() {
      $("#3dmaster_asset_search").val(null)
      $("#asset_a_a_3dDependenceMasterID").val(null)
      $("#asset_a_3dDetails").dropdown("set selected", "")
      return $("#3dmaster-asset-div").hide()
    })
  },

  init_video_accordion(autoplay) {
    if (autoplay == null) { autoplay = true }
    return $('.ui.accordion.videoplayer').accordion({
      animateChildren: false,
    })
  },
  
  init_ocr_fields() {
    // console.log "init_ocr_fields"
    return $("#events .ui.menu a.item[data-tab='ocr']").on('click', function() {
      if ($("#events .tab[data-tab='ocr'] tbody").text().length === 0) {
        assets.ocr_fields()

        $("#events .tab[data-tab='ocr'] .ui.menu #asset_event_versions_ocr").change(() => assets.ocr_fields())

        $("#events .tab[data-tab='ocr'] .ui.menu .false-positive").on('click', function() {
          $("#events .tab[data-tab='ocr'] .ui.menu .false-positive").removeClass("active")
          $(this).addClass("active")
          return assets.ocr_fields()
        })

        $(document).keydown(function(event) {
          if (event.keyCode === 13) {
            if ($("#events .tab[data-tab='ocr'] .ui.menu .item input").is(":focus")) {
              assets.ocr_fields()
              return true;
            }
          }
         })
      }
    })
  },

  ocr_fields() {
    const ocr_body = $("#events .tab[data-tab='ocr'] tbody")
    ocr_body.html('<div class="ui basic segment"><br><br><br><br><div class="ui active inverted dimmer"><div class="ui large loader"></div></div><br><br><br><br></div>')
    return $.ajax({
      url: `/assets/${$("#asset_a_ID").val()}/events/ocr`,
      type: "GET",
      data: {
        version_id: $("#events .tab[data-tab='ocr'] .ui.menu #asset_event_versions_ocr").val(),
        false_positive: $("#events .tab[data-tab='ocr'] .ui.menu .false-positive.active").data("filter"),
        filter_text: $("#events .tab[data-tab='ocr'] .ui.menu .item input").val()
      }
    }).done(function(data) {
      ocr_body.html(data)
      ocr_body.find(".ui.checkbox").checkbox()
      const version_options = $("#events .tab[data-tab='ocr'] tbody .version-options").attr("data-version-options")

      $("#events .tab[data-tab='ocr'] .item.all .count").text($("#events .tab[data-tab='ocr'] tbody .counts").attr("data-ocr-all"))
      $("#events .tab[data-tab='ocr'] .item.positive .count").text($("#events .tab[data-tab='ocr'] tbody .counts").attr("data-ocr-positive"))
      $("#events .tab[data-tab='ocr'] .item.negative .count").text($("#events .tab[data-tab='ocr'] tbody .counts").attr("data-ocr-negative"))
      const version_info_label = $("#events .tab[data-tab='ocr'] div.version-options-data")
      version_info_label.text(version_options)
      version_info_label.removeClass('hidden')
    }).fail(function() {
      console.log("fail")
    })
  },

  init_audio_transcription_fields() {
    const audio_transcription_body = $("#events .tab[data-tab='audio-transcription']")

    if ($("#asset_a_ID").val() != "" && $("#asset_a_ID").val() != undefined && $("#events .tab[data-tab='audio-transcription']").length) {
      return $.ajax({
        url: `/assets/${$("#asset_a_ID").val()}/events/audio_transcription`,
        type: "GET",
      }).done(function(data) {
        audio_transcription_body.html(data)
      }).fail(function() {
        console.log("fail")
      })
    }    
  },

  init_vidchecker_events() {
    $("#events .tab[data-tab='vidchecker-events'] .ui.dropdown.event").dropdown({
      placeholder: false, 
      fullTextSearch: false,
    }).on('change', function() {
      // reset vidchecker dropdowns
      $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown('queryRemote', '', function() { 
        $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown('restore defaults')
        const options = $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").find('.item').toArray().filter((o) => $(o).data('value') == '' ? false : true).map(
          (obj) => $(obj).data('value')
        )
        $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown('set exactly', options)
      })

      $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-type").dropdown('clear')
      // $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-type")[0].style.top = '-7px'
    })

    $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown({
      apiSettings: {
        cache: false,
        action: 'get vidchecker_alert_levels',
        data: {
          version_id: $("#events .tab[data-tab='vidchecker-events'] #vidchecker_events").dropdown("get value")
        },
        beforeSend: function (settings) {
          settings.data.version_id = $("#events .tab[data-tab='vidchecker-events'] #vidchecker_events").dropdown("get value")
          return settings;
        },
      },
      onRemove: function() {
        var d = $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level")
        var v = $(this).dropdown('get value')
        if (Array.isArray(v) && v.length == 0) {
          d && d[0] && (d[0].style.top = '')
        } else {
          d && d[0] && (d[0].style.top = '')
        }
        assets.vidchecker_event()
      },
      onAdd: function(_addedValue, _addedText, _$addedChoice) {
        var d = $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level")
        d && d[0] && (d[0].style.top = '')
      },
      onChange: function() {
        assets.vidchecker_event()
      }
    }).dropdown('queryRemote', '', function() { 
      $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown('restore defaults')
      const options = $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").find('.item').toArray().filter((o) => $(o).data('value') == '' ? false : true).map(
        (obj) => $(obj).data('value')
      )
      $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown('set exactly', options)
    })
    // $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level")[0].style.top = '-7px'

    $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-type").dropdown({
      apiSettings: {
        cache: false,
        action: 'get vidchecker_alert_types',
        data: {
          version_id: $("#events .tab[data-tab='vidchecker-events'] #vidchecker_events").dropdown("get value")
        },
        beforeSend: function (settings) {
          settings.data.version_id = $("#events .tab[data-tab='vidchecker-events'] #vidchecker_events").dropdown("get value")
          return settings;
        },
      },
      onRemove: function() {
        const d = $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-type")
        const v = $(this).dropdown('get value')
        if (Array.isArray(v) && v.length == 0) {
          d && d[0] && (d[0].style.top = '-7px')
        } else {
          d && d[0] && (d[0].style.top = '')
        }
        assets.vidchecker_event()
      },
      onAdd: function(_addedValue, _addedText, _$addedChoice) {
        const d = $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-type")
        d && d[0] && (d[0].style.top = '')
      },
      onChange: function() {
        assets.vidchecker_event()
      }
    })
    
    // column sorting for vidchecker table (should also work for all tables)
    general.table_sort('.vidchecker.table', "TC In")

    // $(document).keydown(function(event) {
    //   if (event.keyCode === 13) {
    //     if ($("#events .ui.tab[data-tab='vidchecker-events'] input").is(":focus")) {
    //       assets.vidchecker_event()
    //       return true;
    //     }
    //   }
    //  })

    // return $("#events .ui.menu a.item[data-tab='vidchecker-events']").on('click', function() {
    //   if ($("#events .tab[data-tab='vidchecker-events'] tbody").text().length === 0) {
    //     assets.vidchecker_event()
    //   }
    // })
    
  },

  init_content_marker() {
    $("#events .export.content.marker").on('click', function() {
      assets.export_to_dmm(1, "export", this)
    })

    $("#events .publish.content.marker").on('click', function() {
      assets.export_to_dmm(2, "publish", this)
    })

    $(".tc.formatter").on("change", function() {
      assets.clean_framerate($(this))
    })

    $('#ad_marker_version_selection').dropdown({
      placeholder: false,
      fireOnInit: true,
      onChange(value, _text, _$choice) {
        $('#asset_content_events .nested-form-wrapper').hide()
        if (value == "") {
          $(`#asset_content_events .nested-form-wrapper[data-version-id=""]`).show()
          $('#asset_content_events button[data-action="nested-form#add"]').show()
        } else {
          $(`#asset_content_events .nested-form-wrapper[data-version-id="${value}"]`).show()
          $('#asset_content_events button[data-action="nested-form#add"]').hide()
        }
        
      }
    })
  },

  export_to_dmm_ajax(destination, asset_id, flash = false) {
    return $.ajax({
      url: `/assets/${asset_id}/events/content_marker/to_dmm`,
      type: "POST",
      data: {
        destination: destination, 
        flash: flash,
      }
    })
  },

  export_to_dmm(destination, type, target) {
    $(target).addClass("loading")
    assets.export_to_dmm_ajax(destination, $("#asset_a_ID").val()).done(function(data) {
      $(target).removeClass("loading")
      $("#events .segment.content.marker").prepend("<div class='ui visible info animate__animated animate__fadeInDown message'>Content Marker " + type +" was sent</div>")
      if (data.publish_date.length) {
        $('.publish.sub.header').text(`Published: ${data.publish_date}`)
      }
    }).fail(function(_xhr, _textStatus, errorThrown) {
        $(target).removeClass("loading")
        console.log(errorThrown)
        $("#events .segment.content.marker").prepend("<div class='ui visible error animate__animated headShake message'>" + errorThrown + "</div>")
    })
  },

  init_all_sync_groups() {
    if ($('#asset_a_ID').length > 0 && $('#asset_a_ID').val() != '') {
      $.ajax({
        url: `/assets/${$("#asset_a_ID").val()}/all_sync_group_assets`,
        type: "GET",
      }).done(function(data) {
        $('.ui.bottom.attached.tab[data-tab="association"] .menu .item').tab()
        $('#sync-group-chain-list').html(data)
        Array.from($('#assets [data-tab="all_sync_group"]').find('.remote.card')).map((card) => general.fetch_view(card))
      }).fail(function(_xhr, _textStatus, _errorThrown) {
        $('#sync-group-chain-list').html('')
      })
    } else {
      $('#sync-group-chain-list').html('')
    }
  },

  vidchecker_event() {
    const ve_body = $("#events .tab[data-tab='vidchecker-events'] tbody")
    ve_body.html('<div class="ui basic segment"><br><br><br><br><div class="ui active inverted dimmer"><div class="ui large loader"></div></div><br><br><br><br></div>')
    if(vidchecker_event_request) {
      vidchecker_event_request.abort()
    }
    vidchecker_event_request = $.ajax({
      url: `/assets/${$("#asset_a_ID").val()}/events/vidchecker_events`,
      type: "GET",
      data: {
        version_id: $("#events .tab[data-tab='vidchecker-events'] #vidchecker_events").dropdown("get value"), 
        alert_level: $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-level").dropdown("get value"),
        alert_type: $("#events .ui.tab[data-tab='vidchecker-events'] .ui.dropdown.alert-type").dropdown("get value"),
        filter_text: $("#events .ui.tab[data-tab='vidchecker-events'] .right.menu input").val(),
      }
    }).done(function(data) {
      ve_body.html(data)
      assets.init_vidchecker_play_buttons()
    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') {
        console.log("failed to load vidchecker events")
      }
    })
  },


  init_vidchecker_play_buttons() {
    $('.asset-event.jump-to-time.button').on('click', function(event) {
      var time = $(this).data('player-time')
      var mod  = $(this).data('player-time-modifier')
      if (event.altKey) {
        window.playerTools.jumpToTime(time, true)
      } else {
        window.playerTools.jumpToTime(time - mod)
      }
    })
  },
  
  init_jump_to_tc_fields() {
    if($('.preview.playbutton').length > 0) {
      $('#content .icon.input').off('mouseenter', 'mouseleave')
      $('#content .icon.input').on('mouseenter', function() {
        if ($(this).find('input').val().length > 0) {
          $(this).find('i.play.icon').show()
        }
        if (typeof player !== 'undefined') {
          $(this).find('i.set-tc.icon').show()
        }
      }).on('mouseleave', function() {
          $(this).find('i').hide()
      })
    }

    $('#content .link.icon').on('mouseenter', function() {
      $(this).addClass('primary')
    }).on('mouseleave', function() {
      $(this).removeClass('primary')
    })

    if($('.preview.playbutton').length > 0) {
      $('#content .play.icon').off('click')
      $('#content .play.icon').on('click', function() {
        const framerate = Timecode.check_framerate($("option:selected", $("#asset_a_fr_ID")).data('framerate'))
        const tc_format = $("option:selected", $("#asset_a_TimecodeFormat")).val()
        const tc_value = $(this).siblings('input').val()
        const start_tc = Timecode.from_string($('#asset_a_FileStartTimecode').val(), framerate, tc_format, false)
        let tc = Timecode.from_string(tc_value, framerate, tc_format, false)
        tc = tc.subtract(start_tc)
        window.playerTools.jumpToTime(tc.to_runtime_seconds(), true)
      })
    }

    $("#content .set-tc.icon").off('click')
    $("#content .set-tc.icon").on('click', function() {
      if (typeof player !== 'undefined') {
        const target = $(this).siblings('input')
        target.val(playerTools.current_tc())
        target.addClass('highlight').delay(400).queue(() => target.removeClass('highlight').dequeue())
      }
    })
  },

  init_subtitle_fields() {
    return $("#events .ui.menu a.item[data-tab='subtitle']").on('click', function() {
      if ($("#events .tab[data-tab='subtitle'] tbody").text().length === 0) {
        assets.subtitle_fields()

        $("#events .tab[data-tab='subtitle'] .ui.menu #asset_event_versions_subtitle").change(() => assets.subtitle_fields())

        return $(document).keydown(function(event) {
          if (event.keyCode === 13) {
            if ($("#events .tab[data-tab='subtitle'] .ui.menu .item input").is(":focus")) {
              assets.subtitle_fields()
              return true;
            }
          }
         })
      }
    })
  },

  subtitle_fields() {
    const subtitle_body = $("#events .tab[data-tab='subtitle'] tbody")
    subtitle_body.html('<div class="ui basic segment"><br><br><br><br><div class="ui active inverted dimmer"><div class="ui large loader"></div></div><br><br><br><br></div>')
    return $.ajax({
      url: `/assets/${$("#asset_a_ID").val()}/events/subtitle`,
      type: "GET",
      data: {
        version_id: $("#events .tab[data-tab='subtitle'] .ui.menu #asset_event_versions_subtitle").val(),
        filter_text: $("#events .tab[data-tab='subtitle'] .ui.menu .item input").val()
      }
    }).done(function(data) {
      subtitle_body.html(data)
    }).fail(function() {
      console.log("fail")
    })
  },

  init_vi_fields() {
    if ($('#asset_a_ID').length > 0 && $('#asset_a_ID').val() != '') {
      $.ajax({
        url: `/assets/${$("#asset_a_ID").val()}/azure_vi_insights`,
        type: "GET"
      }).done(function(data) {
        $("#events .tab[data-tab='azure-vi']").html(data)
        $('.vi .menu .item').on('click', function() {
          $('#insight_frame').html(`<iframe width="100%", height="800px"src="${$(this).data('uri')}" frameborder="0" allowfullscreen />`)
        })
        $('.vi .menu .item').first().trigger('click')
      })
    }
  },

  init_storyboard() {
    if ($('#asset_a_ID').length > 0 && ($('#asset_a_ID').val() != '') || $('#asset_a_ID').text() != '') {
      const asset_id = $('#asset_a_ID').val() != '' ? $('#asset_a_ID').val() : $('#asset_a_ID').data('asset-id')
      $('.item[data-tab="storyboard"]').on('click', function() {
        //console.log(asset_id)
        if ($('.ui.tab[data-tab="storyboard"]').find('.loading.segment').length != 0) {
          $.ajax({
            url: `/assets/${asset_id}/show_storyboard`,
            type: "GET",
          }).done(function(data) {
            $('.ui.tab[data-tab="storyboard"]').html(data)
          }).fail(function() {
            console.log("failure to load storyboard")
          })
        }
      })
    }
  },
  
  init_tags() {
    return asset_tags.init_asset_tags_dropdown()
  },

  init_set_value_modal() {
    $.ajax({
      url: "/assets/set_value_modal",
    }).done(function(data) {
      $("body").append(data)
      
      $('.set.asset.value.modal').modal({
        autofocus: false,
        onShow() {
          $('.set.asset.value.modal .assets-search-count').text($(".select.index.elements").data("selected").length)

          $('.set.asset.value.modal .dropdown').not(".remote").not(".api").dropdown()
          $('.set.asset.value.modal .checkbox').checkbox()

          $('.qc_error_code.ui.dropdown').dropdown({
            onChange(value, _text, _$choice) {
              $('.set.asset.value.modal #nfp_description').val(value).trigger('change')
            }
          })

          $('.set.asset.value.modal #nfp_description').on('change input', function() {
            if ($(this).val().length > 0) {
              $('.set.asset.value.modal .approve.button').removeClass('disabled')
            }
          })

          $('.set.asset.value.modal .arrival_from.dropdown').dropdown({
            apiSettings: {
              cache: false,
              url: '/contacts/suppliers?q={/query}',
            },
            templates: {
              menu: dropdownTemplates.search_menu
            }
          })

          $('#title_selection').dropdown({
            allowReselection: true,
            apiSettings: {
              cache: false,
              url: '/titles/dropdown_search?q={/query}',
            },
            onChange(_value, _text, _$selectedItem) {
              $("#asset_title_asset_attributes_ta_ts_ID").dropdown('change values', null) // trigger remote on next dropdown usage
              assets.call_season_ajax()
            },
            templates: {
              menu: dropdownTemplates.title_search_menu
            }
          })

          assets.init_title_season_dropdown()

          assets.adjust_season(false)
          
          api.init()

          let check_submit = function() {
            if ($('#a_IsNotForProduction').val() == "true" && $('#nfp_description').val().length <= 0) {
              $('.set-value.button').addClass('disabled')
            } else {
              $('.set-value.button').removeClass('disabled')
            }
          }

          let asset_history_params = function() {
            return {
              ah_upw_ID: $("#upw_ID").val(),
              ah_Description: $('.set.asset.value.modal #nfp_description').val(),
              ah_SetNotForProduction: $('#a_IsNotForProduction').val()
            }
          }

          let button = $('.set.asset.value.modal .ui.set-value.button')
          button.on('click', function() {
            button.addClass("loading")
            $.ajax({
              url: "/assets/set_value",
              type: "POST",
              dataType: "json",
              data: {
                model_ids: index_select.selected_ids(),
                assets_params: general.collect_parameter('.set.asset.value.modal'),
                asset_history_params: asset_history_params()
              }
            }).done(function(_data) {
              button.removeClass("loading") 
              index_select.end_selection()

              $('.set.asset.value.modal').modal('hide')

              $(".start-search .primary.button").trigger('click')
            }).fail(() => console.log("error"))
          })

          $('#a_IsNotForProduction').on('change', function() {
            check_submit()
            if ($(this).val()) {
              if ($(this).val() == "true") {
                $('.nfp.segment .header').text("Not for Production")
                $('.nfp.segment label.description').text("Not for Production cause description")
                $('.nfp.segment .template.field').show()
              } else {
                $('.nfp.segment .header').text("Is for Production")
                $('.nfp.segment label.description').text("Is cleared for Production description")
                $('.nfp.segment .template.field').hide()
              }
              $('.nfp.segment').show()
            } else {
              $('.nfp.segment').hide()
            }
          })
          $('#nfp_description').on('change input', function() {
            check_submit()
          })
        }, 
        onHidden() {
          $('.set.asset.value.modal').remove()
        }
      }).modal("show")

    }).fail(() => console.log("error"))
  },

  init_sync_group() {
    search.asset_autocompletion(".ui.search.syncgroup-master", { title_id: assets.filter_by_title(".ui.search.syncgroup-master") })
    search.asset_autocompletion(".ui.search.syncgroup-slave", { title_id: assets.filter_by_title(".ui.search.syncgroup-slave") })
  },

  nested_fields() {
    $("#assets").off("fields_added.nested_form_fields")
    $("#assets").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "source_asset":
          return assets.set_asset_data(event.target, param.additional_data)
        case "sync_master":
          return assets.set_asset_data(event.target, param.additional_data)
        case "sync_slafe":
          return assets.set_asset_data(event.target, param.additional_data)
        case "asset_project":
          return assets.set_project_data(event.target, param.additional_data)
        case "asset_alternate_id":
          $(event.target).find('.ui.dropdown').not('.remote').dropdown({ placeholder: false, fullTextSearch: false })
          return
        case "asset_rating":
          assets.init_rating_fields(event.target)
          return
        case "asset_content":
          assets.init_content_fields(event.target)
          return
        case "audio_track":
          assets.init_audio_fields(event.target)
          return
        case "asset_event":
          assets.init_event_fields(event.target)
          return
        case "asset_event_metadata":
          assets.init_event_metadata_fields(event.target)
          return
        default:
          return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#assets").off("fields_removed.nested_form_fields")
    $("#assets").on("fields_removed.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "audio_track": 
          audio_tracks.set_audio_numbers()
          return
      default:
        return console.log(`Fields were successfully removed, callback not handled for ${param.object_class}.`)
      }
    })
  },

  remove_card(node) {
    $(node).find('.label.remove').on('click', function() {
      $(this).closest(".field").find("input[type=hidden]").val("")
      $(this).closest(".field").find(".ui.fluid.search").show()
      $(this).closest(".tiny.segment").hide()
    })
  },

  filter_by_title(element) {
    if ($(element).find(".filter_by_title").prop("checked")) {
      return $('#title_selection').val()
    } else {
      return ""
    }
  },

  filter_by_title_checkbox() {
    $('.filter_by_title').checkbox({
      onChange: function() {
        assets.init_sync_group()
      }
    })
  },

  exclude_asset_ids(element) {
    if ($(element).hasClass('syncgroup-master')) {
      let asset_ids = []
      $("#sync-masters-list").find("fieldset[style!='display: none;'] .asset.list.item").each(function (_i) {
        asset_ids.push($(this).data("asset-id"))
      })
      $("#sync-slave-list").find("fieldset[style!='display: none;'] .asset.list.item").each(function (_i) {
        asset_ids.push($(this).data("asset-id"))
      })
      return asset_ids;
    }
    if ($(element).hasClass('syncgroup-slave')) {
      let asset_ids = []
      $("#sync-masters-list").find("fieldset[style!='display: none;'] .asset.list.item").each(function (_i) {
        asset_ids.push($(this).data("asset-id"))
      })
      $("#sync-slave-list").find("fieldset[style!='display: none;'] .asset.list.item").each(function (_i) {
        asset_ids.push($(this).data("asset-id"))
      })
      return asset_ids;
    }
    if ($(element).hasClass('source-assets')) {
      let asset_ids = []
      $("#source-assets-list").find("fieldset[style!='display: none;'] .asset.list.item").each(function (_i) {
        asset_ids.push($(this).data("asset-id"))
      })
      return asset_ids;
    }
    if ($(element).hasClass('basket-assets')) {
      let asset_ids = []
      $("#quickmenu .basket-items").find(".asset.card").each(function (_i) {
        asset_ids.push($(this).data("asset-id"))
      })
      return asset_ids;
    }
    if ($(element).hasClass('compose-assets')) {
      let asset_ids = []
      $(".asset-list").find(".asset.card.asset-id-display").each(function (_i) {
        asset_ids.push($(this).data("model-id"))
      })
      return asset_ids;
    }

    return []
  },

  check_validation_status() {
    let badge = $('.show_validation_results_modal')
    if (badge.length > 0) {
      const a_ID = badge.data('id')
      fetch(`/assets/${a_ID}/check_validation_status`)
        .then(response => response.json())
        .then(data => {
          if (data.validation_status == 'failed') {
            badge.removeClass('hidden')
          }
        }).catch(() => {})
        .finally(() => {
          badge.siblings('.validation.loading.indicator').remove()
        })
    }
  },

  init_validations() {
    $('form[id*="asset"]').on('submit', function(e, params){
      $(".submit").addClass("loading")
      if (params?.submit_anyway) {
        // submits
      } else {
        e.preventDefault()
        assets.run_validations().then( _ => {
          $(".submit").removeClass("loading")
          $('.ui.warning.validation.message').addClass('hidden').find('.list').html('')
          $('form[id*="asset"]').trigger('submit', { submit_anyway: true })
        }).catch( rejects => {
          $(".submit").removeClass("loading")
          assets.show_validation_warnings(rejects)
        })
        return false
      }
    })
  },

  run_validations() {
    return new Promise(function(resolve, reject) {

      let validations = [
        assets.validate_track_groups(),
        assets.validate_preview_image_tc()
      ]

      Promise.allSettled(validations).then((results) => {
        let rejects = results.filter(x => x.status === 'rejected')
        if (rejects.length) {
          reject(rejects) // validations failed
        } else {
          resolve() // validations successful
        }
      })
    })

  },

  show_validation_warnings(rejects) {
    let warning_message = $('.ui.warning.validation.message')
    warning_message.addClass('hidden').find('.list').html("")
    warning_message.find('.buttons').html("")
    let warning_list = warning_message.find('.list')

    for (let reject of rejects) {
      let failed_validation = reject.reason
      var msg = ""
      switch (failed_validation.type) {
      case 'track_groups':
        for (let w of failed_validation.problems) {
          msg += `<li>Trackgroup: ${w.track_group}<ul>${w.errors.map(x => `<li>${x}</li>`).join('')}</ul></li>`
        }
        if (msg) { 
          let details_accordion = `<div class="ui accordion">
            <div class="ui active title">
              <i class="dropdown icon"></i>
              Potentially problematic Audio Trackgroups detected (click for details)
            </div>
            <div class="active content" style="padding-left: 1.7em">
              <ul>${msg}</ul>
            </div>
          </div>`
          $(details_accordion).appendTo(warning_list).accordion()
        }
        break
      case 'preview_image_tc':
        msg = `<li><div>Validation of the Preview Image TC failed, please check whether it is correct. Preview Image TC: ${failed_validation.preview_image_tc} <ul>${failed_validation.problems.map(x => `<li>${x}</li>`).join("")}</ul></div></li>`
        $(msg).appendTo(warning_list)
        break
      }
    }

    // actions
    var item = `<span class="element"><div class="ui icon mini submit_anyway button blue gradient"><i class="alternate cloud upload icon"></i> ${$(':input[type=submit]').first().val() || "Submit"} Anyway</div></span>`
    $(item).appendTo(warning_message.find('.buttons')).find('.submit_anyway.button').on('click', function() {
      $('form[id*="asset"]').trigger('submit', {submit_anyway: true})
      warning_message.addClass('hidden').find('.list').html("")
    })
    warning_message.removeClass('hidden').show()

  },

  validate_preview_image_tc() {
    const framerate = Timecode.check_framerate($("option:selected", $("#asset_a_fr_ID")).data('framerate'))
    const tc_format = $("option:selected", $("#asset_a_TimecodeFormat")).val()
    const preview_image_tc = Timecode.from_string($('#asset_a_PreviewImageTC').val(), framerate, tc_format)
    const preview_start_tc = Timecode.from_string($('#asset_a_PreviewStartTC').val(), framerate, tc_format)
    const preview_end_tc = Timecode.from_string($('#asset_a_PreviewEndTC').val(), framerate, tc_format)

    let checks = []
    checks.push($('#asset_a_PreviewImageTC').val() != "00:00:00:00")
    checks.push([!preview_image_tc.invalid(), "Preview Image TC is an invalid Timecode!"])
    checks.push([preview_image_tc.to_msecs() > preview_start_tc.to_msecs(), "Preview Image TC is before Preview Start TC!"])
    checks.push([preview_image_tc.to_msecs() < preview_end_tc.to_msecs(), "Preview Image TC is after Preview End TC!"])

    return new Promise(function(resolve, reject) {
      if (!checks.some(x => x[0] == false) || $('#asset_a_PreviewImageTC').val().length === 0) {
        return resolve() 
      } else {
        return reject({ type: 'preview_image_tc', preview_image_tc: $('#asset_a_PreviewImageTC').val(), problems: checks.filter(x => x[0] == false).map(x => x[1]) })
      }
    })
  },

  validate_track_groups() {
    return new Promise(function(resolve, reject) {
      var infos = []
      let trackgroups = Array.from($('.nested-fields:not([style*="display: none"],[style*="display:none"]) .audio-trackgroup.selection.dropdown').dropdown('get value'))
      if (trackgroups.length == 0 ) resolve()
      let trackgroups_in_order = !!trackgroups.reduce((n, item) => n !== false && item >= n && item, 'A')
      if (trackgroups_in_order === false) { 
        infos.push({track_group: 'ALL', errors: ['Track groups are not ordered correctly']})
      }
      
      let unique_trackgroups = trackgroups.unique()
      let validation_results = unique_trackgroups.map(x => assets.validate_track_group(x)).filter(x => x.errors.length != 0)
      if (validation_results.length != 0) { // validation of at least one trackgroup failed
        infos = infos.concat(validation_results) 
      }
      if (infos.length) {
        reject({ type: 'track_groups', problems: infos })
      } else {
        resolve()
      }
    })
  },

  validate_track_group(track_group) {
    var info = {track_group: track_group, errors: []}
    let all_tracks_in_trackgroup = $('.audio-trackgroup.selection.dropdown').toArray().filter(x => $(x).dropdown('get value') == track_group).map(x => $(x).parents('[id*="audio_track_"]')).filter(x => $(x).parent('[class="nested-fields"]')[0]?.style?.display != "none").map( (x, i) => ({ 
        track_group: track_group, 
        channel: $(x).find('.audio-track-channels.selection.dropdown').dropdown('get text'),
        usage: $(x).find('.audio-track-usages.selection.dropdown').dropdown('get text'),
        codec: $(x).find('.audio-audio-codecs.selection.dropdown').dropdown('get text'),
        sampling_rate: $(x).find('.audio-sampling-rates.selection.dropdown').dropdown('get text'),
        bit_rate: $(x).find('.audio-bit-depths.selection.dropdown').dropdown('get text'),
        loudness: $(x).find('.audio-loudness.selection.dropdown').dropdown('get text'),
        notes: $(x).find('.audio-track-notes.selection.dropdown').dropdown('get text'),
        language: $(x).find('.audio-language.selection.dropdown').dropdown('get text'),
        secondary_language: $(x).find('.secondary-audio-language.selection.dropdown').dropdown('get text'),
        index: i
      })
    )

    if (all_tracks_in_trackgroup.length == 0) {
      info.errors.push(`No Tracks found for Track Group: ${track_group}`)
      return info
    }

    for (let track of all_tracks_in_trackgroup) {

      if (!all_tracks_in_trackgroup.every(x => x.language == all_tracks_in_trackgroup[0].language)) {
        info.errors.push(`Differing languages were found`)
      }

      if (track.channel == 'Left Front') { 
        if (all_tracks_in_trackgroup.length < 3) { info.errors.push(`Invalid channel count for Left Front, should be more than 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (all_tracks_in_trackgroup[track.index + 1]?.channel != 'Right Front') { info.errors.push('Left Front must be followed by Right Front') }
      }
      if (track.channel == 'Right Front') {
        if (all_tracks_in_trackgroup.length < 3) { info.errors.push(`Invalid channel count for Right Front, should be more than 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (all_tracks_in_trackgroup[track.index - 1]?.channel != 'Left Front') { info.errors.push('Right Front must be preceeded by Left Front') }
      }

      if (track.channel == 'LT') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for LT, should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (all_tracks_in_trackgroup[track.index + 1]?.channel != 'RT') { info.errors.push('LT must be followed by RT') }
      }
      if (track.channel == 'RT') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for RT, should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (all_tracks_in_trackgroup[track.index - 1]?.channel != 'LT') { info.errors.push('RT must be preceeded by LT') }
      }

      if (track.channel == 'Stereo L') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for Stereo L, should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (all_tracks_in_trackgroup[track.index + 1]?.channel != 'Stereo R') { info.errors.push('Stereo L must be followed by Stereo R') }
      }
      if (track.channel == 'Stereo R') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for Stereo R, should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (all_tracks_in_trackgroup[track.index - 1]?.channel != 'Stereo L') { info.errors.push('Stereo R must be preceeded by Stereo L') }
      }

      if (track.channel == '5.1 (11)') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for 5.1 (11), should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (!all_tracks_in_trackgroup.every(x => (x.channel == "5.1 (11)"))) { info.errors.push(`Invalid Layout for 5.1 (11), every track's channel should be 5.1 (11)`) }
        if (!all_tracks_in_trackgroup.every(x => (x.codec == "Dolby E"))) { info.errors.push(`Invalid Layout for 5.1 (11), every track's codec should be Dolby E`) }
      }
      if (track.channel == '5.1+2 (0)') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for 5.1+2 (0), should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (!all_tracks_in_trackgroup.every(x => (x.channel == "5.1+2 (0)"))) { info.errors.push(`Invalid Layout for 5.1+2 (0), every track's channel should be 5.1+2 (0)`) }
        if (!all_tracks_in_trackgroup.every(x => (x.codec == "Dolby E"))) { info.errors.push(`Invalid Layout for 5.1+2 (0), every track's codec should be Dolby E`) }
      }
      if (track.channel == '2 + 2 (19)') {
        if (all_tracks_in_trackgroup.length != 2) { info.errors.push(`Invalid channel count for 2 + 2 (19), should be 2 but is ${all_tracks_in_trackgroup.length}`) }
        if (!all_tracks_in_trackgroup.every(x => (x.channel == "2 + 2 (19)"))) { info.errors.push(`Invalid Layout for 2 + 2 (19), every track's channel should be 2 + 2 (19)`) }
        if (!all_tracks_in_trackgroup.every(x => (x.codec == "Dolby E"))) { info.errors.push(`Invalid Layout for 2 + 2 (19), every track's codec should be Dolby E`) }
      }

      if (track.channel == 'Mono') {
        if (!all_tracks_in_trackgroup.every(x => x.channel == 'Mono')) { info.errors.push(`Mono mixed with other channels is not allowed`) }
      }

    }

    // duplicate helpers
    let countDuplicates = tracks => tracks.reduce((a, b) => ({ ...a, [b]: (a[b] || 0) + 1 }), {})
    let duplicates      = dict   => Object.keys(dict).filter((a) => dict[a] > 1)
    // codec, bit depth, sampling rate should be the same across track group, but there are exceptions

    let allowed_duplicates = [
      'LTC Timecode',
      'Mono',
      'tbc',
      'no Audio',
      '5.1 (11)',
      '5.1+2 (0)',
      '2 + 2 (19)',
      'Atmos',
    ]
    // eslint-disable-next-line no-unused-vars
    let channel_compare = all_tracks_in_trackgroup.filter(x => !allowed_duplicates.includes(x.channel)).map(({channel, ...rest}) => channel)
    let channel_duplicates = duplicates(countDuplicates(channel_compare))

    if (channel_duplicates?.length != 0) {
      if (channel_duplicates.filter(x => !allowed_duplicates.includes(x))?.length != 0) {
        info.errors.push(`Invalid duplicate channels found: ${channel_duplicates.join(', ')}`)
      }
    }
    info.errors = info.errors.unique()
    return info
  },

  show_copyable_ids_modal(asset_id) {
    $.ajax({
      url: `/assets/${asset_id}/show_copyable_ids_modal`,
    }).done(function(data, _textStatus, _xhr) {
      let modal = $(data).appendTo('body')
      modal.modal({
        autofocus: false,
        duration: 200,
        onShow() {
          modal.find('.copy_to_clipboard.button').on('click', function(_e) {
            modal.find('textarea#presigned_urls').trigger('select').each(function () { document.execCommand('copy') })
            $('body').toast({ message: `Copied Text to Clipboard!` })
          })
        },
        onHidden() {
          modal.remove()
        }
      }).modal('show')

    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      console.log(errorThrown)
    })
  },

  context_menu() {
    assets.init_vidchecker_xml_menu_button()

    $('.item.asset.show.copyable.ids').off('click')
    $('.item.asset.show.copyable.ids').on('click', function(_e) {
      assets.show_copyable_ids_modal($(this).data('target-id'))
    })
    assets.init_nbcu_metadata_menu_button()
    assets.init_backup_tapes_menu_button()
    quicklinks.context_menu()
    baskets.context_menu()
  },

  catalog_autocompletion(node) {
    let search_field = $(node).find(".search.catalog_relations")
    search_field.search("destroy")
    
    const url = '/catalog_base_objects.json?search_input={/query}&q={/query}&with_relation_type_id=true';
    return search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
      },
      fields: {
        results: "catalog_base_objects"
      },
      templates: {
        standard: assets.base_objects_search
      },
      onResultsAdd(_html) {
        setTimeout(function() {
          Array.from($(search_field).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
        }, 100)
        return true
      },
      onSelect(result, _response) {
        if (node == "#assets") {
          $(node).find('#asset_catalog_base_object_ids').val([result.id])
        } else if (node == "#projects") {
          $(node).find('#project_catalog_base_object_ids').val([result.id])
        }

        search_field.search("hide results")
        search_field.search("set value", "")

        search_field.closest(".field").find(".ui.fluid.search").hide()

        search_field.closest(".field").find(".tiny.segment .remote.catalog_base_object.card").data("model-id", result.id)

        general.fetch_view($(node).find('.remote.catalog_base_object.card'))
        
        search_field.closest(".field").find(".tiny.segment").show()
      }
    })
  },
  
  base_objects_search(response) {
    let html = '';
    $.each(response.catalog_base_objects, function(_index, option) {
      html += `
      <div class="result" style="padding: 0;">
        <div class="content">
          <div class="remote catalog_base_object card" data-model-name="catalog_base_objects" data-model-id="${option.id}" data-type="compact" data-fields="no-links" style="margin-top: 10px;">
            <div class="card list item" style="height: 62px; padding: 0.4em 1em;">
              <div class="ui placeholder">
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>`
    });
    return html;
  }
}

function getFunctionByName(functionName, context) {
  // If using Node.js, the context will be an empty object
  if(typeof(window) == "undefined") {
      context = context || global;
  }else{
      // Use the window (from browser) as context if none providen.
      context = context || window;
  }

  // Retrieve the namespaces of the function you want to execute
  // e.g Namespaces of "MyLib.UI.alerti" would be ["MyLib","UI"]
  var namespaces = functionName.split(".")
  
  // Retrieve the real name of the function i.e alerti
  var functionToExecute = namespaces.pop()
  
  // Iterate through every namespace to access the one that has the function
  // you want to execute. For example with the alert fn "MyLib.UI.SomeSub.alert"
  // Loop until context will be equal to SomeSub
  for (var i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]];
  }
  
  // If the context really exists (namespaces), return the function or property
  if(context){
      return context[functionToExecute];
  }else{
      return undefined;
  }
}

window.assets = assets;
