var workflow_template = {
  init(target, params_id) {
    workflow_template.set_on_changes(target, params_id)
    $(target).find('.ui.accordion').accordion()
    $(target).find('.ui.dropdown').not('.remote').dropdown()
    if ($(target).find('.title-component').length > 0) {
      title.init(target, params_id)
    }
    if ($(".search.awaited-asset").length > 0) {
      awaited_asset.init(target, params_id)
    }
  },

  set_on_changes(target, params_id) {
    $(target).find('.priority.dropdown').dropdown('setting', 'onChange', (value, _text) => $(`#priority_${params_id}`).val(value))
    $(`#override_scanning_format_${params_id}`).on('change', function() {
      const value = $(`#override_scanning_format_${params_id}`).val()
      if (value === "i") {
        $(target).find('.override-scan-order').hide().show()
      } else {
        $(target).find('.override-scan-order').hide()
      }
    })
    $(`#content_${params_id}`).on('change', function() {
      $(`#source_content_in_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-in'))
      $(`#content_type_id_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-type-id'))
      $(`#source_duration_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-duration'))
    })
    // trigger defaults
    $(`#override_scanning_format_${params_id}`).trigger('change')
    $(`#content_${params_id}`).trigger('change')
  }
}

window.workflow_template = workflow_template
