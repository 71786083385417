var audio_tracks = {
  init() {
    audio_tracks.init_remote()

    $('#audio_track_defaults .first-language-search').dropdown({
      action: "combo",
      fullTextSearch: false,
      allowReselection: true,
      apiSettings: {
        cache: false,
        url: '/languagecode?q={/query}',
        data: {
          default_filter: $('#audio_track_defaults .first-language-search').data('language-default-filter')
        }
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      },
      onChange(value, text, _$choice) {
        let audios = document.getElementsByClassName("audio-tracks")

        const labels = $("#audio_content .audio-tracks .audio-channel-label")
        if (labels.hasClass("blue")) {
          audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
        }

        let i = audios.length;
        while (i--) {
         $(audios[i]).find(".audio-language").dropdown("set value", value).dropdown("set text", text).dropdown("set selected", value)
        }
      } 
    })

    $('#audio_track_defaults .second-language-search').dropdown({
      action: "combo",
      fullTextSearch: false,
      allowReselection: true,
      apiSettings: {
        cache: false,
        url: '/languagecode?q={/query}',
        data: {
          default_filter: $('#audio_track_defaults .second-language-search').data('language-default-filter')
        }
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      },
      onChange(value, text, _$choice) {
        let audios = document.getElementsByClassName("audio-tracks")

        const labels = $("#audio_content .audio-tracks .audio-channel-label")
        if (labels.hasClass("blue")) {
          audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
        }

        let i = audios.length;
        while (i--) {
          $(audios[i]).find(".secondary-audio-language").dropdown("set value", value).dropdown("set text", text).dropdown("set selected", value)
        }
      }
    })

    $('#audio_track_defaults .ui.dropdown').not('.remote').dropdown({ action: "combo", fullTextSearch: false })

    $("#audio-trackgroup").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }

      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-trackgroup").dropdown("set value", $("#audio-trackgroup").val()).dropdown("set text", $("#audio-trackgroup").val()).dropdown("set selected", $("#audio-trackgroup").val())
      }
    })

    $("#audio-track-channels").on("change", function() {
      $.ajax({
        url: '/assets/audio_track_channels.json',
        type: "GET",
      }).done(function(data) {
        audio_tracks.set_preset_layout(data.results)
      })
    })

    $("#audio-track-usages").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }
    
      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-track-usages").dropdown("set value", $("#audio-track-usages").val()).dropdown("set text", $('#audio-track-usages option[value="' + $("#audio-track-usages").val() + '"]').text()).dropdown("set selected", $("#audio-track-usages").val())
      }
    })

    $("#audio-audio-codecs").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }

      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-audio-codecs").dropdown("set value", $("#audio-audio-codecs").val()).dropdown("set text", $('#audio-audio-codecs option[value="' + $("#audio-audio-codecs").val() + '"]').text()).dropdown("set selected", $("#audio-audio-codecs").val())
      }
    })

    $("#audio-sampling-rates").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }

      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-sampling-rates").dropdown("set value", $("#audio-sampling-rates").val()).dropdown("set text", $('#audio-sampling-rates option[value="' + $("#audio-sampling-rates").val() + '"]').text()).dropdown("set selected", $("#sampling-rates").val())
      }
    })

    $("#audio-bit-depths").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }

      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-bit-depths").dropdown("set value", $("#audio-bit-depths").val()).dropdown("set text", $('#audio-bit-depths option[value="' + $("#audio-bit-depths").val() + '"]').text()).dropdown("set selected", $("#audio-bit-depths").val())
      }
    })

    $("#audio-loudness").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }

      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-loudness").dropdown("set value", $("#audio-loudness").val()).dropdown("set text", $("#audio-loudness").val()).dropdown("set selected", $("#audio-loudness").val())
      }
    })

    $("#audio_track_notes").on("change", function() {
      let audios = document.getElementsByClassName("audio-tracks")

      const labels = $("#audio_content .audio-tracks .audio-channel-label")
      if (labels.hasClass("blue")) {
        audios = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks")
      }

      let i = audios.length;
      while (i--) {
        $(audios[i]).find(".audio-track-notes").dropdown("set selected", $("#audio_track_notes").val())
      }
    })

    const labels = $("#audio_content .audio-tracks .audio-channel-label")

    $(".all-tracks.audio-channel-label").on('click', function() {
      if (labels.hasClass("blue")) {
        $("#audio_content .audio-tracks .audio-channel-label").removeClass("blue")
      } else {
        $("#audio_content .audio-tracks .audio-channel-label").addClass("blue")
      }
    })

    $.each(labels, function(_i, label) {
      audio_tracks.channel_selection(label)
    })

    $.each(labels, function(_i, label) {
      audio_tracks.track_group_selection(label)
    })

    $(".remove-all-tracks").on('click', function() {
      for (let audio_track of Array.from($('.audio-tracks:visible'))) {
        $(audio_track).find(".remove_nested_fields_link").trigger('click')
      }
      return audio_tracks.set_audio_numbers()
    })
    
    $(".add-audio-track").on('click', function(event) {
      if (event.altKey) {
        $(".add-audio-track").popup({
          inline: true,
          position: 'right center',
          exclusive: true,
          on: 'click',
          onHidden() {
            return $(".add-audio-track").popup("destroy")
          }
        }).popup('show')
        return false;
      }
      return true;
    })
    
    $(".insert-audio-tracks").on('click', function(_event) {
      const track_number = parseInt($('#audio-track-number').val())
      window.insert_audio_track_batch = {
        "no_audio": $('#no-audio-checkbox').prop('checked'),
        "last_channel": $('.audio-tracks:visible').last().find('.audio-trackgroup').dropdown('get value')
      };
      for (let num = 1, end = track_number, asc = 1 <= end; asc ? num <= end : num >= end; asc ? num++ : num--) {
        $(".add-audio-track").trigger('click')
      }
      $('.add-audio-track').popup('hide')
      return window.insert_audio_track_batch = undefined;
    })
  },

  channel_selection(label) {
    $(label).off("click")
    $(label).on('click', function() {
      if ($(this).hasClass("blue")) {
        $(this).removeClass("blue")
      } else {
        $(this).addClass("blue")
      }
    })
  },

  track_group_selection(label) {
    $(label).off("dblclick")
    $(label).on('dblclick', function() {
      const all_tracks = $("#audio_content .nested-fields")
      const has_blue = $(this).hasClass("blue")
      
      let current_track_group = $(this).closest(".audio-tracks").find(".audio-trackgroup").dropdown("get value")
      for (var track of Array.from(all_tracks)) {
        (function() {
          const track_group = $(track).find('.audio-trackgroup.dropdown').dropdown('get value')

          if (track_group == current_track_group) { 
            if (has_blue) {
              $(track).find(".audio-channel-label").removeClass("blue")
            } else {
              $(track).find(".audio-channel-label").addClass("blue")
            }
          }

        })()
      }
    })
  },

  init_remote() {
    audio_tracks.set_audio_numbers()
    audio_tracks.audio_loudness()

    $.each($('.audio-trackgroup'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          //cache: false,
          url: '/assets/track_groups.json?q={/query}',
        },
        onChange: function() {
          audio_tracks.set_audio_numbers()
        }
      })
    }),

    $.each($('.audio-track-channels'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_track_channels.json?q={/query}',
        }
      })
    }),

    $.each($('.audio-track-usages'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_track_usages.json?q={/query}',
        }
      })
    }),

    $.each($('.audio-audio-codecs'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_codecs.json?q={/query}',
        }
      })
    }),

    $.each($('.audio-sampling-rates'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_samplings.json?q={/query}',
        }
      })
    }),

    $.each($('.audio-bit-depths'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_bit_depths.json?q={/query}',
        }
      })
    }),

    $.each($('.audio-loudness'), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_loudness.json?q={/query}',
        }
      })
    }),

    $.each($(".audio-track-notes"), function(_i, item) {
      $(item).dropdown({
        filterRemoteData: true,
        fullTextSearch: false,
        apiSettings: {
          cache: false,
          url: '/assets/audio_track_notes.json?q={/query}',
        }
      })
    }),

    assets.set_language_search('.audio-tracks')

    $('#audio_track_defaults .with-tooltip, .audio-tracks .with-tooltip').popup({
      inline: true,
      exclusive: true,
      delay: {
        show: 500,
        hide: 0
      }
    })
  },

  audio_loudness() {
    $('.audio-settings-button').off('click')
    $('.audio-settings-button').on('click', function() {
      const button = $(this)
      var audio_track_id = $(this).attr("data-audio-id")
      const target_id = $(this).attr("data-target")
      const asset_id = $("#asset_a_ID").val()

      if (audio_track_id == "") {
        if (asset_id != "") {
          audio_track_id = 0
        }
      }

      if ($("#audio_track_" + target_id.substr(16) + " .loudness.fields .modal").length > 0) { 
        const modal = $("#audio_track_" + target_id.substr(16) + " .loudness.fields .modal")
        modal.modal("show")
      } else {
        button.addClass("loading")
        const child_index_id = /\[(.*?)\]\[(.*?)\]/g.exec($("#audio_track_" + target_id.substr(16) + ' .audio-track-channels select').attr("name"))[2]
        $.ajax({
          url: `/assets/${asset_id}/audio_tracks/${audio_track_id}/loudness_fields`,
          data: {
            target_id: target_id,
            child_index_id: child_index_id
          }
        }).done(function(data) {
          button.removeClass("loading")
          const field = button.parentsUntil('.nested-fields').find('.loudness.fields')
          $(field).append($(data).find('.audio-settings'))
          const modal = $(field).find(".modal.audio-settings").modal({ detachable: false })
          modal.modal("show")
        })
      }
    })
  },

  track_fields() {
    $.ajax({
      data: {
        job_id: $("#asset_job_id").val(),
        asset_id: $("#asset_a_ID").val(),
        import_asset_id: assets.getUrlParameter('import_asset_id'),
        skip_audios: assets.getUrlParameter('skip_audios'),
        audio_import_select: assets.getUrlParameter('audio_import_select')
      },
      url: '/assets/audio_tracks',
      type: "GET"
    }).done(function(data) {
      $("#assets .audio_tracks").html(data)
      audio_tracks.init_remote()
    }).fail(function() {
      console.log("fail")
    })
  },
  
  set_audio_numbers() {
    const all_tracks = $("#audio_content .nested-fields")
    const sels = [];
    let current_track_group = "A"
    let prev_track = undefined
    for (var track of Array.from(all_tracks)) {
      (function() {
        const track_group = $(track).find('.audio-trackgroup.dropdown').dropdown('get value')
        let divider = $(prev_track).parent().find('.divider')
        if ($(track).parent().css('display') !== 'none') {
          if (track_group !== current_track_group) {
            $(divider).show()
            current_track_group = track_group
          } else {
            $(divider).hide()
          }
          prev_track = track
          return sels.push($(track).find(".audio-channel-label"))
        } else {
          $(divider).hide()
        }
      })()
    }

    if (all_tracks.last().next().css('display') !== 'none') {
      all_tracks.last().next().hide()
    }

    let i = sels.length;

    $(".all-tracks").text(i)
    while (i--) {
      $(sels[i]).text(i + 1)
    }
  },
  
  set_preset_layout(mapping) {
    const C = "2"
    const L = "4"
    const LFE = "5"
    const Lt = "6"
    // eslint-disable-next-line no-unused-vars
    const LTC = "7"
    const Mono_two_channels = "8"
    const Mono_all = "8"
    const tbc = "9"
    const NoAudio = "10"
    const R = "11"
    const Rt = "12"
    const StereoL = "13"
    const StereoR = "14"
    const Ls = "15"
    const Rs = "16"
    const Lss = "18"
    const Rss = "19"
    const Lsr = "20"
    const Rsr = "21"
    // eslint-disable-next-line no-unused-vars
    const Cs = "22"
    const Atmos = "38"
    
    let tracks =  $('.audio-tracks:visible')

    const labels = $("#audio_content .audio-tracks .audio-channel-label")
    if (labels.hasClass("blue")) {
      tracks = $("#audio_content .audio-tracks .audio-channel-label.blue").closest(".audio-tracks:visible")
    }

    let i = tracks.length
    let vi = 0
    let layout = []
    let track_group = []

    const change_all = values =>
      Array.from(tracks).map((_audio, _index) =>
        (function() {
          if (typeof values === "string") {
            layout.push(values)
          } else {
            layout.push(values[vi])
            if (vi <= values.length) { vi += 1 }
          }
          
          //track_group.push("A")
        })())
    
    switch ($("#audio-track-channels").dropdown('get value')) {
      case " ":
        change_all(" ")
        break;
      case "tbc":
        change_all(tbc)
        break;
      case "Mono (2 Channels)":
        change_all(Mono_two_channels)
        track_group = ["A", "A"]
        break;
      case "Mono (all)":
        change_all(Mono_all)
        break;
      case "no Audio":
        change_all(NoAudio)
        break;
      case "Apple Layout":
        layout = [L, R, C, LFE, Ls, Rs, Lt, Rt]
        track_group = ["A", "A", "A", "A", "A", "A", "B", "B"]
        break;
      case "Atmos":
        change_all(Atmos)
        break;
      case "SMPTE Layout":
        layout = [L, R, C, LFE, Ls, Rs]
        track_group = ["A", "A", "A", "A", "A", "A"]
        break;
      case "Stereo (all)":
        layout = [StereoL, StereoR]
        change_all(layout)
        track_group = Array.from(Array(26)).map((e, i) => i + 65).flatMap((x) => [String.fromCharCode(x), String.fromCharCode(x)]);
        break;
      case "Stereo (2 Channels)":
        layout = [StereoL, StereoR]
        track_group = ["A", "A"]
        break;
      case "Stereo Dual Language":
        layout = [StereoL, StereoR, StereoL, StereoR]
        track_group = ["A", "A", "B", "B"]
        break;
      case "Disney/Deluxe":
        layout = [L, R, C, LFE, Ls, Rs, Lt, Rt, L, R, C, LFE, Ls, Rs, Lt, Rt, L, R, C, LFE, Ls, Rs, Lt, Rt]
        track_group = ["A", "A", "A", "A", "A", "A", "B", "B", "C", "C", "C", "C", "C", "C", "D", "D", "E", "E", "E", "E", "E", "E", "F", "F"]
        break;
      case "Paramount":
        layout = [L, C, R, Ls, Rs, LFE, StereoL, StereoR, L, C, R, Ls, Rs, LFE, StereoL, StereoR]
        track_group = ["A", "A", "A", "A", "A", "A", "B", "B", "C", "C", "C", "C", "C", "C", "D", "D"]
        break;
      case "2.0 + 5.1":
        layout = [Lt, Rt, L, R, C, LFE, Ls, Rs, Lt, Rt, L, R, C, LFE, Ls, Rs, Lt, Rt]
        track_group = ["A", "A", "B", "B", "B", "B", "B", "B", "C", "C", "D", "D", "D", "D", "D", "D"]
        break;
      case "2.0, 2.0 + 5.1, (5.1)":
        layout = [Lt, Rt, Lt, Rt, L, R, C, LFE, Ls, Rs, L, R, C, LFE, Ls, Rs]
        track_group = ["A", "A", "B", "B", "C", "C", "C", "C", "C", "C", "D", "D", "D", "D", "D", "D"]
        break;
      case "5.1, 5.1 + 2.0, 2.0":
        layout = [L, R, C, LFE, Ls, Rs, L, R, C, LFE, Ls, Rs, Lt, Rt, Lt, Rt]
        track_group = ["A", "A", "A", "A", "A", "A", "B", "B", "B", "B", "B", "B", "C", "C", "D", "D", ]
        break;
      case "7.1":
        layout = [L, R, C, LFE, Lss, Rss, Lsr, Rsr]
        track_group = ["A", "A", "A", "A", "A", "A", "A", "A"]
        break;
      case "FOX+":
        layout = [Lt, Rt, Lt, Rt, L, C, R, Ls, Rs, LFE, L, C, R, Ls, Rs, LFE]
        track_group = ["A", "A", "B", "B", "C", "C", "C", "C", "C", "C", "D", "D", "D", "D", "D", "D"]
        break;
      case "Sky SL PCM + SL DolbyE":
        layout = [StereoL, StereoR, NoAudio, NoAudio, tbc, tbc, NoAudio, NoAudio]
        track_group = ["A", "A", "B", "B", "C", "C", "D", "D"]
        break;
      case "Sky DL PCM + DL DolbyE":
        layout = [StereoL, StereoR, StereoL, StereoR, tbc, tbc, tbc, tbc]
        track_group = ["A", "A", "B", "B", "C", "C", "D", "D"]
        break;
      case "Kinowelt TV DL":
        layout = [StereoL, StereoR, StereoL, StereoR, NoAudio, NoAudio, NoAudio, NoAudio]
        track_group = ["A", "A", "B", "B", "C", "C", "D", "D"]
        break;
      case "5.0":
        layout = [L, R, C, Ls, Rs]
        track_group = ["A", "A", "A", "A", "A"]
        break;
    }

    while (i--) {
      if(layout[i] === undefined) { 
        continue
      }

      $(tracks[i]).find(".audio-track-channels").dropdown('set value', layout[i]).dropdown("set text", mapping.find(map => map.value == layout[i]).name).dropdown("set selected", layout[i])
      if (track_group.length != 0) {
        $(tracks[i]).find(".audio-trackgroup").dropdown('set value', track_group[i]).dropdown("set text", track_group[i]).dropdown("set selected", track_group[i])
      }
    }
  }
}

window.audio_tracks = audio_tracks;
  