document.addEventListener("turbo:load", function() {
  document.querySelector('#contacts.index') && contacts.index()
  document.querySelector('#contacts.edit') && contacts.edit()
});

var contacts = {
  index() {
    search.init("/contacts");
  },

  edit() {
    let delay
    delay = (function() {
      let timer = 0;
      return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })()
    $("#contacts .dropdown").not(".remote").dropdown({ placeholder: false });
    $(".ui.pointing.secondary.menu .item").tab();

    $('#contacts .remote.contact').dropdown({
      direction: 'downward',
      apiSettings: {
        cache: false,
        url: '/contacts.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })

    contacts.init_dc_dropdown($('#metadata_mapping_contacts_form').find(".cpn-dc.dropdown"))
    contacts.init_dc_dropdown($('#contact_provider_names_form').find(".cpn-dc.dropdown"), "")

    $("#contacts").off("fields_added.nested_form_fields")
    $("#contacts").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "provider_names":
          return
        case "contact_provider_name":
          contacts.init_dc_dropdown($(event.target).find(".cpn-dc.dropdown"), "")
          return
        case "metadata_mapping_contact":
          $(event.target).find(".mmc-dc-ID.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true })
          return
        default:
          return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#contacts .item[data-tab='contact_persons']").on('click', function() {
      if ($('.ui.tab[data-tab="contact_persons"] .loading.segment').length != 0) {
        contacts.load_contact_persons()
      }
    })

    $('#contacts .contact-person.search input').on('keyup', function() {
      const search_input = $(this).val()
      delay((function() {
        contacts.load_contact_persons(search_input)
      }), 400)
    })

    $('.gen-client-key.field').hover((function() {
      if (!($('.gen-client-key.field label a').length > 0)) {
        if ($("#contact_IntrexxClientKey").val() == "") {
          $(`.gen-client-key.field label`).append(`<a target='_blank' onClick='window.contacts.add_client_key()'>&nbsp;&nbsp;<i class='fad fa-random'></i> Generate random UUID</a>`)
        }
      }
    }), function() {
      $(`.gen-client-key.field label a`).remove();
    });
  },

  init_index_body() {
    $('#contacts.index .contact-person').off('click')
    $('#contacts.index .contact-person').on('click', function() {
      contacts.contact_person_modal($(this).data('cp-id'))
    })
  },

  init_dc_dropdown(node, contact_id = "#contact_id") {
    $(node).dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/channels?q={/query}',
        data: {
          c_ID: $(contact_id).val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $(contact_id).val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    })
  },

  load_contact_persons(search_input = {}) {
    $.ajax({
      url: `/contacts/${$("#contacts").data('contact-id')}/contact_persons`,
      type: "GET",
      data: {
        search_input: search_input
      }
    }).done(function(data) {
      $('.ui.tab[data-tab="contact_persons"] .content').html(data).promise().then(function() {
        $('tr.contact-person').off('click')
        $('tr.contact-person').on('click', function() {
          contacts.contact_person_modal($(this).data('cp-id'))
        })

        $('.add-contact-person.button').off('click')
        $('.add-contact-person.button').on('click', function() {
          contacts.contact_person_modal("new")
        })
      }
      )
    }).fail(function() {
      console.log("failure to load contact persons")
    })
  },

  create_button() {
    $('body').dimmer({
      dimmerName: "quickmenu",
      onShow() {
        $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
        $("#quickmenu .quickmenu.header").append("<a href='/contacts'><h3 class='ui header'>Contact</h3></a>");
        $.ajax({
          url: "/contacts/quickmenu",
          type: "GET",
          success(data, _textStatus, _xhr) {
            $("#quickmenu .quickmenu.body").html(data);
            $("#new_contact .dropdown").dropdown({ placeholder: false });
            $("#new_contact .button.submit").on('click', () => $("#new_contact .segment").prepend('<div class="ui active inverted dimmer submit"><div class="ui text loader"></div></div>'));

            $(document).ready(() =>
              $("#new_contact").on("ajax:success", (_e, _data, _status, _xhr) => $(".ui.dimmer.quickmenu").remove()).on("ajax:error", function(_e, xhr, _status, error) {
                $("#new_contact .segment .ui.active.dimmer").remove();
                $(".quickmenu.body").prepend(`<div class="ui negative message"><i class="close icon"></i><div class="header">${error}</div><p>${xhr.responseText}</p></div>`);
                general.message_closable();
              })
            );
          },

          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
            $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
          }
        });
      },
      onHide() {
        $(".ui.dimmer.quickmenu").remove();
        $("body").removeClass("dimmable dimmed")
      }
    }).dimmer('show');
  },

  contact_person_modal(cp_ID) {
    const c_ID = $('#contacts').data('contact-id')
    let url = `/contacts/${c_ID}/edit_contact_person_modal?cp_id=${cp_ID}`
    if (cp_ID === "new") {
      url = `/contacts/${c_ID}/new_contact_person_modal`
    }

    $.ajax(url).done(function(data) {
      $("body").append(data)

       let edit_contact_person_modal = $('.edit-contact-person.modal').modal({
          onShow() {
            $(this).find('.ui.dropdown').dropdown()
            $(this).find('.menu .item').tab()

            calendar.datepicker(".edit-contact-person.modal")
            $('.delete-contact-person.button').off('click')
            $('.delete-contact-person.button').on('click', function() {
              contacts.delete_contact_person()
            })

            let form_id = $("button.edit-contact-person[form]").attr('form')
            $(`#${form_id}`).off('ajax:beforeSend.contacts')
            $(`#${form_id}`).on('ajax:beforeSend.contacts', function(_e, _data, _status, _xhr) {
              $('.edit-contact-person.button').addClass('loading')
            })
            $(`#${form_id}`).off('ajax:complete.contacts')
            $(`#${form_id}`).on('ajax:complete.contacts', function(_event,_xhr,_options) {
              $('.edit-contact-person.button').removeClass('loading')
            })

            $(`#${form_id}`).off('ajax:success.contacts')
            $(`#${form_id}`).on('ajax:success.contacts', function(_response) {
              edit_contact_person_modal.modal('hide')
              contacts.load_contact_persons()
            })

            $(`#${form_id}`).off("ajax:error.contacts")
            $(`#${form_id}`).on("ajax:error.contacts", function(data) {
              const errors = data.originalEvent.detail[0] 
              let message = ""
              for (const key in errors) {
                message += `<li>${errors[key]}</li>`
              }
              $('body').toast({ 
                class: 'error', 
                className: {
                  toast: 'ui icon message'
                }, 
                showIcon: 'times', 
                title: 'Error saving Contact Person', 
                message: message
              })
            })
          },
          onHidden() {
            $('.edit-contact-person.modal').remove()
          },
          onApprove: function() {
            
          },
          autofocus: false,
          closable: false
        }).modal("show")
    })
  },

  delete_contact_person() {
    if (window.confirm("Are you sure?")) {
      $.ajax({
        url: `/contact_persons/${$('#contact_person_cp_ID').val()}`,
        type: "DELETE",
      }).done(function(_data) {
        contacts.load_contact_persons()
        $('.edit-contact-person.modal').modal('hide')
      })
    }
  },

  add_client_key() {
    $("#contact_IntrexxClientKey").val(contacts.uuidv4())
  },

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
    );
  },

  contacts_search(response) {
    let html = '';
    $.each(response.contacts, function(_index, option) {
      html += '<div class="result" style="padding: 0;"><div class="content">';
      html += `<div class="remote contact card" data-model-name="contacts" data-model-id="${option.id}" data-type="card" data-fields="no-links" style="margin-top: 10px;"><div class="card list item" style="height: 62px; padding: 0.4em 1em;"><div class="ui placeholder"><div class="line"></div><div class="line"></div></div></div></div>`
      html += '</div></div>';
    });
    return html;
  }
  
}

window.contacts = contacts;
