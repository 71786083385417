import { Controller } from "@hotwired/stimulus"
import { GridStack } from "gridstack"

export default class extends Controller {
  
  static targets = ['gridstack', 'widgetButton', 'backButton', 'assetChart', 'projectChart', 'workflowChart']
  static values = {
    configuration: Array,
    enabled: Boolean,
    widgetMenuOpen: Boolean
  }
  static grid = null;

  connect() {
    this.grid = GridStack.init({
      acceptWidgets: true,
      removable: '#trash', // drag-out delete class
      cellHeight: "130px",
    })
    this.grid.disable()
    this.grid.load(this.configurationValue)
    this.grid.on('added removed change', function(e, items) {
      let str = '';
      items.forEach(function(item) { str += ' (x,y)=' + item.x + ',' + item.y; });
      //console.log(e.type + ' ' + items.length + ' items:' + str );
    })
    this.displayLoadingIndicator()
  }

  assetChartTargetConnected(_element) {
    dashboard.fill_asset_chart()
  }

  projectChartTargetConnected(_element) {
    dashboard.fill_projects_chart()
  }

  workflowChartTargetConnected(_element) {
    dashboard.fill_workflow_chart()
  }

  configure() {
    if (this.enabledValue) {
      this.grid.disable()
      this.gridstackTarget.classList.remove('config')
      
      this.saveFullGrid()
      this.updateDashboard().then(data => {
        this.hideEditBar()
      })
    } else {
      this.grid.enable()
      this.gridstackTarget.classList.add('config')
      this.showEditBar()
    }
    this.enabledValue = !this.enabledValue
  }

  saveFullGrid() {
    const parser = new DOMParser()
    const serializedFull = this.grid.save(true, true)
    let turboFrame;

    serializedFull.children.map((element) => {
      const htmlString = element.content
      const doc = parser.parseFromString(htmlString, "text/html")
      turboFrame = doc.body.querySelector('turbo-frame')
      while (turboFrame.hasChildNodes()) {
        turboFrame.removeChild(turboFrame.firstChild)
      }
      turboFrame.removeAttribute('complete')
      element.content = turboFrame.outerHTML
    })
    this.configurationValue = serializedFull.children
  }

  async updateDashboard() {
    const response = await post('/dashboard/update', { 
      body: {
        config: this.configurationValue,
      }
    })

    if (response.ok) {
      const result = await response.json
      return result
    }
  }

  widgetMenuOpenValueChanged() {
    if (this.widgetMenuOpenValue) {
      $(this.widgetButtonTargets).hide()
      $(this.backButtonTargets).show()
    } else {
      $(this.widgetButtonTargets).show()
      $(this.backButtonTargets).hide()
    }
  }

  showEditBar() {
    $(".dashboard-header-container").hide()
    $(".dashboard-header-edit").html("<div class='ui active centered inline loader'></div>")
    $.ajax({
      url: "/dashboard/edit",
      complete() {

      },
      success(data, _textStatus, _xhr) {
        $(".dashboard-header-edit").html(data)
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  }

  hideEditBar() {
    this.widgetMenuOpenValue = false
    $(".dashboard-header-container").show()
    $("#trash").hide()
    $("#add-widgets").hide()
    $(".dashboard-header-edit").html("")
  }

  addWidget() {
    this.widgetMenuOpenValue = true
    $.ajax({
      url: "/dashboard/add_widgets",
      complete() {
        $("#trash").hide()
        $("#add-widgets").show()
      },
      success(data, _textStatus, _xhr) {
        $("#add-widgets .segment").html(data)
        GridStack.setupDragIn('.newWidget', { appendTo: 'body', helper: 'clone' });
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  }

  removeWidget() {
    this.widgetMenuOpenValue = true
    $("#add-widgets").hide()
    $("#trash").show()
  }

  back() {
    this.widgetMenuOpenValue = false
    $("#add-widgets").hide()
    $("#trash").hide()
  }

  async restoreDefaultLayout() {
    const response = await post('/dashboard/restore_default')

    if (response.ok) {
      location.reload()
    }
  }

  displayLoadingIndicator() {
    document.querySelectorAll('.grid-stack-item').forEach((field, _i) => {
      const lineCount = parseInt(field.getAttribute('gs-h')) || 1
      field.querySelector('.grid-stack-item-content').innerHTML = field.querySelector('.grid-stack-item-content').textContent
      field.querySelector('turbo-frame').innerHTML = this.insertLoadingTemplate(lineCount)
    })
  }

  insertLoadingTemplate(lines) {
    let loadingLines = '<div class="line"></div><div class="line"></div>'
    
    for (let i = 0; i < lines * 3; i++) {
      loadingLines += `<div class="line"></div>`;
    }

    return `<div class="ui segment">
      <div class="ui placeholder">
        <div class="image header">
          ${loadingLines}
        </div>
      </div>
    </div>`
  }
}
