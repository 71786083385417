import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'checkbox',
    'field'
  ]

  static values = {
    paramsId: String,
  }

  checkboxTargetConnected(element) {
    let c = this
    $(element).checkbox({
      onChecked() {
        $(c.fieldTarget).show()
      },
      onUnchecked() {
        $(c.fieldTarget).hide()
        $(c.fieldTarget).find('input').val("")
      }
    })
  }
}
