// Entry point for the build script in your package.json

import './vendor/jquery'

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

// Stimulus.handleError = (error, message, detail) => {
//   console.warn(message, detail)
//   ErrorTrackingSystem.captureException(error)
// }
// Stimulus.debug = true

import NestedForm from 'stimulus-rails-nested-form'
Stimulus.register('nested-form', NestedForm)

import * as RequestJS from '@rails/request.js'
window.RequestJS = RequestJS
window.get = RequestJS.get
window.post = RequestJS.post
window.patch = RequestJS.patch
window.put = RequestJS.put
window.destroy = RequestJS.destroy

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  Stimulus.register(controller.name.replace("controllers--", ""), controller.module.default)
})

import "./channels/**/*_channel.js"

Rails.start()
ActiveStorage.start()

import 'fomantic-ui/dist/semantic'

import "./vendor/moment"

import "./vendor/highcharts"
import("./vendor/highlight")

//import("./vendor/client_side_validations")
import '@client-side-validations/client-side-validations'

import("./vendor/human_format")

import sortable from 'html5sortable/dist/html5sortable.es.js'
window.sortable = sortable

//import("./vendor/serializeobject")
import 'jquery-serializeobject'

import("./vendor/dragster")
import prettyBytes from 'pretty-bytes'
window.prettyBytes = prettyBytes

import { particlesJS } from 'particles.js/particles'
window.particlesJS = particlesJS

import("./vendor/react")

import "./components/**/*"

//import "./server_rendering"
import("./server_rendering")

import "./src/**/*"

import "./vendor/nested_form_fields"
import "./vendor/fomantic_menu"

import("./vendor/gridstack")
import("./vendor/fontawesome")
import("./video/play")
