document.addEventListener("turbo:load", () => bookmarks.init());

var bookmarks = {
  init() {
    if ($("#bookmarks").length > 0) {
      search.init("/bookmarks");
      $('#bookmarks .dropdown').dropdown();
    }

    bookmarks.init_action_menu();
    return bookmarks.menu_button();
  },

  init_action_menu() {
    $('.bookmark.item').on('click', function() {
      return bookmarks.item_action(this);
    });
  },

  check_state(item) {
    const target_id = $(item).data('model-id');
    const target_type = $(item).data('model-name');
    return $.ajax({
      url: `/bookmarks/state?bookmark[target_id]=${target_id}&bookmark[target_type]=${target_type}`,
      type: "GET",
      success(data, _textStatus, _xhr) {
        const icon = $(item).find(".fa-bookmark");
        if (data === false) {
          $(item).find(".bookmark.item .text").text("Add Bookmark");
          $(icon).removeClass("blue");
          $(icon).attr('data-prefix', 'far');
        } else {
          $(item).find(".bookmark.item .text").text("Remove Bookmark");
          $(icon).addClass("blue");
          $(icon).attr('data-prefix', 'fas');
        }
      }
    });
  },
       
  item_action(item) {
    if ($(item).hasClass('bookmark') && ($("#bookmarks").length > 0)) {
      return $(item).closest('.list.item').parent().hide('50');
    } else {
      return bookmarks.toggle_icon(item);
    }
  },

  toggle_icon(item) {
    const icon = $(item).find(".fa-bookmark");
    if ($(icon).hasClass("blue")) {
      $(item).find(".text").text("Add Bookmark");
      $(icon).removeClass("blue");
      $(icon).attr('data-prefix', 'far');
    } else {
      $(item).find(".text").text("Remove Bookmark");
      $(icon).addClass("blue");
      $(icon).attr('data-prefix', 'fas');
    }
  },

  init_index_body() {
    assets.load_cover('#bookmarks .quicklook.cover')
  },

  menu_button() {
    return $(".main.menu .bookmarks.item").on('click', () =>
      $('body').dimmer({
        dimmerName: "quickmenu",
        onShow() {
          $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
          $("#quickmenu .quickmenu.header").append("<a href='/bookmarks'><h3 class='ui header'><i class='fas fa-bookmark fa-fw fa-sm icon' aria-hidden='true'></i>Bookmarks</h3></a>");
          return $.ajax({
            url: "/bookmarks/quickmenu",
            type: "GET",
            success(data, _textStatus, _xhr) {
              $("#quickmenu .quickmenu.body").html(data);
              bookmarks.init_action_menu();
              context_menu.init_menu();
              assets.load_cover('#quickmenu .quicklook.cover')
              return
            },
            error(_jqXHR, _textStatus, errorThrown) {
              console.log(errorThrown);
              $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
              return 
            }
          });
        },
        onHide() {
          $(".ui.dimmer.quickmenu").remove();
          $("body").removeClass("dimmable dimmed")
        }
      }).dimmer('show')
    );
  }
};

window.bookmarks = bookmarks;
